import {DataGrid} from "./calc";

/// ### Ergebnistabelle

// Anzeigefaktoren (1 entspricht "kg / km")

const t_ergtab = 'Ergebnistabelle';
DataGrid.setCell(t_ergtab, 'C2', 1000);
DataGrid.setCell(t_ergtab, 'D2', 1000);
DataGrid.setCell(t_ergtab, 'C3', 1000);
DataGrid.setCell(t_ergtab, 'D3', 1000);
DataGrid.setCell(t_ergtab, 'C4', 1000);
DataGrid.setCell(t_ergtab, 'D4', 1000);
DataGrid.setCell(t_ergtab, 'C5', 1000000);
DataGrid.setCell(t_ergtab, 'D5', 1000000);
DataGrid.setCell(t_ergtab, 'C6', 1000000);
DataGrid.setCell(t_ergtab, 'D6', 1000000);
DataGrid.setCell(t_ergtab, 'C7', 3.095);
DataGrid.setCell(t_ergtab, 'D7', 0.03095);


/// Fahrzeugherstellung
const t_erg_tab = 'Ergebnisse tabellarisch';
DataGrid.setCell(t_ergtab, 'C13', (s, c, g) => g.g(t_erg_tab, 'C13'));
DataGrid.setCell(t_ergtab, 'D13', (s, c, g) => g.g(t_erg_tab, 'D13'));
DataGrid.setCell(t_ergtab, 'E13', (s, c, g) => g.g(t_erg_tab, 'E13'));
DataGrid.setCell(t_ergtab, 'F13', (s, c, g) => g.g(t_erg_tab, 'F13'));
DataGrid.setCell(t_ergtab, 'G13', (s, c, g) => g.g(t_erg_tab, 'G13'));
DataGrid.setCell(t_ergtab, 'H13', (s, c, g) => g.g(t_erg_tab, 'H13'));

// Versauerung
DataGrid.setCell(t_ergtab, 'C14', (s, c, g) => g.n(t_erg_tab, 'C14') * g.n(s, 'C2'));
DataGrid.setCell(t_ergtab, 'D14', (s, c, g) => g.n(t_erg_tab, 'D14') * g.n(s, 'C2'));
DataGrid.setCell(t_ergtab, 'E14', (s, c, g) => g.n(t_erg_tab, 'E14') * g.n(s, 'C2'));
DataGrid.setCell(t_ergtab, 'F14', (s, c, g) => g.n(t_erg_tab, 'F14') * g.n(s, 'C2'));
DataGrid.setCell(t_ergtab, 'G14', (s, c, g) => g.n(t_erg_tab, 'G14') * g.n(s, 'C2'));
DataGrid.setCell(t_ergtab, 'H14', (s, c, g) => g.n(t_erg_tab, 'H14') * g.n(s, 'C2'));

// Treibhauseffekt
DataGrid.setCell(t_ergtab, 'C15', (s, c, g) => g.n(t_erg_tab, 'C15') * g.n(s, 'C3'));
DataGrid.setCell(t_ergtab, 'D15', (s, c, g) => g.n(t_erg_tab, 'D15') * g.n(s, 'C3'));
DataGrid.setCell(t_ergtab, 'E15', (s, c, g) => g.n(t_erg_tab, 'E15') * g.n(s, 'C3'));
DataGrid.setCell(t_ergtab, 'F15', (s, c, g) => g.n(t_erg_tab, 'F15') * g.n(s, 'C3'));
DataGrid.setCell(t_ergtab, 'G15', (s, c, g) => g.n(t_erg_tab, 'G15') * g.n(s, 'C3'));
DataGrid.setCell(t_ergtab, 'H15', (s, c, g) => g.n(t_erg_tab, 'H15') * g.n(s, 'C3'));

// Feinstaub
DataGrid.setCell(t_ergtab, 'C16', (s, c, g) => g.n(t_erg_tab, 'C16') * g.n(s, 'C4'));
DataGrid.setCell(t_ergtab, 'D16', (s, c, g) => g.n(t_erg_tab, 'D16') * g.n(s, 'C4'));
DataGrid.setCell(t_ergtab, 'E16', (s, c, g) => g.n(t_erg_tab, 'E16') * g.n(s, 'C4'));
DataGrid.setCell(t_ergtab, 'F16', (s, c, g) => g.n(t_erg_tab, 'F16') * g.n(s, 'C4'));
DataGrid.setCell(t_ergtab, 'G16', (s, c, g) => g.n(t_erg_tab, 'G16') * g.n(s, 'C4'));
DataGrid.setCell(t_ergtab, 'H16', (s, c, g) => g.n(t_erg_tab, 'H16') * g.n(s, 'C4'));

// Eutrophierung (terr.)
DataGrid.setCell(t_ergtab, 'C17', (s, c, g) => g.n(t_erg_tab, 'C17') * g.n(s, 'C5'));
DataGrid.setCell(t_ergtab, 'D17', (s, c, g) => g.n(t_erg_tab, 'D17') * g.n(s, 'C5'));
DataGrid.setCell(t_ergtab, 'E17', (s, c, g) => g.n(t_erg_tab, 'E17') * g.n(s, 'C5'));
DataGrid.setCell(t_ergtab, 'F17', (s, c, g) => g.n(t_erg_tab, 'F17') * g.n(s, 'C5'));
DataGrid.setCell(t_ergtab, 'G17', (s, c, g) => g.n(t_erg_tab, 'G17') * g.n(s, 'C5'));
DataGrid.setCell(t_ergtab, 'H17', (s, c, g) => g.n(t_erg_tab, 'H17') * g.n(s, 'C5'));

// Sommersmog
DataGrid.setCell(t_ergtab, 'C18', (s, c, g) => g.n(t_erg_tab, 'C18') * g.n(s, 'C6'));
DataGrid.setCell(t_ergtab, 'D18', (s, c, g) => g.n(t_erg_tab, 'D18') * g.n(s, 'C6'));
DataGrid.setCell(t_ergtab, 'E18', (s, c, g) => g.n(t_erg_tab, 'E18') * g.n(s, 'C6'));
DataGrid.setCell(t_ergtab, 'F18', (s, c, g) => g.n(t_erg_tab, 'F18') * g.n(s, 'C6'));
DataGrid.setCell(t_ergtab, 'G18', (s, c, g) => g.n(t_erg_tab, 'G18') * g.n(s, 'C6'));
DataGrid.setCell(t_ergtab, 'H18', (s, c, g) => g.n(t_erg_tab, 'H18') * g.n(s, 'C6'));

// Kumulierter Energieaufwand
DataGrid.setCell(t_ergtab, 'C19', (s, c, g) => g.n(t_erg_tab, 'C19') * g.n(s, 'C7'));
DataGrid.setCell(t_ergtab, 'D19', (s, c, g) => g.n(t_erg_tab, 'D19') * g.n(s, 'C7'));
DataGrid.setCell(t_ergtab, 'E19', (s, c, g) => g.n(t_erg_tab, 'E19') * g.n(s, 'C7'));
DataGrid.setCell(t_ergtab, 'F19', (s, c, g) => g.n(t_erg_tab, 'F19') * g.n(s, 'C7'));
DataGrid.setCell(t_ergtab, 'G19', (s, c, g) => g.n(t_erg_tab, 'G19') * g.n(s, 'C7'));
DataGrid.setCell(t_ergtab, 'H19', (s, c, g) => g.n(t_erg_tab, 'H19') * g.n(s, 'C7'));
DataGrid.setCell(t_ergtab, 'C20', (s, c, g) => g.n(t_erg_tab, 'C20') * g.n(s, 'C7'));
DataGrid.setCell(t_ergtab, 'D20', (s, c, g) => g.n(t_erg_tab, 'D20') * g.n(s, 'C7'));
DataGrid.setCell(t_ergtab, 'E20', (s, c, g) => g.n(t_erg_tab, 'E20') * g.n(s, 'C7'));
DataGrid.setCell(t_ergtab, 'F20', (s, c, g) => g.n(t_erg_tab, 'F20') * g.n(s, 'C7'));
DataGrid.setCell(t_ergtab, 'G20', (s, c, g) => g.n(t_erg_tab, 'G20') * g.n(s, 'C7'));
DataGrid.setCell(t_ergtab, 'H20', (s, c, g) => g.n(t_erg_tab, 'H20') * g.n(s, 'C7'));


///Energiebereitstellung
DataGrid.setCell(t_ergtab, 'C21', (s, c, g) => g.g(t_erg_tab, 'C21'));
DataGrid.setCell(t_ergtab, 'D21', (s, c, g) => g.g(t_erg_tab, 'D21'));
DataGrid.setCell(t_ergtab, 'E21', (s, c, g) => g.g(t_erg_tab, 'E21'));
DataGrid.setCell(t_ergtab, 'F21', (s, c, g) => g.g(t_erg_tab, 'F21'));
DataGrid.setCell(t_ergtab, 'G21', (s, c, g) => g.g(t_erg_tab, 'G21'));
DataGrid.setCell(t_ergtab, 'H21', (s, c, g) => g.g(t_erg_tab, 'H21'));

// Versauerung
DataGrid.setCell(t_ergtab, 'C22', (s, c, g) => g.n(t_erg_tab, 'C22') * g.n(s, 'C2'));
DataGrid.setCell(t_ergtab, 'D22', (s, c, g) => g.n(t_erg_tab, 'D22') * g.n(s, 'C2'));
DataGrid.setCell(t_ergtab, 'E22', (s, c, g) => g.n(t_erg_tab, 'E22') * g.n(s, 'C2'));
DataGrid.setCell(t_ergtab, 'F22', (s, c, g) => g.n(t_erg_tab, 'F22') * g.n(s, 'C2'));
DataGrid.setCell(t_ergtab, 'G22', (s, c, g) => g.n(t_erg_tab, 'G22') * g.n(s, 'C2'));
DataGrid.setCell(t_ergtab, 'H22', (s, c, g) => g.n(t_erg_tab, 'H22') * g.n(s, 'C2'));

// Treibhauseffekt
DataGrid.setCell(t_ergtab, 'C23', (s, c, g) => g.n(t_erg_tab, 'C23') * g.n(s, 'C3'));
DataGrid.setCell(t_ergtab, 'D23', (s, c, g) => g.n(t_erg_tab, 'D23') * g.n(s, 'C3'));
DataGrid.setCell(t_ergtab, 'E23', (s, c, g) => g.n(t_erg_tab, 'E23') * g.n(s, 'C3'));
DataGrid.setCell(t_ergtab, 'F23', (s, c, g) => g.n(t_erg_tab, 'F23') * g.n(s, 'C3'));
DataGrid.setCell(t_ergtab, 'G23', (s, c, g) => g.n(t_erg_tab, 'G23') * g.n(s, 'C3'));
DataGrid.setCell(t_ergtab, 'H23', (s, c, g) => g.n(t_erg_tab, 'H23') * g.n(s, 'C3'));

// Feinstaub
DataGrid.setCell(t_ergtab, 'C24', (s, c, g) => g.n(t_erg_tab, 'C24') * g.n(s, 'C4'));
DataGrid.setCell(t_ergtab, 'D24', (s, c, g) => g.n(t_erg_tab, 'D24') * g.n(s, 'C4'));
DataGrid.setCell(t_ergtab, 'E24', (s, c, g) => g.n(t_erg_tab, 'E24') * g.n(s, 'C4'));
DataGrid.setCell(t_ergtab, 'F24', (s, c, g) => g.n(t_erg_tab, 'F24') * g.n(s, 'C4'));
DataGrid.setCell(t_ergtab, 'G24', (s, c, g) => g.n(t_erg_tab, 'G24') * g.n(s, 'C4'));
DataGrid.setCell(t_ergtab, 'H24', (s, c, g) => g.n(t_erg_tab, 'H24') * g.n(s, 'C4'));

// Eutrophierung (terr.)
DataGrid.setCell(t_ergtab, 'C25', (s, c, g) => g.n(t_erg_tab, 'C25') * g.n(s, 'C5'));
DataGrid.setCell(t_ergtab, 'D25', (s, c, g) => g.n(t_erg_tab, 'D25') * g.n(s, 'C5'));
DataGrid.setCell(t_ergtab, 'E25', (s, c, g) => g.n(t_erg_tab, 'E25') * g.n(s, 'C5'));
DataGrid.setCell(t_ergtab, 'F25', (s, c, g) => g.n(t_erg_tab, 'F25') * g.n(s, 'C5'));
DataGrid.setCell(t_ergtab, 'G25', (s, c, g) => g.n(t_erg_tab, 'G25') * g.n(s, 'C5'));
DataGrid.setCell(t_ergtab, 'H25', (s, c, g) => g.n(t_erg_tab, 'H25') * g.n(s, 'C5'));

// Sommersmog
DataGrid.setCell(t_ergtab, 'C26', (s, c, g) => g.n(t_erg_tab, 'C26') * g.n(s, 'C6'));
DataGrid.setCell(t_ergtab, 'D26', (s, c, g) => g.n(t_erg_tab, 'D26') * g.n(s, 'C6'));
DataGrid.setCell(t_ergtab, 'E26', (s, c, g) => g.n(t_erg_tab, 'E26') * g.n(s, 'C6'));
DataGrid.setCell(t_ergtab, 'F26', (s, c, g) => g.n(t_erg_tab, 'F26') * g.n(s, 'C6'));
DataGrid.setCell(t_ergtab, 'G26', (s, c, g) => g.n(t_erg_tab, 'G26') * g.n(s, 'C6'));
DataGrid.setCell(t_ergtab, 'H26', (s, c, g) => g.n(t_erg_tab, 'H26') * g.n(s, 'C6'));

// Kumulierter Energieaufwand
DataGrid.setCell(t_ergtab, 'C27', (s, c, g) => g.n(t_erg_tab, 'C27') * g.n(s, 'C7'));
DataGrid.setCell(t_ergtab, 'D27', (s, c, g) => g.n(t_erg_tab, 'D27') * g.n(s, 'C7'));
DataGrid.setCell(t_ergtab, 'E27', (s, c, g) => g.n(t_erg_tab, 'E27') * g.n(s, 'C7'));
DataGrid.setCell(t_ergtab, 'F27', (s, c, g) => g.n(t_erg_tab, 'F27') * g.n(s, 'C7'));
DataGrid.setCell(t_ergtab, 'G27', (s, c, g) => g.n(t_erg_tab, 'G27') * g.n(s, 'C7'));
DataGrid.setCell(t_ergtab, 'H27', (s, c, g) => g.n(t_erg_tab, 'H27') * g.n(s, 'C7'));
DataGrid.setCell(t_ergtab, 'C28', (s, c, g) => g.n(t_erg_tab, 'C28') * g.n(s, 'C7'));
DataGrid.setCell(t_ergtab, 'D28', (s, c, g) => g.n(t_erg_tab, 'D28') * g.n(s, 'C7'));
DataGrid.setCell(t_ergtab, 'E28', (s, c, g) => g.n(t_erg_tab, 'E28') * g.n(s, 'C7'));
DataGrid.setCell(t_ergtab, 'F28', (s, c, g) => g.n(t_erg_tab, 'F28') * g.n(s, 'C7'));
DataGrid.setCell(t_ergtab, 'G28', (s, c, g) => g.n(t_erg_tab, 'G28') * g.n(s, 'C7'));
DataGrid.setCell(t_ergtab, 'H28', (s, c, g) => g.n(t_erg_tab, 'H28') * g.n(s, 'C7'));


///direkte Emissionen
DataGrid.setCell(t_ergtab, 'C29', (s, c, g) => g.g(t_erg_tab, 'C29'));
DataGrid.setCell(t_ergtab, 'D29', (s, c, g) => g.g(t_erg_tab, 'D29'));
DataGrid.setCell(t_ergtab, 'E29', (s, c, g) => g.g(t_erg_tab, 'E29'));
DataGrid.setCell(t_ergtab, 'F29', (s, c, g) => g.g(t_erg_tab, 'F29'));
DataGrid.setCell(t_ergtab, 'G29', (s, c, g) => g.g(t_erg_tab, 'G29'));
DataGrid.setCell(t_ergtab, 'H29', (s, c, g) => g.g(t_erg_tab, 'H29'));

// Versauerung
DataGrid.setCell(t_ergtab, 'C30', (s, c, g) => g.n(t_erg_tab, 'C30') * g.n(s, 'C2'));
DataGrid.setCell(t_ergtab, 'D30', (s, c, g) => g.n(t_erg_tab, 'D30') * g.n(s, 'C2'));
DataGrid.setCell(t_ergtab, 'E30', (s, c, g) => g.n(t_erg_tab, 'E30') * g.n(s, 'C2'));
DataGrid.setCell(t_ergtab, 'F30', (s, c, g) => g.n(t_erg_tab, 'F30') * g.n(s, 'C2'));
DataGrid.setCell(t_ergtab, 'G30', (s, c, g) => g.n(t_erg_tab, 'G30') * g.n(s, 'C2'));
DataGrid.setCell(t_ergtab, 'H30', (s, c, g) => g.n(t_erg_tab, 'H30') * g.n(s, 'C2'));

// Treibhauseffekt
DataGrid.setCell(t_ergtab, 'C31', (s, c, g) => g.n(t_erg_tab, 'C31') * g.n(s, 'C3'));
DataGrid.setCell(t_ergtab, 'D31', (s, c, g) => g.n(t_erg_tab, 'D31') * g.n(s, 'C3'));
DataGrid.setCell(t_ergtab, 'E31', (s, c, g) => g.n(t_erg_tab, 'E31') * g.n(s, 'C3'));
DataGrid.setCell(t_ergtab, 'F31', (s, c, g) => g.n(t_erg_tab, 'F31') * g.n(s, 'C3'));
DataGrid.setCell(t_ergtab, 'G31', (s, c, g) => g.n(t_erg_tab, 'G31') * g.n(s, 'C3'));
DataGrid.setCell(t_ergtab, 'H31', (s, c, g) => g.n(t_erg_tab, 'H31') * g.n(s, 'C3'));

// Feinstaub
DataGrid.setCell(t_ergtab, 'C32', (s, c, g) => g.n(t_erg_tab, 'C32') * g.n(s, 'C4'));
DataGrid.setCell(t_ergtab, 'D32', (s, c, g) => g.n(t_erg_tab, 'D32') * g.n(s, 'C4'));
DataGrid.setCell(t_ergtab, 'E32', (s, c, g) => g.n(t_erg_tab, 'E32') * g.n(s, 'C4'));
DataGrid.setCell(t_ergtab, 'F32', (s, c, g) => g.n(t_erg_tab, 'F32') * g.n(s, 'C4'));
DataGrid.setCell(t_ergtab, 'G32', (s, c, g) => g.n(t_erg_tab, 'G32') * g.n(s, 'C4'));
DataGrid.setCell(t_ergtab, 'H32', (s, c, g) => g.n(t_erg_tab, 'H32') * g.n(s, 'C4'));

// Eutrophierung (terr.)
DataGrid.setCell(t_ergtab, 'C33', (s, c, g) => g.n(t_erg_tab, 'C33') * g.n(s, 'C5'));
DataGrid.setCell(t_ergtab, 'D33', (s, c, g) => g.n(t_erg_tab, 'D33') * g.n(s, 'C5'));
DataGrid.setCell(t_ergtab, 'E33', (s, c, g) => g.n(t_erg_tab, 'E33') * g.n(s, 'C5'));
DataGrid.setCell(t_ergtab, 'F33', (s, c, g) => g.n(t_erg_tab, 'F33') * g.n(s, 'C5'));
DataGrid.setCell(t_ergtab, 'G33', (s, c, g) => g.n(t_erg_tab, 'G33') * g.n(s, 'C5'));
DataGrid.setCell(t_ergtab, 'H33', (s, c, g) => g.n(t_erg_tab, 'H33') * g.n(s, 'C5'));

// Sommersmog
DataGrid.setCell(t_ergtab, 'C34', (s, c, g) => g.n(t_erg_tab, 'C34') * g.n(s, 'C6'));
DataGrid.setCell(t_ergtab, 'D34', (s, c, g) => g.n(t_erg_tab, 'D34') * g.n(s, 'C6'));
DataGrid.setCell(t_ergtab, 'E34', (s, c, g) => g.n(t_erg_tab, 'E34') * g.n(s, 'C6'));
DataGrid.setCell(t_ergtab, 'F34', (s, c, g) => g.n(t_erg_tab, 'F34') * g.n(s, 'C6'));
DataGrid.setCell(t_ergtab, 'G34', (s, c, g) => g.n(t_erg_tab, 'G34') * g.n(s, 'C6'));
DataGrid.setCell(t_ergtab, 'H34', (s, c, g) => g.n(t_erg_tab, 'H34') * g.n(s, 'C6'));

// Kumulierter Energieaufwand
DataGrid.setCell(t_ergtab, 'C35', (s, c, g) => g.n(t_erg_tab, 'C35') * g.n(s, 'C7'));
DataGrid.setCell(t_ergtab, 'D35', (s, c, g) => g.n(t_erg_tab, 'D35') * g.n(s, 'C7'));
DataGrid.setCell(t_ergtab, 'E35', (s, c, g) => g.n(t_erg_tab, 'E35') * g.n(s, 'C7'));
DataGrid.setCell(t_ergtab, 'F35', (s, c, g) => g.n(t_erg_tab, 'F35') * g.n(s, 'C7'));
DataGrid.setCell(t_ergtab, 'G35', (s, c, g) => g.n(t_erg_tab, 'G35') * g.n(s, 'C7'));
DataGrid.setCell(t_ergtab, 'H35', (s, c, g) => g.n(t_erg_tab, 'H35') * g.n(s, 'C7'));
DataGrid.setCell(t_ergtab, 'C36', (s, c, g) => g.n(t_erg_tab, 'C36') * g.n(s, 'C7'));
DataGrid.setCell(t_ergtab, 'D36', (s, c, g) => g.n(t_erg_tab, 'D36') * g.n(s, 'C7'));
DataGrid.setCell(t_ergtab, 'E36', (s, c, g) => g.n(t_erg_tab, 'E36') * g.n(s, 'C7'));
DataGrid.setCell(t_ergtab, 'F36', (s, c, g) => g.n(t_erg_tab, 'F36') * g.n(s, 'C7'));
DataGrid.setCell(t_ergtab, 'G36', (s, c, g) => g.n(t_erg_tab, 'G36') * g.n(s, 'C7'));
DataGrid.setCell(t_ergtab, 'H36', (s, c, g) => g.n(t_erg_tab, 'H36') * g.n(s, 'C7'));


///Wartung / Entsorgung
DataGrid.setCell(t_ergtab, 'C37', (s, c, g) => g.g(t_erg_tab, 'C37'));
DataGrid.setCell(t_ergtab, 'D37', (s, c, g) => g.g(t_erg_tab, 'D37'));
DataGrid.setCell(t_ergtab, 'E37', (s, c, g) => g.g(t_erg_tab, 'E37'));
DataGrid.setCell(t_ergtab, 'F37', (s, c, g) => g.g(t_erg_tab, 'F37'));
DataGrid.setCell(t_ergtab, 'G37', (s, c, g) => g.g(t_erg_tab, 'G37'));
DataGrid.setCell(t_ergtab, 'H37', (s, c, g) => g.g(t_erg_tab, 'H37'));

// Versauerung
DataGrid.setCell(t_ergtab, 'C38', (s, c, g) => g.n(t_erg_tab, 'C38') * g.n(s, 'C2'));
DataGrid.setCell(t_ergtab, 'D38', (s, c, g) => g.n(t_erg_tab, 'D38') * g.n(s, 'C2'));
DataGrid.setCell(t_ergtab, 'E38', (s, c, g) => g.n(t_erg_tab, 'E38') * g.n(s, 'C2'));
DataGrid.setCell(t_ergtab, 'F38', (s, c, g) => g.n(t_erg_tab, 'F38') * g.n(s, 'C2'));
DataGrid.setCell(t_ergtab, 'G38', (s, c, g) => g.n(t_erg_tab, 'G38') * g.n(s, 'C2'));
DataGrid.setCell(t_ergtab, 'H38', (s, c, g) => g.n(t_erg_tab, 'H38') * g.n(s, 'C2'));

// Treibhauseffekt
DataGrid.setCell(t_ergtab, 'C39', (s, c, g) => g.n(t_erg_tab, 'C39') * g.n(s, 'C3'));
DataGrid.setCell(t_ergtab, 'D39', (s, c, g) => g.n(t_erg_tab, 'D39') * g.n(s, 'C3'));
DataGrid.setCell(t_ergtab, 'E39', (s, c, g) => g.n(t_erg_tab, 'E39') * g.n(s, 'C3'));
DataGrid.setCell(t_ergtab, 'F39', (s, c, g) => g.n(t_erg_tab, 'F39') * g.n(s, 'C3'));
DataGrid.setCell(t_ergtab, 'G39', (s, c, g) => g.n(t_erg_tab, 'G39') * g.n(s, 'C3'));
DataGrid.setCell(t_ergtab, 'H39', (s, c, g) => g.n(t_erg_tab, 'H39') * g.n(s, 'C3'));

// Feinstaub
DataGrid.setCell(t_ergtab, 'C40', (s, c, g) => g.n(t_erg_tab, 'C40') * g.n(s, 'C4'));
DataGrid.setCell(t_ergtab, 'D40', (s, c, g) => g.n(t_erg_tab, 'D40') * g.n(s, 'C4'));
DataGrid.setCell(t_ergtab, 'E40', (s, c, g) => g.n(t_erg_tab, 'E40') * g.n(s, 'C4'));
DataGrid.setCell(t_ergtab, 'F40', (s, c, g) => g.n(t_erg_tab, 'F40') * g.n(s, 'C4'));
DataGrid.setCell(t_ergtab, 'G40', (s, c, g) => g.n(t_erg_tab, 'G40') * g.n(s, 'C4'));
DataGrid.setCell(t_ergtab, 'H40', (s, c, g) => g.n(t_erg_tab, 'H40') * g.n(s, 'C4'));

// Eutrophierung (terr.)
DataGrid.setCell(t_ergtab, 'C41', (s, c, g) => g.n(t_erg_tab, 'C41') * g.n(s, 'C5'));
DataGrid.setCell(t_ergtab, 'D41', (s, c, g) => g.n(t_erg_tab, 'D41') * g.n(s, 'C5'));
DataGrid.setCell(t_ergtab, 'E41', (s, c, g) => g.n(t_erg_tab, 'E41') * g.n(s, 'C5'));
DataGrid.setCell(t_ergtab, 'F41', (s, c, g) => g.n(t_erg_tab, 'F41') * g.n(s, 'C5'));
DataGrid.setCell(t_ergtab, 'G41', (s, c, g) => g.n(t_erg_tab, 'G41') * g.n(s, 'C5'));
DataGrid.setCell(t_ergtab, 'H41', (s, c, g) => g.n(t_erg_tab, 'H41') * g.n(s, 'C5'));

// Sommersmog
DataGrid.setCell(t_ergtab, 'C42', (s, c, g) => g.n(t_erg_tab, 'C42') * g.n(s, 'C6'));
DataGrid.setCell(t_ergtab, 'D42', (s, c, g) => g.n(t_erg_tab, 'D42') * g.n(s, 'C6'));
DataGrid.setCell(t_ergtab, 'E42', (s, c, g) => g.n(t_erg_tab, 'E42') * g.n(s, 'C6'));
DataGrid.setCell(t_ergtab, 'F42', (s, c, g) => g.n(t_erg_tab, 'F42') * g.n(s, 'C6'));
DataGrid.setCell(t_ergtab, 'G42', (s, c, g) => g.n(t_erg_tab, 'G42') * g.n(s, 'C6'));
DataGrid.setCell(t_ergtab, 'H42', (s, c, g) => g.n(t_erg_tab, 'H42') * g.n(s, 'C6'));

// Kumulierter Energieaufwand
DataGrid.setCell(t_ergtab, 'C43', (s, c, g) => g.n(t_erg_tab, 'C43') * g.n(s, 'C7'));
DataGrid.setCell(t_ergtab, 'D43', (s, c, g) => g.n(t_erg_tab, 'D43') * g.n(s, 'C7'));
DataGrid.setCell(t_ergtab, 'E43', (s, c, g) => g.n(t_erg_tab, 'E43') * g.n(s, 'C7'));
DataGrid.setCell(t_ergtab, 'F43', (s, c, g) => g.n(t_erg_tab, 'F43') * g.n(s, 'C7'));
DataGrid.setCell(t_ergtab, 'G43', (s, c, g) => g.n(t_erg_tab, 'G43') * g.n(s, 'C7'));
DataGrid.setCell(t_ergtab, 'H43', (s, c, g) => g.n(t_erg_tab, 'H43') * g.n(s, 'C7'));
DataGrid.setCell(t_ergtab, 'C44', (s, c, g) => g.n(t_erg_tab, 'C44') * g.n(s, 'C7'));
DataGrid.setCell(t_ergtab, 'D44', (s, c, g) => g.n(t_erg_tab, 'D44') * g.n(s, 'C7'));
DataGrid.setCell(t_ergtab, 'E44', (s, c, g) => g.n(t_erg_tab, 'E44') * g.n(s, 'C7'));
DataGrid.setCell(t_ergtab, 'F44', (s, c, g) => g.n(t_erg_tab, 'F44') * g.n(s, 'C7'));
DataGrid.setCell(t_ergtab, 'G44', (s, c, g) => g.n(t_erg_tab, 'G44') * g.n(s, 'C7'));
DataGrid.setCell(t_ergtab, 'H44', (s, c, g) => g.n(t_erg_tab, 'H44') * g.n(s, 'C7'));


/// Fahrzeuggewicht
DataGrid.setCell(t_ergtab, 'C45', (s, c, g) => g.g(t_erg_tab, 'C45'));
DataGrid.setCell(t_ergtab, 'D45', (s, c, g) => g.g(t_erg_tab, 'D45'));
DataGrid.setCell(t_ergtab, 'E45', (s, c, g) => g.g(t_erg_tab, 'E45'));
DataGrid.setCell(t_ergtab, 'F45', (s, c, g) => g.g(t_erg_tab, 'F45'));
DataGrid.setCell(t_ergtab, 'G45', (s, c, g) => g.g(t_erg_tab, 'G45'));
DataGrid.setCell(t_ergtab, 'H45', (s, c, g) => g.g(t_erg_tab, 'H45'));


/// KEA
DataGrid.setCell(t_ergtab, 'C48', (s, c, g) => g.SUM(s, ['C19', 'C27', 'C35', 'C43']));
DataGrid.setCell(t_ergtab, 'D48', (s, c, g) => g.SUM(s, ['D19', 'D27', 'D35', 'D43']));
DataGrid.setCell(t_ergtab, 'E48', (s, c, g) => g.SUM(s, ['E19', 'E27', 'E35', 'E43']));
DataGrid.setCell(t_ergtab, 'F48', (s, c, g) => g.SUM(s, ['F19', 'F27', 'F35', 'F43']));
DataGrid.setCell(t_ergtab, 'G48', (s, c, g) => g.SUM(s, ['G19', 'G27', 'G35', 'G43']));
DataGrid.setCell(t_ergtab, 'H48', (s, c, g) => g.SUM(s, ['H19', 'H27', 'H35', 'H43']));

DataGrid.setCell(t_ergtab, 'C49', (s, c, g) => g.SUM(s, ['C20', 'C28', 'C36', 'C44']));
DataGrid.setCell(t_ergtab, 'D49', (s, c, g) => g.SUM(s, ['D20', 'D28', 'D36', 'D44']));
DataGrid.setCell(t_ergtab, 'E49', (s, c, g) => g.SUM(s, ['E20', 'E28', 'E36', 'E44']));
DataGrid.setCell(t_ergtab, 'F49', (s, c, g) => g.SUM(s, ['F20', 'F28', 'F36', 'F44']));
DataGrid.setCell(t_ergtab, 'G49', (s, c, g) => g.SUM(s, ['G20', 'G28', 'G36', 'G44']));
DataGrid.setCell(t_ergtab, 'H49', (s, c, g) => g.SUM(s, ['H20', 'H28', 'H36', 'H44']));


/// Anzeigefaktoren
DataGrid.setCell(t_ergtab, 'I2', (s, c, g) => g.g('Eingabeparameter', 'F5'));

DataGrid.setCell(t_ergtab, 'K2', 31.42176327);
DataGrid.setCell(t_ergtab, 'K3', 10928.89354);
DataGrid.setCell(t_ergtab, 'K4', 25.47052281);
DataGrid.setCell(t_ergtab, 'K5', 4.713764475);
DataGrid.setCell(t_ergtab, 'K6', 15.61715267);
DataGrid.setCell(t_ergtab, 'K7', 173875.2524);


DataGrid.setCell(t_ergtab, 'L2', (s, c, g) => g.n(s, 'K2') * g.n(s, 'C2'));
DataGrid.setCell(t_ergtab, 'L3', (s, c, g) => g.n(s, 'K3') * g.n(s, 'C3'));
DataGrid.setCell(t_ergtab, 'L4', (s, c, g) => g.n(s, 'K4') * g.n(s, 'C4'));
DataGrid.setCell(t_ergtab, 'L5', (s, c, g) => g.n(s, 'K5') * g.n(s, 'C5'));
DataGrid.setCell(t_ergtab, 'L6', (s, c, g) => g.n(s, 'K6') * g.n(s, 'C6'));
DataGrid.setCell(t_ergtab, 'L7', (s, c, g) => g.n(s, 'K7') * g.n(s, 'C7'));

// Einwohnerdurchschnittswerte (pro Fahrzeugleben)
DataGrid.setCells(t_ergtab, 'K14', 'P44', (s, c, g) => {
  // P44=H44/$L7*$I$2
  const $ox = g.COLUMN(c);
  const $oy = g.ROW(c);

  const $lines = {
    14: 2, 15: 3, 16: 4, 17: 5, 18: 6, 19: 7, 20: 7,
    22: 2, 23: 3, 24: 4, 25: 5, 26: 6, 27: 7, 28: 7,
    30: 2, 31: 3, 32: 4, 33: 5, 34: 6, 35: 7, 36: 7,
    38: 2, 39: 3, 40: 4, 41: 5, 42: 6, 43: 7, 44: 7
  };

  return g.n(s, g.index2cell($ox - 8, $oy)) / g.n(s, `L${$lines[$oy]}`) * g.n(s, 'I2');
});

DataGrid.setCells(t_ergtab, 'K48', 'P49', (s, c, g) => {
  // K48==SUM(K19;K27;K35;K43)
  const $ox = g.COLUMN(c);
  const $oy = g.ROW(c);
  return g.SUM(s, [g.index2cell($ox, $oy - 29), g.index2cell($ox, $oy - 21), g.index2cell($ox, $oy - 13), g.index2cell($ox, $oy - 5)]);
});
DataGrid.setCells(t_ergtab, 'K52', 'P52', (s, c, g) => {
  const $ox = g.COLUMN(c);
  //var $oy = g.ROW(c);
  return g.SUM(s, [g.index2cell($ox, 15), g.index2cell($ox, 23), g.index2cell($ox, 31), g.index2cell($ox, 39)]);
});
DataGrid.setCells(t_ergtab, 'K53', 'P53', (s, c, g) => {
  const $ox = g.COLUMN(c);
  //var $oy = g.ROW(c);
  return g.SUM(s, [g.index2cell($ox, 14), g.index2cell($ox, 22), g.index2cell($ox, 30), g.index2cell($ox, 38)]);
});
DataGrid.setCells(t_ergtab, 'K54', 'P54', (s, c, g) => {
  const $ox = g.COLUMN(c);
  //var $oy = g.ROW(c);
  return g.SUM(s, [g.index2cell($ox, 16), g.index2cell($ox, 24), g.index2cell($ox, 32), g.index2cell($ox, 40)]);
});
DataGrid.setCells(t_ergtab, 'K55', 'P55', (s, c, g) => {
  const $ox = g.COLUMN(c);
  //var $oy = g.ROW(c);
  return g.SUM(s, [g.index2cell($ox, 18), g.index2cell($ox, 26), g.index2cell($ox, 34), g.index2cell($ox, 42)]);
});
DataGrid.setCells(t_ergtab, 'K56', 'P56', (s, c, g) => {
  const $ox = g.COLUMN(c);
  //var $oy = g.ROW(c);
  return g.SUM(s, [g.index2cell($ox, 17), g.index2cell($ox, 25), g.index2cell($ox, 33), g.index2cell($ox, 41)]);
});

























