import {DataGrid, DataGridClass} from "./calc";
import {FLT, roundNumber} from "./data_entwurf";
import {init} from "echarts";

declare global {
  interface Number {
    smartCeil(scale?: number): number;

    smartInterval(scale?: number): number;
  }
}

interface VehicleStatus {
  Verbrennungsfahrzeug_aktiv: boolean;
  Plug_in_Hybrid_aktiv: boolean;
  Batterie_Fahrzeug_aktiv: boolean;
  derzeitiges_Auto_aktiv: boolean;
}

Number.prototype.smartCeil = function ($scale) {
  if (typeof $scale == 'undefined') {
    $scale = 2;
  }
  const $magnitude = Math.ceil(Math.log((this as number)) / Math.log(10));
  const $roundingScale = $magnitude * -1 + $scale;
  const $roundingFactor = 10 ** $roundingScale;

  return Math.ceil((this as number) * $roundingFactor) / $roundingFactor;
};
Number.prototype.smartInterval = function ($scale) {
  if (typeof $scale == 'undefined') {
    $scale = 2;
  }
  let $i = (10 / ((this as number) / (this.smartCeil($scale))));
  if ($i > 50) {
    $i = $i / 10;
  }
  return $i;
};



interface PlotData {
  yMax: number;
  imgsrc: string;
}

interface ChartData {
  feinstaub: PlotData;
  smog: PlotData;
  ozon: PlotData;
  klimawirkung: PlotData;
  energie: PlotData;
  umweltwirkung: PlotData;
  acid: PlotData;
  FLT: PlotData;

  [opt: string]: any;    // for potential extra properties
}


export const drawFLTGraph = (g: DataGridClass, fltoption: VehicleStatus) => {

	const chart_FTL = init(document.getElementById('chart_FLT') as HTMLDivElement, null, {renderer: 'svg'});

	const defaults = {
		type: 'line',
		showSymbol: false,
		endLabel: {
			show: true,
			formatter(params: { value: number }) {
				return `${roundNumber(params.value, 1).toLocaleString('de-DE', {maximumFractionDigits: 1, minimumFractionDigits: 1})} t CO₂-Äq.`;
			}
		}
	};

	// Jahre
	//const jahre = g.getCells(FLT, 'R40', 'AE40');
	//const betrj = g.getCells(FLT, 'R39', 'AE39');
	// KM
	const fahrl: number[] = g.getCells(FLT, 'R38', 'AE38')
		.map((value: number) => roundNumber(value, 0));

	chart_FTL.setOption({
		tooltip: {
			show: false
		},
		legend: {
			show: false,
			data: ['Ihr Auto', 'ICEV aktuell', 'PHEV', 'BEV'],
		},
		xAxis: {
			type: 'category',
			data: fahrl,
			axisLabel: {
				interval: 'auto',
				showMinLabel: false,
				showMaxLabel: true,
				formatter: (value: string) => parseInt(value, 10).toLocaleString('de-DE') + ' km'
			}
		},
		yAxis: {
			type: 'value'
		},
		grid: {
			right: 76,
			bottom: 20,
			top: 20,
			left: 25
		},
		color: ['#5b8ac1', '#ff4343', '#ff934b', '#35aa26'],

		series: [
			{
				name: 'Ihr Auto',
				data: fltoption.derzeitiges_Auto_aktiv ? g.getCells(FLT, 'R41', 'AE41') as number[] : [],
        ...defaults
      },
      {
        name: 'ICEV aktuell',
        data: fltoption.Verbrennungsfahrzeug_aktiv ? g.getCells(FLT, 'R42', 'AE42') as number[] : [],
        ...defaults
      },
      {
        name: 'PHEV',
        data: fltoption.Plug_in_Hybrid_aktiv ? g.getCells(FLT, 'R43', 'AE43') as number[] : [],
        ...defaults
      },
      {
        name: 'BEV',
        data: fltoption.Batterie_Fahrzeug_aktiv ? g.getCells(FLT, 'R44', 'AE44') as number[] : [],
        ...defaults
      }
    ]
  });
};




export const drawChart = (chartdata: ChartData, g: DataGridClass, showPHEV: boolean) => {

	const $kraftstoff = g.getCell('Eingabeparameter', 'B14');

	const carTypeColors = ['#595959', '#A6A6A6', '#558ED5', '#77933C'];
	const lifecycleColors = ['#4f81bd', '#c0504d', '#9bbb59', '#8064a2'];

	const $excludeRegex = showPHEV ? /^[GH]/ : /^[EF]/;

	const legendLifeCycle = [
		"Fahrzeugherstellung & Entsorgung",
		"Energiebereitstellung",
		"direkte Emission",
		"Wartung",
	];
	const labelKumulierterEnergieaufwand = [
		'Kumulierter Energieaufwand, nicht erneuerbar',
		'Kumulierter Energieaufwand, erneuerbar'
	];

	const vergleichsAutoTypMap = {
		'Benzin': 'Benziner',
		'Bioethanol': 'Benziner',
		'Diesel': 'Diesel',
		'Biodiesel': 'Diesel'
	};
	const xLabel = [
		`Ihr Fahrzeug`,
		`aktueller\n${(vergleichsAutoTypMap[$kraftstoff] || '')}`,
		`${showPHEV ? 'Plugin-Hybrid' : 'Elektrofahrzeug'}\n(Strommix D)`,
		`${showPHEV ? 'Plugin-Hybrid' : 'Elektrofahrzeug'}\n(Sonnenstrom)`
	];

	const $xLabel_umweltwirkungen = ['Versauerung', 'Feinstaub', 'Sommersmog', 'Eutrophierung (terr.)'];


	//=SERIES(Ergebnistabelle!$B$29;Ergebnistabelle!$C$12:$H$12;Ergebnistabelle!$C$31:$H$31;3)
	/// Treibhausgase
	{
		const chart_klimawirkung = init(document.getElementById('chart_klimawirkung') as HTMLDivElement, null, {renderer: 'svg'});
		const $data: number[][] = [];
		$data.push(g.getCells('Ergebnistabelle', 'C15', 'H15', $excludeRegex) as number[]);
		$data.push(g.getCells('Ergebnistabelle', 'C23', 'H23', $excludeRegex) as number[]);
		$data.push(g.getCells('Ergebnistabelle', 'C31', 'H31', $excludeRegex) as number[]);
		$data.push(g.getCells('Ergebnistabelle', 'C39', 'H39', $excludeRegex) as number[]);


		// Berechne die Summe für jede Gruppe
		const $CarSums = $data[0].map((_, index) => $data.reduce((sum, categoryData) => sum + (categoryData[index]??0), 0));
		const $yMax = Math.ceil(450 * 1.2);

		// Processing percent changes
		// TODO add marker to chart
		const $dataChange = $CarSums.map(d => 100 * d / $CarSums[0] - 100);

		chart_klimawirkung.setOption({
			color: lifecycleColors,
			legend: {
				data: legendLifeCycle,
			},
			xAxis: {
				type: 'category',
				data: xLabel,				
				axisLabel: {interval: 0, rotate: 0},
			},
			yAxis: {
				type: 'value',
				max: $yMax,
				interval: $yMax / 10
			},
			series: $data.map((seriesData, index, data) => ({
				name: legendLifeCycle[index] ?? '',
				label: {
					show: index === data.length - 1,
					position: 'top',
					formatter: index === data.length - 1 ? params => roundNumber($CarSums[params.dataIndex], 0).toLocaleString('de-DE') : ''
				},
				type: 'bar',
				stack: 'total',
				barWidth: '40%',
				data: seriesData
			}))
		});
	}



	/// Kumulierter Energieaufwand
	{
		const chart_energie = init(document.getElementById('chart_energie') as HTMLElement, null, {renderer: 'svg'});

		const $data:number[][] = [];
		$data.push(DataGrid.getCells('Ergebnistabelle', 'C48', 'H48', $excludeRegex) as number[]);
		$data.push(DataGrid.getCells('Ergebnistabelle', 'C49', 'H49', $excludeRegex) as number[]);

		// Percent changes
		const $CarSums = $data[0].map((_, index) => $data.reduce((sum, categoryData) => sum + (categoryData[index] ?? 0), 0));

		const $yMax = roundNumber(Math.max(37.5, ...$data[0]) * 1.2, 2);

		const $dataChange = $CarSums.map(d => 100 * d / $CarSums[0] - 100);

		chart_energie.setOption({
			grid: {
				left: '3%',
				right: '4%',
				bottom: '3%',
				containLabel: true
			},
			color: ['#948a54', '#ffc000'],
			legend: {
				data: labelKumulierterEnergieaufwand,
			},
			xAxis: {
				type: 'category',
				data: xLabel,
				axisLabel: {interval: 0, rotate: 0},
			},
			yAxis: {
				type: 'value',
				max: $yMax,
				interval: $yMax / 10
			},
			series: [
				{
					name: labelKumulierterEnergieaufwand[0],
					type: 'bar',
					stack: 'total',
					data: $data[0],
				},
				{
					name: labelKumulierterEnergieaufwand[1],
					type: 'bar',
					stack: 'total',
					barWidth: '40%',
					data: $data[1],

					// Label with sum above last bar segment
					label: {
						show: true,
						position: 'top',
						formatter: params => roundNumber($CarSums[params.dataIndex], 1).toLocaleString('de-DE')
					},
				}
			]
		});
	}


	/// Umweltwirkungen
	{
		const chart_umweltwirkungen = init(document.getElementById('chart_umweltwirkung') as HTMLElement, null, {renderer: 'svg'});

		const $data = [];
		$data.push(DataGrid.getCells('Ergebnistabelle', 'K53', 'K56') as number[]);
		$data.push(DataGrid.getCells('Ergebnistabelle', 'L53', 'L56') as number[]);
		if (showPHEV) {
			$data.push(DataGrid.getCells('Ergebnistabelle', 'M53', 'M56') as number[]);
			$data.push(DataGrid.getCells('Ergebnistabelle', 'N53', 'N56') as number[]);
		} else {
			$data.push(DataGrid.getCells('Ergebnistabelle', 'O53', 'O56') as number[]);
			$data.push(DataGrid.getCells('Ergebnistabelle', 'P53', 'P56') as number[]);
		}

		const $yMax = roundNumber(Math.max(10, ...$data[0]), 2);

		chart_umweltwirkungen.setOption({
			legend: {
				data: xLabel
			},
			grid: {
				left: '3%',
				right: '4%',
				bottom: '3%',
				containLabel: true
			},
			xAxis: {
				type: 'category',
				data: $xLabel_umweltwirkungen
			},
			yAxis: {
				type: 'value',
				max: $yMax
			},
			series: $data.map((seriesData, index) => ({
				name: xLabel[index],
				type: 'bar',
				data: seriesData,
				itemStyle: {
					color: carTypeColors[index]
				},
				label: {
					show: true,
					position: 'top',
					formatter: params => roundNumber(params.value, 1).toLocaleString('de-DE')
				},
			}))
		});
	}


	/// Versauerung
	{
		const chart_acid = init(document.getElementById('chart_acid') as HTMLElement, null, {renderer: 'svg'});

		const $data: number[][] = [];
		$data.push(DataGrid.getCells('Ergebnistabelle', 'C14', 'H14', $excludeRegex) as number[]);
		$data.push(DataGrid.getCells('Ergebnistabelle', 'C22', 'H22', $excludeRegex) as number[]);
		$data.push(DataGrid.getCells('Ergebnistabelle', 'C30', 'H30', $excludeRegex) as number[]);
		$data.push(DataGrid.getCells('Ergebnistabelle', 'C38', 'H38', $excludeRegex) as number[]);

		// Calculating maximum Y-axis value based on data sums
		const $CarSums = $data[0].map((_, index) => $data.reduce((sum, categoryData) => sum + (categoryData[index] ?? 0), 0));

		const $yMax = Math.max((2 * 1.2));

		chart_acid.setOption({
			legend: {
				data: legendLifeCycle
			},
			grid: {
				left: '3%',
				right: '4%',
				bottom: '3%',
				containLabel: true
			},
			xAxis: {
				type: 'category',
				data: xLabel,
				axisLabel: {interval: 0, rotate: 0},
			},
			yAxis: {
				type: 'value',
				max: $yMax
			},
			series: $data.map((seriesData, index, data) => ({
				name: legendLifeCycle[index],
				type: 'bar',
				stack: 'total',
				data: seriesData,
				itemStyle: {
					color: lifecycleColors[index]
				},
				label: {
					show: index === data.length - 1,
					position: 'top',
					formatter: index === data.length - 1 ? params => roundNumber($CarSums[params.dataIndex], 1).toLocaleString('de-DE') : ''
				},
			}))
		});
	}


	/// Feinstaub
	{
		const chart_feinstaub = init(document.getElementById('chart_feinstaub') as HTMLElement, null, {renderer: 'svg'});

		const $data: number[][] = [];
		$data.push(DataGrid.getCells('Ergebnistabelle', 'C16', 'H16', $excludeRegex) as number[]);
		$data.push(DataGrid.getCells('Ergebnistabelle', 'C24', 'H24', $excludeRegex) as number[]);
		$data.push(DataGrid.getCells('Ergebnistabelle', 'C32', 'H32', $excludeRegex) as number[]);
		$data.push(DataGrid.getCells('Ergebnistabelle', 'C40', 'H40', $excludeRegex) as number[]);

		const $CarSums = $data[0].map((_, index) => $data.reduce((sum, categoryData) => sum + (categoryData[index] ?? 0), 0));

		// Calculating Y-axis maximum dynamically
		const $yMax = roundNumber((1.4 * 1.2), 1);

		chart_feinstaub.setOption({
			legend: {
				data: legendLifeCycle
			},
			grid: {
				left: '3%',
				right: '4%',
				bottom: '3%',
				containLabel: true
			},
			xAxis: {
				type: 'category',
				data: xLabel,
				axisLabel: {interval: 0, rotate: 0},
			},
			yAxis: {
				type: 'value',
				max: $yMax
			},
			series: $data.map((seriesData, index, data) => ({
				name: legendLifeCycle[index],
				type: 'bar',
				stack: 'total',
				data: seriesData,
				itemStyle: {
					color: lifecycleColors[index]
				},
				label: {
					show: index === data.length - 1,
					position: 'top',
					formatter: index === data.length - 1 ? params => roundNumber($CarSums[params.dataIndex], 1).toLocaleString('de-DE') : ''
				},
			}))
		});
	}


	/// Sommersmog
	{
		const chart_smog = init(document.getElementById('chart_smog') as HTMLElement, null, {renderer: 'svg'});

		const $data: number[][] = [];
		$data.push(DataGrid.getCells('Ergebnistabelle', 'C18', 'H18', $excludeRegex) as number[]);
		$data.push(DataGrid.getCells('Ergebnistabelle', 'C26', 'H26', $excludeRegex) as number[]);
		$data.push(DataGrid.getCells('Ergebnistabelle', 'C34', 'H34', $excludeRegex) as number[]);
		$data.push(DataGrid.getCells('Ergebnistabelle', 'C42', 'H42', $excludeRegex) as number[]);

		// Calculating maximum Y-axis value based on the sum of all categories
		const $CarSums = $data[0].map((_, index) => $data.reduce((sum, categoryData) => sum + (categoryData[index] ?? 0), 0));
		const $yMax = Math.max((800 * 1.2));

		chart_smog.setOption({
			legend: {
				data: legendLifeCycle
			},
			grid: {
				left: '3%',
				right: '4%',
				bottom: '3%',
				containLabel: true
			},
			xAxis: {
				type: 'category',
				data: xLabel,
				axisLabel: {interval: 0, rotate: 0},
			},
			yAxis: {
				type: 'value',
				max: $yMax
			},
			series: $data.map((seriesData, index, data) => ({
				name: legendLifeCycle[index],
				type: 'bar',
				stack: 'total',
				data: seriesData,
				itemStyle: {
					color: lifecycleColors[index]
				},
				label: {
					show: index === data.length - 1,
					position: 'top',
					formatter: index === data.length - 1 ? params => roundNumber($CarSums[params.dataIndex], 0).toLocaleString('de-DE') : ''
				},
			}))
		});
	}


	/// Eutrophierung (terr.)
	{
		const chart_eutrophierung = init(document.getElementById('chart_eutrophierung') as HTMLElement, null, {renderer: 'svg'});

		const $data: number[][] = [];
		$data.push(DataGrid.getCells('Ergebnistabelle', 'C17', 'H17', $excludeRegex) as number[]);
		$data.push(DataGrid.getCells('Ergebnistabelle', 'C25', 'H25', $excludeRegex) as number[]);
		$data.push(DataGrid.getCells('Ergebnistabelle', 'C33', 'H33', $excludeRegex) as number[]);
		$data.push(DataGrid.getCells('Ergebnistabelle', 'C41', 'H41', $excludeRegex) as number[]);

		// Calculating the sum of each column to determine the maximum Y-axis value
		const $CarSums = $data[0].map((_, index) => $data.reduce((sum, categoryData) => sum + (categoryData[index] ?? 0), 0));
		const $yMax = Math.max((250 * 1.2));

		chart_eutrophierung.setOption({
			legend: {
				data: legendLifeCycle
			},
			grid: {
				left: '3%',
				right: '4%',
				bottom: '3%',
				containLabel: true
			},
			xAxis: {
				type: 'category',
				data: xLabel,
				axisLabel: {interval: 0, rotate: 0},
			},
			yAxis: {
				type: 'value',
				max: $yMax
			},
			series: $data.map((seriesData, index, data) => ({
				name: legendLifeCycle[index],
				type: 'bar',
				stack: 'total',
				data: seriesData,
				itemStyle: {
					color: lifecycleColors[index]
				},
				label: {
					show: index === data.length - 1,
					position: 'top',
					formatter: index === data.length - 1 ? params => roundNumber($CarSums[params.dataIndex], 0).toLocaleString('de-DE') : ''
				},
			}))
		});
	}
};

