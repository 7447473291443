import {CellFunc, DataGrid} from "./calc";
/// ### Eingabeparameter

DataGrid.setCells('Berechnung_Eingabeparameter', 'B38', 'B53', [
	["Benzin"], ["Diesel"], ["Bioethanol"], ["Biodiesel"], [null], ["Benzin"], ["Diesel"], ["Bioethanol"], ["Biodiesel"], [null], [null], [null], ["Benzin"],
	["Diesel"], ["Bioethanol"], ["Biodiesel"]
]);

DataGrid.setCell('Berechnung_Eingabeparameter', 'B55', 'Aktuelles Jahr');
DataGrid.setCell('Berechnung_Eingabeparameter', 'C55', 2024);

DataGrid.setCell('Berechnung_Eingabeparameter', 'B57', 'Energiedichte Li-Ionen-Batterien');
DataGrid.setCell('Berechnung_Eingabeparameter', 'C57', 150);

// Nutzerdefinierte Parameter
DataGrid.setCell('Berechnung_Eingabeparameter', 'C5', (s, c, g) => g.g('Eingabeparameter', 'B5'));
DataGrid.setCell('Berechnung_Eingabeparameter', 'C6', (s, c, g) => g.g('Eingabeparameter', 'B6'));

DataGrid.setCell('Berechnung_Eingabeparameter', 'C9', (s, c, g) => g.g('Eingabeparameter', 'B9'));
DataGrid.setCell('Berechnung_Eingabeparameter', 'C10', (s, c, g) => g.g('Eingabeparameter', 'B10'));
DataGrid.setCell('Berechnung_Eingabeparameter', 'C11', (s, c, g) => g.g('Eingabeparameter', 'B11'));

DataGrid.setCell('Berechnung_Eingabeparameter', 'C13', (s, c, g) => g.g('Eingabeparameter', 'B13'));
DataGrid.setCell('Berechnung_Eingabeparameter', 'C14', (s, c, g) => g.g('Eingabeparameter', 'B14'));
DataGrid.setCell('Berechnung_Eingabeparameter', 'C15', (s, c, g) => g.g('Eingabeparameter', 'B15'));


DataGrid.setCell('Berechnung_Eingabeparameter', 'C17', (s, c, g) => g.g('Nutzung', 'I13'));

DataGrid.setCell('Berechnung_Eingabeparameter', 'C18', (s, c, g) => g.g('Eingabeparameter', 'B18'));

// Nutzerunabhängige Konstanten
DataGrid.setCell('Berechnung_Eingabeparameter', 'C24', 300000);
DataGrid.setCell('Berechnung_Eingabeparameter', 'C25', 16);

DataGrid.setCell('Berechnung_Eingabeparameter', 'C27', (s, c, g) => DataGrid.HLOOKUP(s, g.g(s, 'C13'), 'L22', 'N26', 5, false));

DataGrid.setCell('Berechnung_Eingabeparameter', 'C28', 16);
DataGrid.setCell('Berechnung_Eingabeparameter', 'C29', 4000);
DataGrid.setCell('Berechnung_Eingabeparameter', 'C30', 53);

DataGrid.setCell('Berechnung_Eingabeparameter', 'C33', (s, c, g) => g.g('Eingabeparameter', 'B21'));
DataGrid.setCell('Berechnung_Eingabeparameter', 'C34', (s, c, g) => g.g('Eingabeparameter', 'B22'));
DataGrid.setCell('Berechnung_Eingabeparameter', 'C35', (s, c, g) => g.g('Eingabeparameter', 'B23'));


DataGrid.setCell('Berechnung_Eingabeparameter', 'C38', (s, c, g) => g.g(s, 'L18'));
DataGrid.setCell('Berechnung_Eingabeparameter', 'C39', (s, c, g) => g.g(s, 'M18'));
DataGrid.setCell('Berechnung_Eingabeparameter', 'C40', (s, c, g) => g.g(s, 'N18'));
DataGrid.setCell('Berechnung_Eingabeparameter', 'C41', (s, c, g) => g.g(s, 'O18'));

DataGrid.setCell('Berechnung_Eingabeparameter', 'C43', (s, c, g) => g.g(s, 'L19'));
DataGrid.setCell('Berechnung_Eingabeparameter', 'C44', (s, c, g) => g.g(s, 'M19'));
DataGrid.setCell('Berechnung_Eingabeparameter', 'C45', (s, c, g) => g.g(s, 'N19'));
DataGrid.setCell('Berechnung_Eingabeparameter', 'C46', (s, c, g) => g.g(s, 'O19'));

DataGrid.setCell('Berechnung_Eingabeparameter', 'C48', 0.056);
DataGrid.setCell('Berechnung_Eingabeparameter', 'C49', 0.070);

DataGrid.setCell('Berechnung_Eingabeparameter', 'C50', (s, c, g) => {
	const $C48 = g.n(s, 'C48');
	const $N5 = g.n(s, 'N5');
	const $N7 = g.n(s, 'N7');

	return $N5 / ($C48 * $N7 + (1 - $C48) * $N5);
});
DataGrid.setCell('Berechnung_Eingabeparameter', 'C51', (s, c, g) => {
	const $C48 = g.n(s, 'C49');
	const $N5 = g.n(s, 'N6');
	const $N7 = g.n(s, 'N8');

	return $N5 / ($C48 * $N7 + (1 - $C48) * $N5);
});
DataGrid.setCell('Berechnung_Eingabeparameter', 'C52', (s, c, g) => g.n(s, 'N5') / g.n(s, 'N7'));
DataGrid.setCell('Berechnung_Eingabeparameter', 'C53', (s, c, g) => g.n(s, 'N6') / g.n(s, 'N8'));

// Abgeleitete Größen
DataGrid.setCell('Berechnung_Eingabeparameter', 'H5', (s, c, g) => {
	const $C5 = g.n(s, 'C5');
	const $C25 = g.n(s, 'C25');
	const $C24 = g.n(s, 'C24');

	return ($C5 * $C25 < $C24) ? $C5 * $C25 : $C24;
});
DataGrid.setCell('Berechnung_Eingabeparameter', 'H6', (s, c, g) => (g.n(s, 'C9') * g.n(s, 'C33') + g.n(s, 'C10') * g.n(s, 'C34') + g.n(s, 'C11') * g.n(s, 'C35')) * g.n(s, 'H5'));
DataGrid.setCell('Berechnung_Eingabeparameter', 'H7', (s, c, g) => g.n(s, 'C27') * g.n(s, 'C6'));
DataGrid.setCell('Berechnung_Eingabeparameter', 'H8', (s, c, g) => g.n(s, 'C30') * g.n(s, 'C27'));
DataGrid.setCell('Berechnung_Eingabeparameter', 'H9', (s, c, g) => {
	const $C14 = g.g(s, 'C14');

	return (($C14 === g.g('Dropdownlisten', 'A1')) || ($C14 == g.g('Dropdownlisten', 'A3'))) ? "Otto" : "Diesel";
});

// Nebenrechnungen
DataGrid.setCell('Berechnung_Eingabeparameter', 'L5', 43543);
DataGrid.setCell('Berechnung_Eingabeparameter', 'L6', 42960);
DataGrid.setCell('Berechnung_Eingabeparameter', 'L7', 26700);
DataGrid.setCell('Berechnung_Eingabeparameter', 'L8', 37100);

DataGrid.setCell('Berechnung_Eingabeparameter', 'N10', 73);
DataGrid.setCell('Berechnung_Eingabeparameter', 'N11', 0.000367);
DataGrid.setCell('Berechnung_Eingabeparameter', 'N12', 73.8);
DataGrid.setCell('Berechnung_Eingabeparameter', 'N13', 0.000372);

DataGrid.setCell('Berechnung_Eingabeparameter', 'M5', 0.742);
DataGrid.setCell('Berechnung_Eingabeparameter', 'M6', 0.832);
DataGrid.setCell('Berechnung_Eingabeparameter', 'M7', 0.789);
DataGrid.setCell('Berechnung_Eingabeparameter', 'M8', 0.881);

DataGrid.setCell('Berechnung_Eingabeparameter', 'N5', (s, c, g) => g.n(s, 'L5') * g.n(s, 'M5'));
DataGrid.setCell('Berechnung_Eingabeparameter', 'N6', (s, c, g) => g.n(s, 'L6') * g.n(s, 'M6'));
DataGrid.setCell('Berechnung_Eingabeparameter', 'N7', (s, c, g) => g.n(s, 'L7') * g.n(s, 'M7'));
DataGrid.setCell('Berechnung_Eingabeparameter', 'N8', (s, c, g) => g.n(s, 'L8') * g.n(s, 'M8'));

DataGrid.setCell('Berechnung_Eingabeparameter', 'L10', (s, c, g) =>
	g.n(s, 'N10') * g.n(s, 'L5') / 1000000);
DataGrid.setCell('Berechnung_Eingabeparameter', 'L11', (s, c, g) =>
	g.n(s, 'N11') * g.n(s, 'L5') / 1000000);
DataGrid.setCell('Berechnung_Eingabeparameter', 'L12', (s, c, g) =>
	g.n(s, 'N12') * g.n(s, 'L6') / 1000000);
DataGrid.setCell('Berechnung_Eingabeparameter', 'L13', (s, c, g) =>
	g.n(s, 'N13') * g.n(s, 'L6') / 1000000);

//=1/(1+(1/C48-1)*L7/L5)
DataGrid.setCell('Berechnung_Eingabeparameter', 'E48', (s, c, g) => 1 / (1 + (1 / g.n(s, 'C48') - 1) * g.n(s, 'L7') / g.n(s, 'L5')));
//=1/(1+(1/C49-1)*L8/L6)
DataGrid.setCell('Berechnung_Eingabeparameter', 'E49', (s, c, g) => 1 / (1 + (1 / g.n(s, 'C49') - 1) * g.n(s, 'L8') / g.n(s, 'L6')));

//=(L10*(1-$E$48))*(M5*(1-$C$48)+M7*$C48)
DataGrid.setCell('Berechnung_Eingabeparameter', 'L18', (s, c, g) => (g.n(s, 'L10') * (1 - g.n(s, 'E48'))) * (g.n(s, 'M5') * (1 - g.n(s, 'C48')) + g.n(s, 'M7') * g.n(s, 'C48')));
DataGrid.setCell('Berechnung_Eingabeparameter', 'L19', (s, c, g) => g.n(s, 'L11') * g.n(s, 'M5'));

//=(L12*(1-$E$49))*(M6*(1-$C$49)+M8*$C$49)
DataGrid.setCell('Berechnung_Eingabeparameter', 'M18', (s, c, g) => (g.n(s, 'L12') * (1 - g.n(s, 'E49'))) * (g.n(s, 'M6') * (1 - g.n(s, 'C49')) + g.n(s, 'M8') * g.n(s, 'C49')));
DataGrid.setCell('Berechnung_Eingabeparameter', 'M19', (s, c, g) => g.n(s, 'L13') * g.n(s, 'M6'));

DataGrid.setCell('Berechnung_Eingabeparameter', 'N18', 0);
DataGrid.setCell('Berechnung_Eingabeparameter', 'N19', (s, c, g) => g.n(s, 'L11') * g.n(s, 'M7'));
DataGrid.setCell('Berechnung_Eingabeparameter', 'O18', 0);
DataGrid.setCell('Berechnung_Eingabeparameter', 'O19', (s, c, g) => g.n(s, 'L13') * g.n(s, 'M8'));


DataGrid.setCell('Berechnung_Eingabeparameter', 'L22', 'klein');
DataGrid.setCell('Berechnung_Eingabeparameter', 'L23', (s, c, g) => (g.g(s, 'H9') == "Diesel") ? 0.8 : 0.8);
DataGrid.setCell('Berechnung_Eingabeparameter', 'L24', 0.8);
DataGrid.setCell('Berechnung_Eingabeparameter', 'L25', 0.8);
DataGrid.setCell('Berechnung_Eingabeparameter', 'L26', 0.160);

DataGrid.setCell('Berechnung_Eingabeparameter', 'M22', 'mittel');
DataGrid.setCell('Berechnung_Eingabeparameter', 'M23', 1);
DataGrid.setCell('Berechnung_Eingabeparameter', 'M24', 1);
DataGrid.setCell('Berechnung_Eingabeparameter', 'M25', 1);
DataGrid.setCell('Berechnung_Eingabeparameter', 'M26', 0.182);

DataGrid.setCell('Berechnung_Eingabeparameter', 'N22', 'groß');
DataGrid.setCell('Berechnung_Eingabeparameter', 'N23', (s, c, g) => (g.g(s, 'H9') == "Diesel") ? 1.5 : 1.5);
DataGrid.setCell('Berechnung_Eingabeparameter', 'N24', 1.5);
DataGrid.setCell('Berechnung_Eingabeparameter', 'N25', 1.5);
DataGrid.setCell('Berechnung_Eingabeparameter', 'N26', 0.229);


/// ### Ergebnisse tabellarisch

// Fahrzeugherstellung
DataGrid.setCells('Ergebnisse tabellarisch', 'C14', 'H19', (s, c, g) => {
	//=INDEX(Herstellung!$C$13:$H$16;COLUMN(C14)-2;ROW(C14)-13)
	let $ox = DataGrid.COLUMN(c);
	if ($ox == 6)
		$ox += -1;
	if ($ox == 7)
		$ox += -1;
	if ($ox == 8) // H
		$ox += -2;
	const $oy = g.ROW(c);
	return g.INDEX('Herstellung', 'C13', 'H16', $ox - 2, $oy - 13);
});
DataGrid.setCells('Ergebnisse tabellarisch', 'C20', 'H20', (s, c, g) => {
	let $ox = DataGrid.COLUMN(c);
	if ($ox == 6)
		$ox += -1;
	if ($ox == 7)
		$ox += -1;
	if ($ox == 8) // H
		$ox += -2;
	const $oy = g.ROW(c) - 1;
	return g.INDEX('Herstellung', 'C13', 'I16', $ox - 2, $oy - 12);
});

// Energiebereitstellung
DataGrid.setCells('Ergebnisse tabellarisch', 'C22', 'H27', (s, c, g) => {
	//=INDEX(Energiebereitstellung!$E$14:$J$19;COLUMN(C22)-2;ROW(C22)-21)+INDEX(Energiebereitstellung!$N$14:$S$19;COLUMN(C22)-2;ROW(C22)-21)
	const $ox = g.COLUMN(c);
	const $oy = g.ROW(c);
	return (
		(g.INDEX('Energiebereitstellung', 'E14', 'K19', $ox - 2, $oy - 21) as number) +
		(g.INDEX('Energiebereitstellung', 'N14', 'T19', $ox - 2, $oy - 21) as number)
	);
});
DataGrid.setCells('Ergebnisse tabellarisch', 'C28', 'H28', (s, c, g) => {
	const $ox = g.COLUMN(c);
	const $oy = g.ROW(c) - 1;
	return (
		(g.INDEX('Energiebereitstellung', 'E14', 'K19', $ox - 2, $oy - 20) as number) +
		(g.INDEX('Energiebereitstellung', 'N14', 'T19', $ox - 2, $oy - 20) as number)
	);
});

// PHEV Grausstrom
DataGrid.setCells('Ergebnisse tabellarisch', 'I22', 'I26', (s, c, g) =>
	// =INDEX($Energiebereitstellung.$O$14:$T$19;COLUMN(E22)-2;ROW(E22)-21)
	g.INDEX('Energiebereitstellung', 'N14', 'S19', g.COLUMN('E27') - 2, g.ROW(c) - 21)
);
DataGrid.setCells('Ergebnisse tabellarisch', 'I27', 'I28', (s, c, g) =>
	// =INDEX($Energiebereitstellung.$O$14:$U$19;COLUMN(E27)-2;ROW(E27)-21)
	g.INDEX('Energiebereitstellung', 'N14', 'T19', g.COLUMN('E27') - 2, g.ROW('E27') - 21)
);

// direkte Emission
DataGrid.setCells('Ergebnisse tabellarisch', 'C30', 'H35', (s, c, g) => {
	//=INDEX(Nutzung!$C$13:$H$16;COLUMN(C30)-2;ROW(C30)-29)
	let $ox = g.COLUMN(c);
	if ($ox == 6)
		$ox = 5;
	if ($ox == 7)
		$ox = 6;
	if ($ox == 8) // H
		$ox = 6;
	const $oy = g.ROW(c);

	return g.INDEX('Nutzung', 'C13', 'H16', $ox - 2, $oy - 29);
});
DataGrid.setCells('Ergebnisse tabellarisch', 'C36', 'H36', (s, c, g) => {
	let $ox = g.COLUMN(c);
	if ($ox == 6)
		$ox = 5;
	if ($ox == 7)
		$ox = 6;
	if ($ox == 8) // H
		$ox = 6;
	const $oy = g.ROW(c) - 1;
	console.log(`${$ox - 2}:${$oy - 29}`);
	return g.INDEX('Nutzung', 'C13', 'H16', $ox - 2, $oy - 29);
});

// Wartung / Entsorgung
DataGrid.setCells('Ergebnisse tabellarisch', 'C38', 'H43', (s, c, g) => {
	//=INDEX($'Wartung u. Entsorgung'.$D$14:$I$19;SPALTE(C38)-2;ZEILE(C38)-37)+INDEX($'Wartung u. Entsorgung'.$M$14:$R$19;SPALTE(C38)-2;ZEILE(C38)-37)
	const $ox = g.COLUMN(c);
	const $oy = g.ROW(c);
	return (
		(g.INDEX('Wartung u. Entsorgung', 'D14', 'I19', $ox - 2, $oy - 37) as number) +
		(g.INDEX('Wartung u. Entsorgung', 'M14', 'R19', $ox - 2, $oy - 37) as number)
	);
});
DataGrid.setCells('Ergebnisse tabellarisch', 'C43', 'H43', (s, c, g) => {
	//=INDEX($'Wartung u. Entsorgung'.$D$14:$J$19;SPALTE(C43)-2;ZEILE(C43)-37)+INDEX($'Wartung u. Entsorgung'.$M$14:$S$19;SPALTE(C43)-2;ZEILE(C43)-37)
	const $ox = g.COLUMN(c);
	const $oy = g.ROW(c);
	return (
		(g.INDEX('Wartung u. Entsorgung', 'D14', 'J19', $ox - 2, $oy - 37) as number) +
		(g.INDEX('Wartung u. Entsorgung', 'M14', 'S19', $ox - 2, $oy - 37) as number)
	);
});
DataGrid.setCells('Ergebnisse tabellarisch', 'C44', 'H44', (s, c, g) => {
	//=INDEX($'Wartung u. Entsorgung'.$D$14:$J$19;SPALTE(C43)-2;ZEILE(C43)-36)+INDEX($'Wartung u. Entsorgung'.$M$14:$S$19;SPALTE(C43)-2;ZEILE(C43)-36)
	const $ox = g.COLUMN(c);
	const $oy = g.ROW(c) - 1;
	return (
		(g.INDEX('Wartung u. Entsorgung', 'D14', 'J19', $ox - 2, $oy - 36) as number) +
		(g.INDEX('Wartung u. Entsorgung', 'M14', 'S19', $ox - 2, $oy - 36) as number)
	);
});

// Fahrzeuggewicht
DataGrid.setCell('Ergebnisse tabellarisch', 'C45', (s, c, g) => g.g('Herstellung', 'K13'));
DataGrid.setCell('Ergebnisse tabellarisch', 'D45', (s, c, g) => g.g('Herstellung', 'K14'));
DataGrid.setCell('Ergebnisse tabellarisch', 'E45', (s, c, g) => g.g('Herstellung', 'K15'));
DataGrid.setCell('Ergebnisse tabellarisch', 'F45', (s, c, g) => g.g('Herstellung', 'K15'));
DataGrid.setCell('Ergebnisse tabellarisch', 'G45', (s, c, g) => g.g('Herstellung', 'K16'));
DataGrid.setCell('Ergebnisse tabellarisch', 'H45', (s, c, g) => g.g('Herstellung', 'K16'));

// KEA, Gesamt
DataGrid.setCells('Ergebnisse tabellarisch', 'C48', 'H49', (s, c, g) => {
	const $ox = g.COLUMN(c);
	const $oy = g.ROW(c);

	return g.SUM(s, [g.index2cell($ox, $oy - 29), g.index2cell($ox, $oy - 21), g.index2cell($ox, $oy - 13), g.index2cell($ox, $oy - 5)]);
});


/// ### Herstellung

DataGrid.setCell('Herstellung', 'C2', (s, c, g) => g.g('Berechnung_Eingabeparameter', 'C6'));
DataGrid.setCell('Herstellung', 'C3', (s, c, g) => g.g('Berechnung_Eingabeparameter', 'H5'));
DataGrid.setCell('Herstellung', 'C4', (s, c, g) => g.g('Berechnung_Eingabeparameter', 'C5'));
DataGrid.setCell('Herstellung', 'C5', (s, c, g) => g.g('Berechnung_Eingabeparameter', 'C13'));
DataGrid.setCell('Herstellung', 'C6', (s, c, g) => g.g('Berechnung_Eingabeparameter', 'H9'));

DataGrid.setCell('Herstellung', 'K2', (s, c, g) => g.g('Berechnung_Eingabeparameter', 'C27'));
DataGrid.setCell('Herstellung', 'K3', (s, c, g) => g.g('Berechnung_Eingabeparameter', 'C28'));
DataGrid.setCell('Herstellung', 'K4', (s, c, g) => g.g('Berechnung_Eingabeparameter', 'C29'));
DataGrid.setCell('Herstellung', 'K5', (s, c, g) => g.g('Berechnung_Eingabeparameter', 'H7'));

DataGrid.setCell('Herstellung', 'P2', (s, c, g) => g.n(s, 'C4') * g.n(s, 'K3'));
DataGrid.setCell('Herstellung', 'P3', (s, c, g) => g.n(s, 'C2') * g.n(s, 'K4'));
DataGrid.setCell('Herstellung', 'P4', (s, c, g) => Math.min(g.n(s, 'P2'), g.n(s, 'P3')));
DataGrid.setCell('Herstellung', 'P5', (s, c, g) => g.n('Berechnung_Eingabeparameter', 'H8'));

// DataGrid.setCell('Herstellung', 'N8', 'Energiedichte Basis:');
// DataGrid.setCell('Herstellung', 'P8', 83);
DataGrid.setCell('Herstellung', 'N9', 'Energiedichte aktuell:');
DataGrid.setCell('Herstellung', 'P9', (s, c, g) => g.g('Berechnung_Eingabeparameter', 'C57'));

DataGrid.setCells('Herstellung', 'C13', 'C14', (s, c, g) => g.g(s, `J${g.g(s, 'C6') == 'Otto' ? 23 : 24}`));
DataGrid.setCells('Herstellung', 'C15', 'C16', (s, c, g) => g.g(s, `J${g.ROW(c) + 10}`));
DataGrid.setCells('Herstellung', 'D13', 'D14', (s, c, g) => g.g(s, `J${g.g(s, 'C6') == 'Otto' ? 29 : 30}`));
DataGrid.setCells('Herstellung', 'D15', 'D16', (s, c, g) => g.g(s, `J${g.ROW(c) + 16}`));
DataGrid.setCells('Herstellung', 'E13', 'E14', (s, c, g) => g.g(s, `J${g.g(s, 'C6') == 'Otto' ? 35 : 36}`));
DataGrid.setCells('Herstellung', 'E15', 'E16', (s, c, g) => g.g(s, `J${g.ROW(c) + 22}`));
DataGrid.setCells('Herstellung', 'F13', 'F14', (s, c, g) => g.g(s, `J${g.g(s, 'C6') == 'Otto' ? 41 : 42}`));
DataGrid.setCells('Herstellung', 'F15', 'F16', (s, c, g) => g.g(s, `J${g.ROW(c) + 28}`));
DataGrid.setCells('Herstellung', 'G13', 'G14', (s, c, g) => g.g(s, `J${g.g(s, 'C6') == 'Otto' ? 47 : 48}`));
DataGrid.setCells('Herstellung', 'G15', 'G16', (s, c, g) => g.g(s, `J${g.ROW(c) + 34}`));
DataGrid.setCells('Herstellung', 'H13', 'H14', (s, c, g) => g.g(s, `J${g.g(s, 'C6') == 'Otto' ? 53 : 54}`));
DataGrid.setCells('Herstellung', 'H15', 'H16', (s, c, g) => g.g(s, `J${g.ROW(c) + 40}`));
DataGrid.setCells('Herstellung', 'I13', 'I14', (s, c, g) => g.g(s, `J${g.g(s, 'C6') == 'Otto' ? 59 : 60}`));
DataGrid.setCells('Herstellung', 'I15', 'I16', (s, c, g) => g.g(s, `J${g.ROW(c) + 46}`));

// Fahrzeuggewicht
DataGrid.setCell('Herstellung', 'K13', (s, c, g) => 1330 * (g.HLOOKUP('Berechnung_Eingabeparameter', g.n(s, 'C5'), 'L22', 'N25', 2, false) as number));
DataGrid.setCell('Herstellung', 'K14', (s, c, g) => 1395 * (g.HLOOKUP('Berechnung_Eingabeparameter', g.n(s, 'C5'), 'L22', 'N25', 2, false) as number));
DataGrid.setCell('Herstellung', 'K15', (s, c, g) => (1659 - 10 * 1.4 / 0.15) * (g.HLOOKUP('Berechnung_Eingabeparameter', g.n(s, 'C5'), 'L22', 'N25', 2, false) as number) + (1000 / g.n(s, 'P9')) * g.n(s, 'P5'));
DataGrid.setCell('Herstellung', 'K16', (s, c, g) => (1695 - 55 * 1.1 / 0.15) * (g.HLOOKUP('Berechnung_Eingabeparameter', g.n(s, 'C5'), 'L22', 'N25', 2, false) as number) + (1000 / g.n(s, 'P9')) * g.n(s, 'K5'));


DataGrid.setCells('Herstellung', 'B20', 'D62',
	[
		["", "Fahrzeug", "Batterie"],

		["", "pro Einheit", "pro kWh"],

		["Versauerung", "", ""],
		["Otto", 56.4064016185627, ""],
		["Diesel", 74.7960392078309, ""],
		["PHEV", 66.2505263966928, 1.02754189218635],
		["BEV", 52.3602701378208, 1.02754189218635],
		["", "", ""],

		["Treibhauseffekt", "", ""],
		["Otto", 7000.8914289149, ""],
		["Diesel", 7879.97588571813, ""],
		["PHEV", 8429.46793250783, 91.1],
		["BEV", 7824.06295248968, 91.1],
		["", "", ""],

		["Feinstaub", "", ""],
		["Otto", 42.6322904769248, ""],
		["Diesel", 55.2128149005373, ""],
		["PHEV", 50.5379046149122, 0.71623956224969],
		["BEV", 41.5477477273176, 0.71623956224969],
		["", "", ""],

		["Eutrophierung (terr.)", "", ""],
		["Otto", 2.68540487513538, ""],
		["Diesel", 3.69573037703293, ""],
		["PHEV", 3.22671325690614, 0.0400949141326426],
		["BEV", 2.73679595990873, 0.0400949141326426],
		["", "", ""],

		["Sommersmog", "", ""],
		["Otto", 3.74823223352759, ""],
		["Diesel", 4.2643585828157, ""],
		["PHEV", 4.33747214355328, 0.0304694025101045],
		["BEV", 3.74669633161622, 0.0304694025101045],
		["", "", ""],

		["KEA, nicht erneuerbar", "", ""],
		["Otto", 103483.292764565, ""],
		["Diesel", 116979.791398964, ""],
		["PHEV", 122222.576506628, 1229.07399100564],
		["BEV", 112872.127466502, 1229.07399100564],
		["", "", ""],

		["KEA, erneuerbar", "", ""],
		["Otto", 10991.1539486999, ""],
		["Diesel", 12155.790844714, ""],
		["PHEV", 13190.6880509342, 290.292845723028],
		["BEV", 12671.5792798713, 290.292845723028]
	]);


DataGrid.setCell('Herstellung', 'K31', 0.0948157450422845);
DataGrid.setCell('Herstellung', 'K32', 0.0579266741036046);

// Entsorgung / km
// DataGrid.setCell('Herstellung', 'L41', 0.000000265691485886413);
// DataGrid.setCell('Herstellung', 'L53', 0.013601909828857);
// DataGrid.setCell('Herstellung', 'L59', 0.000194019256241);

//C23*HLOOKUP($C$5;Eingabeparameter!$L$22:$N$25;2;FALSE)/$C$3
DataGrid.setCells('Herstellung', 'H23', 'H50', (s, c, g) => {
	let r = 2;
	if (['H25', 'H31', 'H37', 'H43', 'H49'].includes(c))
		r = 3;
	if (['H26', 'H32', 'H38', 'H44', 'H50'].includes(c))
		r = 4;
	return (g.n(s, `C${g.ROW(c)}`) *
		(g.HLOOKUP('Berechnung_Eingabeparameter', g.g(s, 'C5'), 'L22', 'N25', r, false) as number) /
		g.n(s, 'C3'));
});

//D30*($K$5/$C$3)*($C$3/$P$4)
DataGrid.setCells('Herstellung', 'I23', 'I62', (s, c, g) => {
	let r = 'K';
	if (['I25', 'I31', 'I37', 'I43', 'I49', 'I55', 'I61'].includes(c))
		r = 'P';
	return (
		g.n(s, `D${g.ROW(c)}`) *
		(g.n(s, `${r}5`) / g.n(s, 'C3')) *
		(g.n(s, 'C3') / g.n(s, 'P4'))
	);
});
DataGrid.setCells('Herstellung', 'J23', 'J62', (s, c, g) => g.n(s, `H${g.ROW(c)}`) + g.n(s, `I${g.ROW(c)}`));


/// ### Energiebereitstellung

DataGrid.setCell('Energiebereitstellung', 'C2', (s, c, g) => g.g('Berechnung_Eingabeparameter', 'C14'));

DataGrid.setCell('Energiebereitstellung', 'C14', (s, c, g) => g.g(s, 'C2'));
DataGrid.setCell('Energiebereitstellung', 'C15', (s, c, g) => g.g(s, 'C2'));
DataGrid.setCell('Energiebereitstellung', 'C16', (s, c, g) => g.g(s, 'C2'));
DataGrid.setCell('Energiebereitstellung', 'C17', (s, c, g) => g.g(s, 'C2'));


DataGrid.setCell('Energiebereitstellung', 'D14', (s, c, g) => g.g('Berechnung_Eingabeparameter', 'C18'));
DataGrid.setCell('Energiebereitstellung', 'D15', (s, c, g) => g.g('Nutzung', 'I14'));
DataGrid.setCell('Energiebereitstellung', 'D16', (s, c, g) => g.g('Nutzung', 'I15'));
DataGrid.setCell('Energiebereitstellung', 'D17', (s, c, g) => g.g('Nutzung', 'I15'));
DataGrid.setCell('Energiebereitstellung', 'D18', (s, c, g) => g.g('Nutzung', 'I16'));
DataGrid.setCell('Energiebereitstellung', 'D19', (s, c, g) => g.g('Nutzung', 'I16'));

//=$D14*VLOOKUP($C14;'DB Energiebereitstellung'!$B$10:$H$16;COLUMN('DB Energiebereitstellung'!C$10)-1;FALSE)/100
DataGrid.setCells('Energiebereitstellung', 'E14', 'K19', (s, c, g) => {
	const $col = g.COLUMN(c) - 2;
	return (
		g.n(s, `D${g.ROW(c)}`) *
		(g.VLOOKUP('DB Energiebereitstellung', g.g(s, `C${g.ROW(c)}`), 'B10', 'H16', $col - 1, false) as number) /
		100
	);
});


DataGrid.setCell('Energiebereitstellung', 'L14', 'n.a.');
DataGrid.setCell('Energiebereitstellung', 'L15', 'n.a.');
DataGrid.setCell('Energiebereitstellung', 'L16', 'Graustrom');
DataGrid.setCell('Energiebereitstellung', 'L17', 'Ökostrom');
DataGrid.setCell('Energiebereitstellung', 'L18', 'Graustrom');
DataGrid.setCell('Energiebereitstellung', 'L19', 'Ökostrom');

DataGrid.setCell('Energiebereitstellung', 'M14', (s, c, g) => g.g('Nutzung', 'M13'));
DataGrid.setCell('Energiebereitstellung', 'M15', (s, c, g) => g.g('Nutzung', 'M14'));
DataGrid.setCell('Energiebereitstellung', 'M16', (s, c, g) => g.g('Nutzung', 'M15'));
DataGrid.setCell('Energiebereitstellung', 'M17', (s, c, g) => g.g('Nutzung', 'M15'));
DataGrid.setCell('Energiebereitstellung', 'M18', (s, c, g) => g.g('Nutzung', 'M16'));
DataGrid.setCell('Energiebereitstellung', 'M19', (s, c, g) => g.g('Nutzung', 'M16'));

//=$N14*SVERWEIS($M14;$'DB Energiebereitstellung'.$K$10:$Q$16;SPALTE($'DB Energiebereitstellung'.C$10)-1;FALSCH())/100
DataGrid.setCells('Energiebereitstellung', 'N14', 'S19', (s, c, g) => {
	const $col = g.COLUMN(c) - 11;
	//g.log('vlvalue ' +c +': ' + g.n(s,'L'+(g.ROW(c))))
	//g.log(g.VLOOKUP('DB Energiebereitstellung', g.g(s,'L'+(g.ROW(c))), 'J10','P16', $col-1, false))
	return (
		g.n(s, `M${g.ROW(c)}`) *
		(g.VLOOKUP('DB Energiebereitstellung', g.g(s, `L${g.ROW(c)}`), 'K10', 'Q16', $col - 1, false) as number) /
		100
	);
});
DataGrid.setCells('Energiebereitstellung', 'T14', 'T19', (s, c, g) => {
	const $col = g.COLUMN(c) - 11;
	//g.log('vlvalue ' +c +': ' + g.n(s,'L'+(g.ROW(c))))
	//g.log(g.VLOOKUP('DB Energiebereitstellung', g.g(s,'L'+(g.ROW(c))), 'J10','Q16', $col-1, false))
	return (
		g.n(s, `M${g.ROW(c)}`) *
		(g.VLOOKUP('DB Energiebereitstellung', g.g(s, `L${g.ROW(c)}`), 'K10', 'R16', $col - 1, false) as number) /
		100
	);
});


/// ### Nutzung

DataGrid.setCell('Nutzung', 'C2', (s, c, g) => g.g('Berechnung_Eingabeparameter', 'H5'));
DataGrid.setCell('Nutzung', 'C3', (s, c, g) => g.g('Berechnung_Eingabeparameter', 'C13'));
DataGrid.setCell('Nutzung', 'C4', (s, c, g) => g.g('Berechnung_Eingabeparameter', 'C14'));
DataGrid.setCell('Nutzung', 'C5', (s, c, g) => {
	const $C4 = g.g(s, 'C4');

	return (($C4 == g.g('Dropdownlisten', 'A1')) || ($C4 == g.g('Dropdownlisten', 'A3'))) ? "Otto" : "Diesel";
});

DataGrid.setCell('Nutzung', 'C6', (s, c, g) => g.g('Berechnung_Eingabeparameter', 'C15'));
DataGrid.setCell('Nutzung', 'G3', (s, c, g) => g.g('Berechnung_Eingabeparameter', 'C9'));
DataGrid.setCell('Nutzung', 'G4', (s, c, g) => g.g('Berechnung_Eingabeparameter', 'C10'));
DataGrid.setCell('Nutzung', 'G5', (s, c, g) => g.g('Berechnung_Eingabeparameter', 'C11'));
DataGrid.setCell('Nutzung', 'K3', (s, c, g) => g.g('Berechnung_Eingabeparameter', 'C33'));
DataGrid.setCell('Nutzung', 'K4', (s, c, g) => g.g('Berechnung_Eingabeparameter', 'C34'));
DataGrid.setCell('Nutzung', 'K5', (s, c, g) => g.g('Berechnung_Eingabeparameter', 'C35'));
DataGrid.setCell('Nutzung', 'O2', (s, c, g) => g.g('Berechnung_Eingabeparameter', 'H7'));
DataGrid.setCell('Nutzung', 'O3', (s, c, g) => g.g('Berechnung_Eingabeparameter', 'H8'));

//=($G$3*'DB Schadstoffe und KV'!U2+$G$4*'DB Schadstoffe und KV'!U4+$G$5*'DB Schadstoffe und KV'!U6)/1000+$K13
DataGrid.setCell('Nutzung', 'C13', (s, c, g) => (
	g.n(s, 'G3') * g.n('DB Schadstoffe und KV', 'U2') +
	g.n(s, 'G4') * g.n('DB Schadstoffe und KV', 'U4') +
	g.n(s, 'G5') * g.n('DB Schadstoffe und KV', 'U6')
) / 1000 + g.n(s, 'K13'));
DataGrid.setCell('Nutzung', 'C14', (s, c, g) => (
	g.n(s, 'G3') * g.n('DB Schadstoffe und KV', 'U10') +
	g.n(s, 'G4') * g.n('DB Schadstoffe und KV', 'U12') +
	g.n(s, 'G5') * g.n('DB Schadstoffe und KV', 'U14')
) / 1000 + g.n(s, 'K14'));
DataGrid.setCell('Nutzung', 'D13', (s, c, g) => (
	g.n(s, 'G3') * g.n('DB Schadstoffe und KV', 'V2') +
	g.n(s, 'G4') * g.n('DB Schadstoffe und KV', 'V4') +
	g.n(s, 'G5') * g.n('DB Schadstoffe und KV', 'V6')
) / 1000 + g.n(s, 'J13'));
DataGrid.setCell('Nutzung', 'D14', (s, c, g) => (
	g.n(s, 'G3') * g.n('DB Schadstoffe und KV', 'V10') +
	g.n(s, 'G4') * g.n('DB Schadstoffe und KV', 'V12') +
	g.n(s, 'G5') * g.n('DB Schadstoffe und KV', 'V14')
) / 1000 + g.n(s, 'J14'));
// c15,d15
DataGrid.setCell('Nutzung', 'C15', (s, c, g) => (
	g.n(s, 'G3') * g.n(s, 'K3') * g.n('DB Schadstoffe und KV', 'U10') +
	g.n(s, 'G4') * g.n(s, 'K4') * g.n('DB Schadstoffe und KV', 'U12') +
	g.n(s, 'G5') * g.n(s, 'K5') * g.n('DB Schadstoffe und KV', 'U14')
) / 1000 + g.n(s, 'K15'));
DataGrid.setCell('Nutzung', 'D15', (s, c, g) => (
	g.n(s, 'G3') * g.n(s, 'K3') * g.n('DB Schadstoffe und KV', 'V10') +
	g.n(s, 'G4') * g.n(s, 'K4') * g.n('DB Schadstoffe und KV', 'V12') +
	g.n(s, 'G5') * g.n(s, 'K5') * g.n('DB Schadstoffe und KV', 'V14')
) / 1000 + g.n(s, 'J15'));

DataGrid.setCell('Nutzung', 'E13', (s, c, g) => (
	g.n(s, 'G3') * g.n('DB Schadstoffe und KV', 'W2') +
	g.n(s, 'G4') * g.n('DB Schadstoffe und KV', 'W4') +
	g.n(s, 'G5') * g.n('DB Schadstoffe und KV', 'W6')
) / 1000);
DataGrid.setCell('Nutzung', 'E14', (s, c, g) => (
	g.n(s, 'G3') * g.n('DB Schadstoffe und KV', 'W10') +
	g.n(s, 'G4') * g.n('DB Schadstoffe und KV', 'W12') +
	g.n(s, 'G5') * g.n('DB Schadstoffe und KV', 'W14')
) / 1000);
DataGrid.setCell('Nutzung', 'E15', (s, c, g) => (
	g.n(s, 'G3') * g.n(s, 'K3') * g.n('DB Schadstoffe und KV', 'W10') +
	g.n(s, 'G4') * g.n(s, 'K4') * g.n('DB Schadstoffe und KV', 'W12') +
	g.n(s, 'G5') * g.n(s, 'K5') * g.n('DB Schadstoffe und KV', 'W14')
) / 1000);

DataGrid.setCell('Nutzung', 'F13', (s, c, g) => (
	g.n(s, 'G3') * g.n('DB Schadstoffe und KV', 'X2') +
	g.n(s, 'G4') * g.n('DB Schadstoffe und KV', 'X4') +
	g.n(s, 'G5') * g.n('DB Schadstoffe und KV', 'X6')
) / 1000);
DataGrid.setCell('Nutzung', 'F14', (s, c, g) => (
	g.n(s, 'G3') * g.n('DB Schadstoffe und KV', 'X10') +
	g.n(s, 'G4') * g.n('DB Schadstoffe und KV', 'X12') +
	g.n(s, 'G5') * g.n('DB Schadstoffe und KV', 'X14')
) / 1000);
DataGrid.setCell('Nutzung', 'F15', (s, c, g) => (
	g.n(s, 'G3') * g.n(s, 'K3') * g.n('DB Schadstoffe und KV', 'X10') +
	g.n(s, 'G4') * g.n(s, 'K4') * g.n('DB Schadstoffe und KV', 'X12') +
	g.n(s, 'G5') * g.n(s, 'K5') * g.n('DB Schadstoffe und KV', 'X14')
) / 1000);

DataGrid.setCell('Nutzung', 'G13', (s, c, g) => (
	g.n(s, 'G3') * g.n('DB Schadstoffe und KV', 'Y2') +
	g.n(s, 'G4') * g.n('DB Schadstoffe und KV', 'Y4') +
	g.n(s, 'G5') * g.n('DB Schadstoffe und KV', 'Y6')
) / 1000);
DataGrid.setCell('Nutzung', 'G14', (s, c, g) => (
	g.n(s, 'G3') * g.n('DB Schadstoffe und KV', 'Y10') +
	g.n(s, 'G4') * g.n('DB Schadstoffe und KV', 'Y12') +
	g.n(s, 'G5') * g.n('DB Schadstoffe und KV', 'Y14')
) / 1000);
DataGrid.setCell('Nutzung', 'G15', (s, c, g) => (
	g.n(s, 'G3') * g.n(s, 'K3') * g.n('DB Schadstoffe und KV', 'Y10') +
	g.n(s, 'G4') * g.n(s, 'K4') * g.n('DB Schadstoffe und KV', 'Y12') +
	g.n(s, 'G5') * g.n(s, 'K5') * g.n('DB Schadstoffe und KV', 'Y14')
) / 1000);

// $G$3*'DB Schadstoffe und KV'!F2+$G$4*'DB Schadstoffe und KV'!F4+$G$5*'DB Schadstoffe und KV'!F6)*VLOOKUP($C$4;Eingabeparameter!$B$50:$C$53;2;FALSE)
DataGrid.setCell('Nutzung', 'I13', (s, c, g) => (
	g.n(s, 'G3') * g.n('DB Schadstoffe und KV', 'F2') +
	g.n(s, 'G4') * g.n('DB Schadstoffe und KV', 'F4') +
	g.n(s, 'G5') * g.n('DB Schadstoffe und KV', 'F6')
) * (g.VLOOKUP('Berechnung_Eingabeparameter', g.n(s, 'C4'), 'B50', 'C53', 2, false) as number));
DataGrid.setCell('Nutzung', 'I14', (s, c, g) => (
	g.n(s, 'G3') * g.n('DB Schadstoffe und KV', 'F10') +
	g.n(s, 'G4') * g.n('DB Schadstoffe und KV', 'F12') +
	g.n(s, 'G5') * g.n('DB Schadstoffe und KV', 'F14')
) * (g.VLOOKUP('Berechnung_Eingabeparameter', g.n(s, 'C4'), 'B50', 'C53', 2, false) as number));
//=($G$3*(1-$K$3)*'DB Schadstoffe und KV'!F10+$G$4*(1-$K$4)*'DB Schadstoffe und KV'!F12+$G$5*(1-$K$5)*'DB Schadstoffe und KV'!F14)*VLOOKUP($C$4;Eingabeparameter!$B$50:$C$53;2;FALSE)
DataGrid.setCell('Nutzung', 'I15', (s, c, g) => (
	g.n(s, 'G3') * (1 - g.n(s, 'K3')) * g.n('DB Schadstoffe und KV', 'F10') +
	g.n(s, 'G4') * (1 - g.n(s, 'K4')) * g.n('DB Schadstoffe und KV', 'F12') +
	g.n(s, 'G5') * (1 - g.n(s, 'K5')) * g.n('DB Schadstoffe und KV', 'F14')
) * (g.VLOOKUP('Berechnung_Eingabeparameter', g.n(s, 'C4'), 'B50', 'C53', 2, false) as number));
//=VLOOKUP($C$4;Eingabeparameter!$B$38:$C$41;2;FALSE)*Eingabeparameter!C18/100
DataGrid.setCell('Nutzung', 'J13', (s, c, g) => (g.VLOOKUP('Berechnung_Eingabeparameter', g.n(s, 'C4'), 'B38', 'C41', 2, false) as number) * g.n('Berechnung_Eingabeparameter', 'C18') / 100);
DataGrid.setCell('Nutzung', 'J14', (s, c, g) => (g.VLOOKUP('Berechnung_Eingabeparameter', g.n(s, 'C4'), 'B38', 'C41', 2, false) as number) * g.n(s, 'I14') / 100);
DataGrid.setCell('Nutzung', 'J15', (s, c, g) => (g.VLOOKUP('Berechnung_Eingabeparameter', g.n(s, 'C4'), 'B38', 'C41', 2, false) as number) * g.n(s, 'I15') / 100);

DataGrid.setCell('Nutzung', 'K13', (s, c, g) => (g.VLOOKUP('Berechnung_Eingabeparameter', g.n(s, 'C4'), 'B43', 'C46', 2, false) as number) * g.n('Berechnung_Eingabeparameter', 'C18') / 100);
DataGrid.setCell('Nutzung', 'K14', (s, c, g) => (g.VLOOKUP('Berechnung_Eingabeparameter', g.n(s, 'C4'), 'B43', 'C46', 2, false) as number) * g.n(s, 'I14') / 100);
DataGrid.setCell('Nutzung', 'K15', (s, c, g) => (g.VLOOKUP('Berechnung_Eingabeparameter', g.n(s, 'C4'), 'B43', 'C46', 2, false) as number) * g.n(s, 'I15') / 100);
//=$G$3*($K$3)*('DB Stromverbrauch'!E10+('DB Stromverbrauch'!F10*$O$3))+$G$4*($K$4)*('DB Stromverbrauch'!E12+('DB Stromverbrauch'!F12*$O$3))+$G$5*($K$5)*('DB Stromverbrauch'!E14+('DB Stromverbrauch'!F14*$O$3))
DataGrid.setCell('Nutzung', 'M15', (s, c, g) => g.n(s, 'G3') * (g.n(s, 'K3')) *
	(
		g.n('DB Stromverbrauch', 'E10') +
		(g.n('DB Stromverbrauch', 'F10') * g.n(s, 'O3'))
	) +
	g.n(s, 'G4') * (g.n(s, 'K4')) *
	(
		g.n('DB Stromverbrauch', 'E12') +
		(g.n('DB Stromverbrauch', 'F12') * g.n(s, 'O3'))
	) +
	g.n(s, 'G5') * (g.n(s, 'K5')) *
	(
		g.n('DB Stromverbrauch', 'E14') +
		(g.n('DB Stromverbrauch', 'F14') * g.n(s, 'O3'))
	));
//=$G$3*('DB Stromverbrauch'!E2+('DB Stromverbrauch'!F2*$O$2))+$G$4*('DB Stromverbrauch'!E4+('DB Stromverbrauch'!F4*$O$2))+$G$5*('DB Stromverbrauch'!E6+('DB Stromverbrauch'!F6*$O$2))
DataGrid.setCell('Nutzung', 'M16', (s, c, g) => g.n(s, 'G3') *
	(
		g.n('DB Stromverbrauch', 'E2') +
		(g.n('DB Stromverbrauch', 'F2') * g.n(s, 'O2'))
	) +
	g.n(s, 'G4') *
	(
		g.n('DB Stromverbrauch', 'E4') +
		(g.n('DB Stromverbrauch', 'F4') * g.n(s, 'O2'))
	) +
	g.n(s, 'G5') *
	(
		g.n('DB Stromverbrauch', 'E6') +
		(g.n('DB Stromverbrauch', 'F6') * g.n(s, 'O2'))
	));

DataGrid.setCell('Nutzung', 'D23', 0.0019);
DataGrid.setCell('Nutzung', 'D25', 0.218);
DataGrid.setCell('Nutzung', 'E21', 28);
DataGrid.setCell('Nutzung', 'E25', 0.006);
DataGrid.setCell('Nutzung', 'F25', 0.027);
DataGrid.setCell('Nutzung', 'H21', 265);
DataGrid.setCell('Nutzung', 'H24', 0.017);
DataGrid.setCell('Nutzung', 'H26', 0.27);
DataGrid.setCell('Nutzung', 'I20', 1.88);
DataGrid.setCell('Nutzung', 'I22', 0.64);
DataGrid.setCell('Nutzung', 'I26', 0.35);
DataGrid.setCell('Nutzung', 'J22', 0.012);
DataGrid.setCell('Nutzung', 'J25', 0.15);
DataGrid.setCell('Nutzung', 'J24', 0.0000230351964532811);
DataGrid.setCell('Nutzung', 'L20', 0.7);
DataGrid.setCell('Nutzung', 'L22', 0.88);
DataGrid.setCell('Nutzung', 'L25', -0.0948339869281046);
DataGrid.setCell('Nutzung', 'L26', 0.13);
DataGrid.setCell('Nutzung', 'M22', 1);
DataGrid.setCell('Nutzung', 'N25', 0);
DataGrid.setCell('Nutzung', 'O25', 0);
DataGrid.setCell('Nutzung', 'P21', 1);
DataGrid.setCell('Nutzung', 'Q20', 1);
DataGrid.setCell('Nutzung', 'Q22', 0.54);
DataGrid.setCell('Nutzung', 'Q25', 0.048);
DataGrid.setCells('Nutzung', 'B26', 'Q26', [["Eutrophierung (terr.)", "ET", "", "", "", "", 0.27, 0.35, "", "", 0.13]]);


/// ### Wartung u. Entsorgung
DataGrid.setCell('Wartung u. Entsorgung', 'D2', (s, c, g) => g.g('Berechnung_Eingabeparameter', 'H5'));


DataGrid.setCells('Wartung u. Entsorgung', 'B12', 'R19',
	[
		["Fahrzeug", "Energieträger", "Versauerung", "Treibhauseffekt", "Feinstaub", "Eutroph. (terr.)", "Sommersmog", "KEA, nicht erneuerbar", "KEA, erneuerbar", "", "Energieträger", "Versauerung", "Treibhauseffekt", "Feinstaub", "Eutroph. (terr.)", "Sommersmog", "KEA, nicht erneuerbar", "KEA, erneuerbar"],
		["", "", "kg SO2-äq. / km", "kg CO2-äq. / km", "kg PM10 / km", "kg PO4-äq. / km", "kg C2H4-äq. / km", "MJ / km", "MJ / km", "", "", "kg SO2-äq. / km", "kg CO2-äq. / km", "kg PM2.5 / km", "kg PO4-äq. / km", "kg C2H4-äq. / km", "MJ / km", "MJ / km"],
		["ICEV - alt", "", 0.0000268, 0.00756, 0.0000206, 0.00000153, 0.00000186, 0.132, 0.00876, "", "", 0.00000237, 0.00385, 0.0000021, 3.07e-7, -2.76e-8, 0.00524, 0.000613],
		["ICEV- aktuell", "", 0.0000268, 0.00756, 0.0000206, 0.00000153, 0.00000186, 0.132, 0.00876, "Benzin", "", 0.00000237, 0.00385, 0.0000021, 3.07e-7, -2.76e-8, 0.00524, 0.000613],
		["PHEV", "Grausstrom", 0.0000268, 0.00756, 0.0000206, 0.00000153, 0.00000186, 0.132, 0.00876, "", "Grausstrom", 0.00000264, 0.00406, 0.00000232, 3.36e-7, -2.63e-8, 0.00589, 0.000694],
		["", "Ökostrom", 0.0000268, 0.00756, 0.0000206, 0.00000153, 0.00000186, 0.132, 0.00876, "", "Ökostrom", 0.00000264, 0.00406, 0.00000232, 3.36e-7, -2.63e-8, 0.00589, 0.000694],
		["BEV", "Graustrom", 0.0000187, 0.00643, 0.0000158, 0.00000128, 9.38e-7, 0.113, 0.00822, "", "Graustrom", 0.00000235, 0.00372, 0.00000208, 3.01e-7, -2.38e-8, 0.00517, 0.000603],
		["", "Ökostrom", 0.0000187, 0.00643, 0.0000158, 0.00000128, 9.38e-7, 0.113, 0.00822, "", "Ökostrom", 0.00000235, 0.00372, 0.00000208, 3.01e-7, -2.38e-8, 0.00517, 0.000603]
	]
);


/// ### DB Schadstoffe und KV
// F2 -> Y14 will be overwritten below
DataGrid.setCells('DB Schadstoffe und KV', 'A1', 'Y146',
	[
		["Referenzfahrzeug", "Kraftstoff", "Abgasnorm", "Größenklasse", "Fahrprofil", "Kraftstoffverbrauch", "CO2", "Benzol", "CH4", "CO", "HC", "N2O", "NH3", "NMHC", "NO2", "NOx", "PM", "Toluol", "Xylol", "", "Versauerung", "Treibhauseffekt", "Feinstaub", "Eutrophierung (terr.)", "Sommersmog"],
		["", "=Otto", "=Euro 6", "=mittel", "=IO"],
		["", "Kraftstoff", "Abgasnorm", "Größenklasse", "Fahrprofil"],
		["", "=Otto", "=Euro 6", "=mittel", "=AO"],
		["", "Kraftstoff", "Abgasnorm", "Größenklasse", "Fahrprofil"],
		["", "=Otto", "=Euro 6", "=mittel", "=AB"],
		[],
		[],
		["aktuelles Fahrzeug", "Kraftstoff", "Abgasnorm", "Größenklasse", "Fahrprofil"],
		["", "=Otto", "=Euro 6", "=mittel", "=IO"],
		["", "Kraftstoff", "Abgasnorm", "Größenklasse", "Fahrprofil"],
		["", "=Otto", "=Euro 6", "=mittel", "=AO"],
		["", "Kraftstoff", "Abgasnorm", "Größenklasse", "Fahrprofil"],
		["", "=Otto", "=Euro 6", "=mittel", "=AB"],
		[],
		[],
		[],
		[],
		[],
		["", "Kraftstoff", "Abgasnorm", "Größenklasse", "Fahrprofil", "Kraftstoffverbrauch", "CO2", "Benzol", "CH4", "CO", "HC", "N2O", "NH3", "NMHC", "NO2", "NOx", "PM2.5", "Toluol", "Xylol", "", "Versauerung", "Treibhauseffekt", "Feinstaub", "Eutrophierung (terr.)", "Sommersmog"],
		["", "Otto", "Euro 0", "klein", "IO", 8.84093306528699, 166.873314257702, 0.139490611943394, 0.193537920859047, 18.7978046717093, 2.95546982297846, 0.0127312270924449, 0.0554356165230274, 2.76193168340326, 0.0703426233162933, 1.2905612926432, 0.00488300574943423, 0.296676614285358, 0.262513296944799, "", 1.00761186391353, 8.79283696355121, 1.20919891805103, 0.190612865141636, 0.800602633415351],
		["", "Otto", "Euro 0", "klein", "AO", 6.30443318088139, 115.518073390201, 0.0265351010602899, 0.018731975927949, 5.24143648147583, 0.518233872950077, 0.00763777922838926, 0.0568479001522064, 0.499501921236515, 0.0551669672131538, 1.10333931446075, 0.00659210700541735, 0.0452620796859264, 0.0367738530039787, "", 0.879211572408673, 2.54850682150573, 1.01990738288313, 0.165393076324835, 0.111922398916058],
		["", "Otto", "Euro 0", "klein", "AB", 8.12476664981488, 150.572335329567, 0.0249878678296227, 0.0176600944250822, 6.0972146987915, 0.459333937615156, 0.00651327427476645, 0.0449436157941818, 0.44167385622859, 0.0903923138976097, 1.80784630775452, 0.0173103660345078, 0.0415927805006504, 0.0338068157434463, "", 1.34998641312123, 2.22050032671541, 1.6422791172415, 0.252508869590238, 0.0595365627505952],
		["", "Otto", "Euro 0", "mittel", "IO", 10.162919231729, 216.850554810066, 0.139490611943394, 0.193537920859047, 18.7978046717093, 2.95546982297846, 0.0127312270924449, 0.0554356165230274, 2.76193168340326, 0.0703426233162933, 1.2905612926432, 0.00488300574943423, 0.296676614285358, 0.262513296944799, "", 1.00761186391353, 8.79283696355121, 1.20919891805103, 0.190612865141636, 0.800602633415351],
		["", "Otto", "Euro 0", "mittel", "AO", 7.36196773266198, 138.564497138978, 0.0265351010602899, 0.018731975927949, 5.24143648147583, 0.518233872950077, 0.00763777922838926, 0.0568479001522064, 0.499501921236515, 0.0551669672131538, 1.10333931446075, 0.00659210700541735, 0.0452620796859264, 0.0367738530039787, "", 0.879211572408673, 2.54850682150573, 1.01990738288313, 0.165393076324835, 0.111922398916058],
		["", "Otto", "Euro 0", "mittel", "AB", 9.48711328721968, 169.369647744433, 0.0249878678296227, 0.0176600944250822, 6.0972146987915, 0.459333937615156, 0.00651327427476645, 0.0449436157941818, 0.44167385622859, 0.0903923138976097, 1.80784630775452, 0.0173103660345078, 0.0415927805006504, 0.0338068157434463, "", 1.34998641312123, 2.22050032671541, 1.6422791172415, 0.252508869590238, 0.0595365627505952],
		["", "Otto", "Euro 0", "groß", "IO", 12.2525862129517, 287.069607225507, 0.139490611943394, 0.193537920859047, 18.7978046717093, 2.95546982297846, 0.0127312270924449, 0.0554356165230274, 2.76193168340326, 0.0703426233162933, 1.2905612926432, 0.00488300574943423, 0.296676614285358, 0.262513296944799, "", 1.00761186391353, 8.79283696355121, 1.20919891805103, 0.190612865141636, 0.800602633415351],
		["", "Otto", "Euro 0", "groß", "AO", 8.88958396461976, 191.504331632484, 0.0265351010602899, 0.018731975927949, 5.24143648147583, 0.518233872950077, 0.00763777922838926, 0.0568479001522064, 0.499501921236515, 0.0551669672131538, 1.10333931446075, 0.00659210700541735, 0.0452620796859264, 0.0367738530039787, "", 0.879211572408673, 2.54850682150573, 1.01990738288313, 0.165393076324835, 0.111922398916058],
		["", "Otto", "Euro 0", "groß", "AB", 11.4434234115109, 239.805453798094, 0.0249878678296227, 0.0176600944250822, 6.0972146987915, 0.459333937615156, 0.00651327427476645, 0.0449436157941818, 0.44167385622859, 0.0903923138976097, 1.80784630775452, 0.0173103660345078, 0.0415927805006504, 0.0338068157434463, "", 1.34998641312123, 2.22050032671541, 1.6422791172415, 0.252508869590238, 0.0595365627505952],
		["", "Otto", "Euro 1", "klein", "IO", 8.0948222258626, 166.873314257702, 0.126938333990001, 0.096570826976678, 8.58166329360383, 1.64772333463204, 0.016574215143919, 0.106569238007069, 1.5511524776722, 0.0743639207216073, 1.26269784500467, 0.00358290178701282, 0.168007013072535, 0.155200010094459, "", 1.08423865895656, 7.09615016848552, 1.20157514744771, 0.20592499124194, 0.345210534612675],
		["", "Otto", "Euro 1", "klein", "AO", 5.79152242000234, 115.518073390201, 0.0188320666611617, 0.0122296819463372, 2.06954312324524, 0.146477808011696, 0.0082620307803154, 0.109248571097851, 0.134248126996681, 0.0326091311872005, 0.652182638645172, 0.00502765830606222, 0.0135400062426925, 0.0112105431035161, "", 0.66191516071558, 2.53186925128102, 0.650478443340398, 0.125251491218805, 0.0142391816407887],
		["", "Otto", "Euro 1", "klein", "AB", 7.46214290324266, 150.572335329567, 0.0179075255691714, 0.0116303097456694, 3.08533716201782, 0.139100672677159, 0.00616943091154099, 0.0800109058618546, 0.127470361068845, 0.0538624040782452, 1.07724809646606, 0.0137598961591721, 0.012876414693892, 0.0106611168012023, "", 0.904494170546529, 1.96054786443711, 1.01447484513372, 0.169711815938354, 0.000334707494694005],
		["", "Otto", "Euro 1", "mittel", "IO", 9.47803709436788, 216.850554810066, 0.126938333990001, 0.096570826976678, 8.58166329360383, 1.64772333463204, 0.016574215143919, 0.106569238007069, 1.5511524776722, 0.0743639207216073, 1.26269784500467, 0.00358290178701282, 0.168007013072535, 0.155200010094459, "", 1.08423865895656, 7.09615016848552, 1.20157514744771, 0.20592499124194, 0.345210534612675],
		["", "Otto", "Euro 1", "mittel", "AO", 6.84457141824966, 138.564497138978, 0.0188320666611617, 0.0122296819463372, 2.06954312324524, 0.146477808011696, 0.0082620307803154, 0.109248571097851, 0.134248126996681, 0.0326091311872005, 0.652182638645172, 0.00502765830606222, 0.0135400062426925, 0.0112105431035161, "", 0.66191516071558, 2.53186925128102, 0.650478443340398, 0.125251491218805, 0.0142391816407887],
		["", "Otto", "Euro 1", "mittel", "AB", 8.81626182849557, 169.369647744433, 0.0179075255691714, 0.0116303097456694, 3.08533716201782, 0.139100672677159, 0.00616943091154099, 0.0800109058618546, 0.127470361068845, 0.0538624040782452, 1.07724809646606, 0.0137598961591721, 0.012876414693892, 0.0106611168012023, "", 0.904494170546529, 1.96054786443711, 1.01447484513372, 0.169711815938354, 0.000334707494694005],
		["", "Otto", "Euro 1", "groß", "IO", 12.1186794396625, 287.069607225507, 0.126938333990001, 0.096570826976678, 8.58166329360383, 1.64772333463204, 0.016574215143919, 0.106569238007069, 1.5511524776722, 0.0743639207216073, 1.26269784500467, 0.00358290178701282, 0.168007013072535, 0.155200010094459, "", 1.08423865895656, 7.09615016848552, 1.20157514744771, 0.20592499124194, 0.345210534612675],
		["", "Otto", "Euro 1", "groß", "AO", 8.42676178191057, 191.504331632484, 0.0188320666611617, 0.0122296819463372, 2.06954312324524, 0.146477808011696, 0.0082620307803154, 0.109248571097851, 0.134248126996681, 0.0326091311872005, 0.652182638645172, 0.00502765830606222, 0.0135400062426925, 0.0112105431035161, "", 0.66191516071558, 2.53186925128102, 0.650478443340398, 0.125251491218805, 0.0142391816407887],
		["", "Otto", "Euro 1", "groß", "AB", 10.8039985396375, 239.805453798094, 0.0179075255691714, 0.0116303097456694, 3.08533716201782, 0.139100672677159, 0.00616943091154099, 0.0800109058618546, 0.127470361068845, 0.0538624040782452, 1.07724809646606, 0.0137598961591721, 0.012876414693892, 0.0106611168012023, "", 0.904494170546529, 1.96054786443711, 1.01447484513372, 0.169711815938354, 0.000334707494694005],
		["", "Otto", "Euro 2", "klein", "IO", 7.94345383546098, 166.873314257702, 0.0867614058692531, 0.0679130424598797, 6.15551286811712, 1.21778255298339, 0.00796250998973846, 0.159712985157967, 1.14986949129577, 0.0438070800309406, 0.749204692042789, 0.00490374723449349, 0.125259401418451, 0.116883941686852, "", 0.82470369652693, 4.01163033615732, 0.780218620628796, 0.15544603246808, 0.268036681416626],
		["", "Otto", "Euro 2", "klein", "AO", 5.61581879702586, 115.518073390201, 0.00684678238485503, 0.00444341916590929, 1.46927189826965, 0.0537841885816306, 0.00392991909757257, 0.123445019125938, 0.0493407703470439, 0.0125440191477537, 0.250880390405655, 0.00748045742511749, 0.00491950009018183, 0.00407313415780664, "", 0.407692909240722, 1.16584429750219, 0.307852102466859, 0.0768812856031582, 0.0233061296560849],
		["", "Otto", "Euro 2", "klein", "AB", 7.23080763214124, 150.572335329567, 0.00646104586212459, 0.00419408082962036, 4.14924669265747, 0.0505741481320001, 0.00308558018878102, 0.0924339070916176, 0.0463800673023798, 0.0240833163261414, 0.481666296720505, 0.021728340536356, 0.00464344723150134, 0.00384457455947995, "", 0.510942153036595, 0.93511301325634, 0.505308942996664, 0.0958015927067027, 0.0733334999951855],
		["", "Otto", "Euro 2", "mittel", "IO", 9.46257634164391, 216.850554810066, 0.0867614058692531, 0.0679130424598797, 6.15551286811712, 1.21778255298339, 0.00796250998973846, 0.159712985157967, 1.14986949129577, 0.0438070800309406, 0.749204692042789, 0.00490374723449349, 0.125259401418451, 0.116883941686852, "", 0.82470369652693, 4.01163033615732, 0.780218620628796, 0.15544603246808, 0.268036681416626],
		["", "Otto", "Euro 2", "mittel", "AO", 6.65309557381512, 138.564497138978, 0.00684678238485503, 0.00444341916590929, 1.46927189826965, 0.0537841885816306, 0.00392991909757257, 0.123445019125938, 0.0493407703470439, 0.0125440191477537, 0.250880390405655, 0.00748045742511749, 0.00491950009018183, 0.00407313415780664, "", 0.407692909240722, 1.16584429750219, 0.307852102466859, 0.0768812856031582, 0.0233061296560849],
		["", "Otto", "Euro 2", "mittel", "AB", 8.55176427606316, 169.369647744433, 0.00646104586212459, 0.00419408082962036, 4.14924669265747, 0.0505741481320001, 0.00308558018878102, 0.0924339070916176, 0.0463800673023798, 0.0240833163261414, 0.481666296720505, 0.021728340536356, 0.00464344723150134, 0.00384457455947995, "", 0.510942153036595, 0.93511301325634, 0.505308942996664, 0.0958015927067027, 0.0733334999951855],
		["", "Otto", "Euro 2", "groß", "IO", 11.7914447345195, 287.069607225507, 0.0867614058692531, 0.0679130424598797, 6.15551286811712, 1.21778255298339, 0.00796250998973846, 0.159712985157967, 1.14986949129577, 0.0438070800309406, 0.749204692042789, 0.00490374723449349, 0.125259401418451, 0.116883941686852, "", 0.82470369652693, 4.01163033615732, 0.780218620628796, 0.15544603246808, 0.268036681416626],
		["", "Otto", "Euro 2", "groß", "AO", 8.16801136935001, 191.504331632484, 0.00684678238485503, 0.00444341916590929, 1.46927189826965, 0.0537841885816306, 0.00392991909757257, 0.123445019125938, 0.0493407703470439, 0.0125440191477537, 0.250880390405655, 0.00748045742511749, 0.00491950009018183, 0.00407313415780664, "", 0.407692909240722, 1.16584429750219, 0.307852102466859, 0.0768812856031582, 0.0233061296560849],
		["", "Otto", "Euro 2", "groß", "AB", 10.4677686624442, 239.805453798094, 0.00646104586212459, 0.00419408082962036, 4.14924669265747, 0.0505741481320001, 0.00308558018878102, 0.0924339070916176, 0.0463800673023798, 0.0240833163261414, 0.481666296720505, 0.021728340536356, 0.00464344723150134, 0.00384457455947995, "", 0.510942153036595, 0.93511301325634, 0.505308942996664, 0.0958015927067027, 0.0733334999951855],
		["", "Otto", "Euro 3", "klein", "IO", 7.87137213605414, 166.873314257702, 0.0485900723243317, 0.0385523334217874, 4.59296518278898, 0.712524051365178, 0.000780841859523207, 0.0181509833782911, 0.673971711611753, 0.00958499759910811, 0.161184375281721, 0.00205019162967801, 0.0732082656535968, 0.0685514790932009, "", 0.146952911448392, 1.2863884285837, 0.16359673177904, 0.0275176402710969, 0.210051373739115],
		["", "Otto", "Euro 3", "klein", "AO", 5.48512845634974, 115.518073390201, 0.00228471295577037, 0.00147966190706939, 0.846629500389099, 0.0185013624723069, 0.000283399625914171, 0.0436839126050472, 0.0170217013801448, 0.00208518886938691, 0.0417037792503834, 0.00276624131947756, 0.00163819710724056, 0.00135635666083545, "", 0.111318401172757, 0.116531434265198, 0.0676275315436069, 0.0207873786133132, 0.0214661940326864],
		["", "Otto", "Euro 3", "klein", "AB", 7.05221457039844, 150.572335329567, 0.00370921815556358, 0.00240635080263019, 2.3975977897644, 0.029291644692421, 0.000261622859397903, 0.0604840368032455, 0.0268852934241295, 0.0040267095901072, 0.0805341973900795, 0.00766027066856623, 0.00266417418606579, 0.00220582168549299, "", 0.170083927363157, 0.13670788021409, 0.117562771447003, 0.0317094967138837, 0.0611449934195178],
		["", "Otto", "Euro 3", "mittel", "IO", 9.4214587062612, 216.850554810066, 0.0485900723243317, 0.0385523334217874, 4.59296518278898, 0.712524051365178, 0.000780841859523207, 0.0181509833782911, 0.673971711611753, 0.00958499759910811, 0.161184375281721, 0.00205019162967801, 0.0732082656535968, 0.0685514790932009, "", 0.146952911448392, 1.2863884285837, 0.16359673177904, 0.0275176402710969, 0.210051373739115],
		["", "Otto", "Euro 3", "mittel", "AO", 6.50427211659756, 138.564497138978, 0.00228471295577037, 0.00147966190706939, 0.846629500389099, 0.0185013624723069, 0.000283399625914171, 0.0436839126050472, 0.0170217013801448, 0.00208518886938691, 0.0417037792503834, 0.00276624131947756, 0.00163819710724056, 0.00135635666083545, "", 0.111318401172757, 0.116531434265198, 0.0676275315436069, 0.0207873786133132, 0.0214661940326864],
		["", "Otto", "Euro 3", "mittel", "AB", 8.34674592803841, 169.369647744433, 0.00370921815556358, 0.00240635080263019, 2.3975977897644, 0.029291644692421, 0.000261622859397903, 0.0604840368032455, 0.0268852934241295, 0.0040267095901072, 0.0805341973900795, 0.00766027066856623, 0.00266417418606579, 0.00220582168549299, "", 0.170083927363157, 0.13670788021409, 0.117562771447003, 0.0317094967138837, 0.0611449934195178],
		["", "Otto", "Euro 3", "groß", "IO", 11.3592777498268, 287.069607225507, 0.0485900723243317, 0.0385523334217874, 4.59296518278898, 0.712524051365178, 0.000780841859523207, 0.0181509833782911, 0.673971711611753, 0.00958499759910811, 0.161184375281721, 0.00205019162967801, 0.0732082656535968, 0.0685514790932009, "", 0.146952911448392, 1.2863884285837, 0.16359673177904, 0.0275176402710969, 0.210051373739115],
		["", "Otto", "Euro 3", "groß", "AO", 7.86433052007686, 191.504331632484, 0.00228471295577037, 0.00147966190706939, 0.846629500389099, 0.0185013624723069, 0.000283399625914171, 0.0436839126050472, 0.0170217013801448, 0.00208518886938691, 0.0417037792503834, 0.00276624131947756, 0.00163819710724056, 0.00135635666083545, "", 0.111318401172757, 0.116531434265198, 0.0676275315436069, 0.0207873786133132, 0.0214661940326864],
		["", "Otto", "Euro 3", "groß", "AB", 10.0771666096518, 239.805453798094, 0.00370921815556358, 0.00240635080263019, 2.3975977897644, 0.029291644692421, 0.000261622859397903, 0.0604840368032455, 0.0268852934241295, 0.0040267095901072, 0.0805341973900795, 0.00766027066856623, 0.00266417418606579, 0.00220582168549299, "", 0.170083927363157, 0.13670788021409, 0.117562771447003, 0.0317094967138837, 0.0611449934195178],
		["", "Otto", "Euro 4", "klein", "IO", 7.77019548241508, 166.873314257702, 0.0432207982183059, 0.0345035569975187, 2.97581070451786, 0.643010918445541, 0.000938495970331132, 0.0180141981691122, 0.608507326355582, 0.00704636320185719, 0.190795013403468, 0.00091954757226631, 0.066227502905876, 0.0621447408761185, "", 0.167423201940358, 1.21480102806827, 0.187650334111817, 0.0313617150136296, 0.153736157510253],
		["", "Otto", "Euro 4", "klein", "AO", 5.17771020307637, 115.518073390201, 0.000725235947811598, 0.000466544472146779, 0.400676935911179, 0.00644044094951823, 0.000248612195719033, 0.0435121022164822, 0.00597389676840976, 0.00234470306895673, 0.0468940585851669, 0.00146863970439881, 0.000516531406901777, 0.000427665770985186, "", 0.114628593176603, 0.0789454770856536, 0.0706548434391152, 0.0213925886846846, 0.00727001051282469],
		["", "Otto", "Euro 4", "klein", "AB", 6.63616785338873, 150.572335329567, 0.00219280707551661, 0.00142121128737926, 1.85034108161926, 0.0175637938664295, 0.000248196418397129, 0.0602424405515194, 0.0161425825790502, 0.00350558618083596, 0.0701117217540741, 0.00401416840031743, 0.00157348404172808, 0.00130277709104121, "", 0.162333993464708, 0.105565966921858, 0.104461356487824, 0.0302663910540286, 0.0457401497539691],
		["", "Otto", "Euro 4", "mittel", "IO", 9.29899646598354, 216.850554810066, 0.0432207982183059, 0.0345035569975187, 2.97581070451786, 0.643010918445541, 0.000938495970331132, 0.0180141981691122, 0.608507326355582, 0.00704636320185719, 0.190795013403468, 0.00091954757226631, 0.066227502905876, 0.0621447408761185, "", 0.167423201940358, 1.21480102806827, 0.187650334111817, 0.0313617150136296, 0.153736157510253],
		["", "Otto", "Euro 4", "mittel", "AO", 6.20869574963032, 138.564497138978, 0.000725235947811598, 0.000466544472146779, 0.400676935911179, 0.00644044094951823, 0.000248612195719033, 0.0435121022164822, 0.00597389676840976, 0.00234470306895673, 0.0468940585851669, 0.00146863970439881, 0.000516531406901777, 0.000427665770985186, "", 0.114628593176603, 0.0789454770856536, 0.0706548434391152, 0.0213925886846846, 0.00727001051282469],
		["", "Otto", "Euro 4", "mittel", "AB", 7.94723722086852, 169.369647744433, 0.00219280707551661, 0.00142121128737926, 1.85034108161926, 0.0175637938664295, 0.000248196418397129, 0.0602424405515194, 0.0161425825790502, 0.00350558618083596, 0.0701117217540741, 0.00401416840031743, 0.00157348404172808, 0.00130277709104121, "", 0.162333993464708, 0.105565966921858, 0.104461356487824, 0.0302663910540286, 0.0457401497539691],
		["", "Otto", "Euro 4", "groß", "IO", 11.1338752335405, 287.069607225507, 0.0432207982183059, 0.0345035569975187, 2.97581070451786, 0.643010918445541, 0.000938495970331132, 0.0180141981691122, 0.608507326355582, 0.00704636320185719, 0.190795013403468, 0.00091954757226631, 0.066227502905876, 0.0621447408761185, "", 0.167423201940358, 1.21480102806827, 0.187650334111817, 0.0313617150136296, 0.153736157510253],
		["", "Otto", "Euro 4", "groß", "AO", 7.56473505080567, 191.504331632484, 0.000725235947811598, 0.000466544472146779, 0.400676935911179, 0.00644044094951823, 0.000248612195719033, 0.0435121022164822, 0.00597389676840976, 0.00234470306895673, 0.0468940585851669, 0.00146863970439881, 0.000516531406901777, 0.000427665770985186, "", 0.114628593176603, 0.0789454770856536, 0.0706548434391152, 0.0213925886846846, 0.00727001051282469],
		["", "Otto", "Euro 4", "groß", "AB", 9.68860268375899, 239.805453798094, 0.00219280707551661, 0.00142121128737926, 1.85034108161926, 0.0175637938664295, 0.000248196418397129, 0.0602424405515194, 0.0161425825790502, 0.00350558618083596, 0.0701117217540741, 0.00401416840031743, 0.00157348404172808, 0.00130277709104121, "", 0.162333993464708, 0.105565966921858, 0.104461356487824, 0.0302663910540286, 0.0457401497539691],
		["", "Otto", "Euro 5", "klein", "IO", 7.15981566739363, 166.873314257702, 0.0389352045517112, 0.0310738511141244, 2.93303087143948, 0.579544045880198, 0.000878663850016892, 0.0178888645023108, 0.548470173530165, 0.00678583129954396, 0.173479961383891, 0.000781540526077151, 0.0596381750359238, 0.0559527086906943, "", 0.155067038233068, 1.10291375144996, 0.171474421907742, 0.0290507367952191, 0.145197006274907],
		["", "Otto", "Euro 5", "klein", "AO", 4.75955110173849, 115.518073390201, 0.000666186545458913, 0.000428182887844741, 0.338989287614822, 0.00598375615663826, 0.000206145065021701, 0.0433546714484692, 0.0055555731523782, 0.00220427312888205, 0.0440854616463184, 0.00126892712432891, 0.000474059663247317, 0.000392501009628177, "", 0.112366605475545, 0.0666175630904035, 0.0678777899779379, 0.0209609041885415, 0.00580781574229758],
		["", "Otto", "Euro 5", "klein", "AB", 6.12474258377366, 150.572335329567, 0.00164292908175412, 0.00106398190837353, 1.21155786514282, 0.013311062939465, 0.000202913506655022, 0.0599681437015533, 0.0122470809146762, 0.00307052489370108, 0.0614104978740215, 0.00378736294806004, 0.00117797998245806, 0.00097531673964113, "", 0.155727458670735, 0.0835635726980397, 0.0963551780171692, 0.0290270016659633, 0.0287317060348745],
		["", "Otto", "Euro 5", "mittel", "IO", 8.41114030449571, 216.850554810066, 0.0389352045517112, 0.0310738511141244, 2.93303087143948, 0.579544045880198, 0.000878663850016892, 0.0178888645023108, 0.548470173530165, 0.00678583129954396, 0.173479961383891, 0.000781540526077151, 0.0596381750359238, 0.0559527086906943, "", 0.155067038233068, 1.10291375144996, 0.171474421907742, 0.0290507367952191, 0.145197006274907],
		["", "Otto", "Euro 5", "mittel", "AO", 5.63477357926115, 138.564497138978, 0.000666186545458913, 0.000428182887844741, 0.338989287614822, 0.00598375615663826, 0.000206145065021701, 0.0433546714484692, 0.0055555731523782, 0.00220427312888205, 0.0440854616463184, 0.00126892712432891, 0.000474059663247317, 0.000392501009628177, "", 0.112366605475545, 0.0666175630904035, 0.0678777899779379, 0.0209609041885415, 0.00580781574229758],
		["", "Otto", "Euro 5", "mittel", "AB", 7.23613294855453, 169.369647744433, 0.00164292908175412, 0.00106398190837353, 1.21155786514282, 0.013311062939465, 0.000202913506655022, 0.0599681437015533, 0.0122470809146762, 0.00307052489370108, 0.0614104978740215, 0.00378736294806004, 0.00117797998245806, 0.00097531673964113, "", 0.155727458670735, 0.0835635726980397, 0.0963551780171692, 0.0290270016659633, 0.0287317060348745],
		["", "Otto", "Euro 5", "groß", "IO", 10.1105970005561, 287.069607225507, 0.0389352045517112, 0.0310738511141244, 2.93303087143948, 0.579544045880198, 0.000878663850016892, 0.0178888645023108, 0.548470173530165, 0.00678583129954396, 0.173479961383891, 0.000781540526077151, 0.0596381750359238, 0.0559527086906943, "", 0.155067038233068, 1.10291375144996, 0.171474421907742, 0.0290507367952191, 0.145197006274907],
		["", "Otto", "Euro 5", "groß", "AO", 6.9283583894382, 191.504331632484, 0.000666186545458913, 0.000428182887844741, 0.338989287614822, 0.00598375615663826, 0.000206145065021701, 0.0433546714484692, 0.0055555731523782, 0.00220427312888205, 0.0440854616463184, 0.00126892712432891, 0.000474059663247317, 0.000392501009628177, "", 0.112366605475545, 0.0666175630904035, 0.0678777899779379, 0.0209609041885415, 0.00580781574229758],
		["", "Otto", "Euro 5", "groß", "AB", 8.89655824740118, 239.805453798094, 0.00164292908175412, 0.00106398190837353, 1.21155786514282, 0.013311062939465, 0.000202913506655022, 0.0599681437015533, 0.0122470809146762, 0.00307052489370108, 0.0614104978740215, 0.00378736294806004, 0.00117797998245806, 0.00097531673964113, "", 0.155727458670735, 0.0835635726980397, 0.0963551780171692, 0.0290270016659633, 0.0287317060348745],
		["", "Otto", "Euro 6", "klein", "IO", 6.71835794624141, 166.873314257702, 0.0346882181502134, 0.0276494574090788, 2.91336437850048, 0.519942944759615, 0.000872767181135714, 0.0178765133023262, 0.492293499624159, 0.00661866819748714, 0.158032302997321, 0.000646869943011552, 0.0531688073845609, 0.0498035794371993, "", 0.144230457106498, 1.00546811045517, 0.157063787089633, 0.0270366261843726, 0.137683926550925],
		["", "Otto", "Euro 6", "klein", "AO", 4.52985551072843, 115.518073390201, 0.000601422373620153, 0.000386108702514321, 0.289851248264313, 0.00548287271521986, 0.000201958959223703, 0.0433391593396664, 0.0050967640709132, 0.0020602373406291, 0.041204746812582, 0.00103131146170199, 0.000427477498305962, 0.000353932962752879, "", 0.11032094232738, 0.0643301678646823, 0.0650897118030116, 0.0205798517735093, 0.00468520454538826],
		["", "Otto", "Euro 6", "klein", "AB", 5.8401063924911, 150.572335329567, 0.00132117051953173, 0.000854950805660337, 0.890810132026672, 0.0108225978328846, 0.00019865101785399, 0.0599423088133335, 0.00996764650335535, 0.00270016444846988, 0.0540032871067524, 0.00328643852844834, 0.000946552667301148, 0.000783704919740558, "", 0.150493841543794, 0.0765811422897968, 0.0892920205809642, 0.0280538711833651, 0.020430803221501],
		["", "Otto", "Euro 6", "mittel", "IO", 7.6, 216.850554810066, 0.0346882181502134, 0.00146549276541919, 0.380859196186066, 0.00366373208817095, 0.000866594433318824, 0.01713059656322, 0.00219823932275176, 0.00661866819748714, 0.019214054569602, 0.00087931688176468, 0.0531688073845609, 0.0498035794371993, "g/km", 0.045655359737575, 0.270681322261226, 0.0287776455753483, 0.00872751638817135, 0.00879958175213954],
		["", "Otto", "Euro 6", "mittel", "AO", 5.6, 138.564497138978, 0.000601422373620153, 0.00164792558643967, 0.281982600688934, 0.00411981402430683, 0.000204756390303373, 0.0154142808169127, 0.00247188843786716, 0.0020602373406291, 0.0162277240306139, 0.0009707918507047, 0.000427477498305962, 0.000353932962752879, "g/km", 0.0403382547572256, 0.100402359850705, 0.0251459913817235, 0.00755988663528115, 0.0064552612692078],
		["", "Otto", "Euro 6", "mittel", "AB", 7.2, 169.369647744433, 0.00132117051953173, 0.00295426044613123, 0.306401818990707, 0.00738565158098936, 0.000197640620172024, 0.0206874571740627, 0.00443139113485813, 0.00270016444846988, 0.0612503252923489, 0.00170992885250598, 0.000946552667301148, 0.000783704919740558, "g/km", 0.0817676471918821, 0.135094056837261, 0.0689033643947914, 0.0152565152663738, 0.00314667079753782],
		["", "Otto", "Euro 6", "groß", "IO", 9.24253965208824, 287.069607225507, 0.0346882181502134, 0.0276494574090788, 2.91336437850048, 0.519942944759615, 0.000872767181135714, 0.0178765133023262, 0.492293499624159, 0.00661866819748714, 0.158032302997321, 0.000646869943011552, 0.0531688073845609, 0.0498035794371993, "", 0.144230457106498, 1.00546811045517, 0.157063787089633, 0.0270366261843726, 0.137683926550925],
		["", "Otto", "Euro 6", "groß", "AO", 6.48777442112965, 191.504331632484, 0.000601422373620153, 0.000386108702514321, 0.289851248264313, 0.00548287271521986, 0.000201958959223703, 0.0433391593396664, 0.0050967640709132, 0.0020602373406291, 0.041204746812582, 0.00103131146170199, 0.000427477498305962, 0.000353932962752879, "", 0.11032094232738, 0.0643301678646823, 0.0650897118030116, 0.0205798517735093, 0.00468520454538826],
		["", "Otto", "Euro 6", "groß", "AB", 8.3625931078233, 239.805453798094, 0.00132117051953173, 0.000854950805660337, 0.890810132026672, 0.0108225978328846, 0.00019865101785399, 0.0599423088133335, 0.00996764650335535, 0.00270016444846988, 0.0540032871067524, 0.00328643852844834, 0.000946552667301148, 0.000783704919740558, "", 0.150493841543794, 0.0765811422897968, 0.0892920205809642, 0.0280538711833651, 0.020430803221501],
		["", "Diesel", "Euro 0", "klein", "IO", 7.4953878548423, 144.785336815201, 0.00385135861487565, 0.00662602233707016, 1.44447041445349, 0.276084262603103, 0, 0.00100000004749745, 0.269458238544244, 0.0619670301095621, 0.726211551970702, 0.122335062862786, 0.000853099692621089, 0.00220867422439235, "", 0.510228086468787, 0.185528625437964, 0.765274727489933, 0.0947575017728154, 0.0105896562792751],
		["", "Diesel", "Euro 0", "klein", "AO", 5.04447371119552, 94.2650397611041, 0.00128551234956831, 0.00184744282159954, 0.391185492277145, 0.0769767835736275, 0, 0.00100000004749745, 0.0751293450593948, 0.0435788817703724, 0.54473602771759, 0.0939137935638428, 0.000246325711486861, 0.000615814293269068, "", 0.383195219491608, 0.0517283990047871, 0.574823050126433, 0.0711656836199109, -0.0298169946245158],
		["", "Diesel", "Euro 0", "klein", "AB", 5.88741497764989, 121.80475354077, 0.00116282096132636, 0.00167111994232982, 0.456082552671433, 0.0696299970149994, 0, 0.00100000004749745, 0.067958876490593, 0.0694877579808235, 0.868597030639648, 0.156706482172012, 0.000222815986489877, 0.000557039980776608, "", 0.609897921537049, 0.046791358385235, 0.922527375683188, 0.113267613999778, -0.0598544323340992],
		["", "Diesel", "Euro 0", "mittel", "IO", 8.65647824390239, 183.008122898033, 0.00385135861487565, 0.00662602233707016, 1.44447041445349, 0.276084262603103, 0, 0.00100000004749745, 0.269458238544244, 0.0619670301095621, 0.726211551970702, 0.122335062862786, 0.000853099692621089, 0.00220867422439235, "", 0.510228086468787, 0.185528625437964, 0.765274727489933, 0.0947575017728154, 0.0105896562792751],
		["", "Diesel", "Euro 0", "mittel", "AO", 5.89888167261585, 125.933397602722, 0.00128551234956831, 0.00184744282159954, 0.391185492277145, 0.0769767835736275, 0, 0.00100000004749745, 0.0751293450593948, 0.0435788817703724, 0.54473602771759, 0.0939137935638428, 0.000246325711486861, 0.000615814293269068, "", 0.383195219491608, 0.0517283990047871, 0.574823050126433, 0.0711656836199109, -0.0298169946245158],
		["", "Diesel", "Euro 0", "mittel", "AB", 6.88455292806869, 163.032364189987, 0.00116282096132636, 0.00167111994232982, 0.456082552671433, 0.0696299970149994, 0, 0.00100000004749745, 0.067958876490593, 0.0694877579808235, 0.868597030639648, 0.156706482172012, 0.000222815986489877, 0.000557039980776608, "", 0.609897921537049, 0.046791358385235, 0.922527375683188, 0.113267613999778, -0.0598544323340992],
		["", "Diesel", "Euro 0", "groß", "IO", 10.7570944412566, 242.555557196162, 0.00385135861487565, 0.00662602233707016, 1.44447041445349, 0.276084262603103, 0, 0.00100000004749745, 0.269458238544244, 0.0619670301095621, 0.726211551970702, 0.122335062862786, 0.000853099692621089, 0.00220867422439235, "", 0.510228086468787, 0.185528625437964, 0.765274727489933, 0.0947575017728154, 0.0105896562792751],
		["", "Diesel", "Euro 0", "groß", "AO", 7.44465556611047, 166.62214209297, 0.00128551234956831, 0.00184744282159954, 0.391185492277145, 0.0769767835736275, 0, 0.00100000004749745, 0.0751293450593948, 0.0435788817703724, 0.54473602771759, 0.0939137935638428, 0.000246325711486861, 0.000615814293269068, "", 0.383195219491608, 0.0517283990047871, 0.574823050126433, 0.0711656836199109, -0.0298169946245158],
		["", "Diesel", "Euro 0", "groß", "AB", 8.68850743964779, 221.652332221129, 0.00116282096132636, 0.00167111994232982, 0.456082552671433, 0.0696299970149994, 0, 0.00100000004749745, 0.067958876490593, 0.0694877579808235, 0.868597030639648, 0.156706482172012, 0.000222815986489877, 0.000557039980776608, "", 0.609897921537049, 0.046791358385235, 0.922527375683188, 0.113267613999778, -0.0598544323340992],
		["", "Diesel", "Euro 1", "klein", "IO", 6.9671580618553, 144.785336815201, 0.00313424815223001, 0.0055957154871096, 1.25935823351398, 0.233154814120304, 0.0031712471973151, 0.00100000016391277, 0.227559102713524, 0.0649877488032953, 0.763959742291038, 0.143374101352123, 0.000715717892012805, 0.00186523859052612, "", 0.536651819911883, 0.99706054092757, 0.819029383905703, 0.10052100329848, -0.00427923620919775],
		["", "Diesel", "Euro 1", "klein", "AO", 4.69366587715543, 94.2650397611041, 0.000798627734184265, 0.00114772852975875, 0.268106341362, 0.0478220209479332, 0.00400000065565109, 0.00100000016391277, 0.0466742925345898, 0.0462055765092373, 0.577569723129272, 0.101523771882057, 0.000153030472574756, 0.000382576166884974, "", 0.406178806498646, 1.09213657258078, 0.610985219851136, 0.0765140642412007, -0.0405263381051694],
		["", "Diesel", "Euro 1", "klein", "AB", 5.47501294336941, 121.80475354077, 0.000667606072966009, 0.000959433906245977, 0.274799793958664, 0.0399764142930508, 0.00396668864414096, 0.00100000004749745, 0.0390169769525528, 0.0740713775157928, 0.925892353057861, 0.149577051401138, 0.000127924518892542, 0.000319811311783269, "", 0.650004647229798, 1.07803664007224, 0.965470525845885, 0.121787011848064, -0.0745281657235169],
		["", "Diesel", "Euro 1", "mittel", "IO", 8.35959097158605, 183.008122898033, 0.00313424815223001, 0.0055957154871096, 1.25935823351398, 0.233154814120304, 0.0031712471973151, 0.00100000016391277, 0.227559102713524, 0.0649877488032953, 0.763959742291038, 0.143374101352123, 0.000715717892012805, 0.00186523859052612, "", 0.536651819911883, 0.99706054092757, 0.819029383905703, 0.10052100329848, -0.00427923620919775],
		["", "Diesel", "Euro 1", "mittel", "AO", 5.71760821432383, 125.933397602722, 0.000798627734184265, 0.00114772852975875, 0.268106341362, 0.0478220209479332, 0.00400000065565109, 0.00100000016391277, 0.0466742925345898, 0.0462055765092373, 0.577569723129272, 0.101523771882057, 0.000153030472574756, 0.000382576166884974, "", 0.406178806498646, 1.09213657258078, 0.610985219851136, 0.0765140642412007, -0.0405263381051694],
		["", "Diesel", "Euro 1", "mittel", "AB", 6.66732941865722, 163.032364189987, 0.000667606072966009, 0.000959433906245977, 0.274799793958664, 0.0399764142930508, 0.00396668864414096, 0.00100000004749745, 0.0390169769525528, 0.0740713775157928, 0.925892353057861, 0.149577051401138, 0.000127924518892542, 0.000319811311783269, "", 0.650004647229798, 1.07803664007224, 0.965470525845885, 0.121787011848064, -0.0745281657235169],
		["", "Diesel", "Euro 1", "groß", "IO", 10.3363048106739, 242.555557196162, 0.00313424815223001, 0.0055957154871096, 1.25935823351398, 0.233154814120304, 0.0031712471973151, 0.00100000016391277, 0.227559102713524, 0.0649877488032953, 0.763959742291038, 0.143374101352123, 0.000715717892012805, 0.00186523859052612, "", 0.536651819911883, 0.99706054092757, 0.819029383905703, 0.10052100329848, -0.00427923620919775],
		["", "Diesel", "Euro 1", "groß", "AO", 7.03782696179596, 166.62214209297, 0.000798627734184265, 0.00114772852975875, 0.268106341362, 0.0478220209479332, 0.00400000065565109, 0.00100000016391277, 0.0466742925345898, 0.0462055765092373, 0.577569723129272, 0.101523771882057, 0.000153030472574756, 0.000382576166884974, "", 0.406178806498646, 1.09213657258078, 0.610985219851136, 0.0765140642412007, -0.0405263381051694],
		["", "Diesel", "Euro 1", "groß", "AB", 8.17903883208939, 221.652332221129, 0.000667606072966009, 0.000959433906245977, 0.274799793958664, 0.0399764142930508, 0.00396668864414096, 0.00100000004749745, 0.0390169769525528, 0.0740713775157928, 0.925892353057861, 0.149577051401138, 0.000127924518892542, 0.000319811311783269, "", 0.650004647229798, 1.07803664007224, 0.965470525845885, 0.121787011848064, -0.0745281657235169],
		["", "Diesel", "Euro 2", "klein", "IO", 6.21449133243696, 144.785336815201, 0.00219563399736314, 0.00400627437992194, 0.861605601459718, 0.166928113347012, 0.00517124729231, 0.00100000016391277, 0.162921835645438, 0.0938389128401898, 0.838531724428593, 0.115905191387501, 0.000510487296285789, 0.00133542493006707, "", 0.588852207408171, 1.48255621509996, 0.856408171017312, 0.11075536100201, -0.0317956423607546],
		["", "Diesel", "Euro 2", "klein", "AO", 4.13172424867549, 94.2650397611041, 0.000429282867116854, 0.000616933510173112, 0.0976167768239975, 0.0257055629044771, 0.00600000051781535, 0.00100000016391277, 0.0250886287540197, 0.0677319318056107, 0.615744888782501, 0.0701773911714554, 0.0000822578003862873, 0.000205644508241676, "", 0.432901422455907, 1.60727427550591, 0.612973956950009, 0.0820168357389048, -0.051990893845435],
		["", "Diesel", "Euro 2", "klein", "AB", 4.81401939515037, 121.80475354077, 0.00033638984314166, 0.000483434501802549, 0.0693646296858788, 0.0201431047171354, 0.00596668850630522, 0.00100000004749745, 0.0196596700698137, 0.109320677816868, 0.993824303150177, 0.0855312421917915, 0.000064457934058737, 0.000161144838784821, "", 0.697557012294419, 1.59470862022135, 0.960972545035183, 0.13115816532285, -0.089423624854775],
		["", "Diesel", "Euro 2", "mittel", "IO", 7.31886648627832, 183.008122898033, 0.00219563399736314, 0.00400627437992194, 0.861605601459718, 0.166928113347012, 0.00517124729231, 0.00100000016391277, 0.162921835645438, 0.0938389128401898, 0.838531724428593, 0.115905191387501, 0.000510487296285789, 0.00133542493006707, "", 0.588852207408171, 1.48255621509996, 0.856408171017312, 0.11075536100201, -0.0317956423607546],
		["", "Diesel", "Euro 2", "mittel", "AO", 4.8899389459542, 125.933397602722, 0.000429282867116854, 0.000616933510173112, 0.0976167768239975, 0.0257055629044771, 0.00600000051781535, 0.00100000016391277, 0.0250886287540197, 0.0677319318056107, 0.615744888782501, 0.0701773911714554, 0.0000822578003862873, 0.000205644508241676, "", 0.432901422455907, 1.60727427550591, 0.612973956950009, 0.0820168357389048, -0.051990893845435],
		["", "Diesel", "Euro 2", "mittel", "AB", 5.68718445883644, 163.032364189987, 0.00033638984314166, 0.000483434501802549, 0.0693646296858788, 0.0201431047171354, 0.00596668850630522, 0.00100000004749745, 0.0196596700698137, 0.109320677816868, 0.993824303150177, 0.0855312421917915, 0.000064457934058737, 0.000161144838784821, "", 0.697557012294419, 1.59470862022135, 0.960972545035183, 0.13115816532285, -0.089423624854775],
		["", "Diesel", "Euro 2", "groß", "IO", 9.23393723797126, 242.555557196162, 0.00219563399736314, 0.00400627437992194, 0.861605601459718, 0.166928113347012, 0.00517124729231, 0.00100000016391277, 0.162921835645438, 0.0938389128401898, 0.838531724428593, 0.115905191387501, 0.000510487296285789, 0.00133542493006707, "", 0.588852207408171, 1.48255621509996, 0.856408171017312, 0.11075536100201, -0.0317956423607546],
		["", "Diesel", "Euro 2", "groß", "AO", 6.24354751882853, 166.62214209297, 0.000429282867116854, 0.000616933510173112, 0.0976167768239975, 0.0257055629044771, 0.00600000051781535, 0.00100000016391277, 0.0250886287540197, 0.0677319318056107, 0.615744888782501, 0.0701773911714554, 0.0000822578003862873, 0.000205644508241676, "", 0.432901422455907, 1.60727427550591, 0.612973956950009, 0.0820168357389048, -0.051990893845435],
		["", "Diesel", "Euro 2", "groß", "AB", 7.24960288730794, 221.652332221129, 0.00033638984314166, 0.000483434501802549, 0.0693646296858788, 0.0201431047171354, 0.00596668850630522, 0.00100000004749745, 0.0196596700698137, 0.109320677816868, 0.993824303150177, 0.0855312421917915, 0.000064457934058737, 0.000161144838784821, "", 0.697557012294419, 1.59470862022135, 0.960972545035183, 0.13115816532285, -0.089423624854775],
		["", "Diesel", "Euro 3", "klein", "IO", 6.15931920732865, 144.785336815201, 0.00139953398657572, 0.00260668628804367, 0.597672810216982, 0.108611936174308, 0.00607188371941447, 0.00100000004749745, 0.106005236441848, 0.265092758350383, 0.800090260959656, 0.0580593009091148, 0.000330986763818632, 0.000868895457356796, "", 0.561943182761054, 1.68203640171006, 0.764050793421313, 0.106001142545621, -0.0438221578892878],
		["", "Diesel", "Euro 3", "klein", "AO", 4.16075939921225, 94.2650397611041, 0.000266785122221336, 0.000383403792511672, 0.0266460869461298, 0.0159751567989588, 0.00400000018998981, 0.00100000004749745, 0.0155917536467314, 0.22166982293129, 0.64331716299057, 0.0304307956248522, 0.0000511205034854356, 0.000127801264170557, "", 0.452202014182694, 1.07073535653763, 0.597377000130713, 0.0850612312566955, -0.0579478236083628],
		["", "Diesel", "Euro 3", "klein", "AB", 4.84779210872888, 121.80475354077, 0.000256160361459479, 0.00036813467158936, 0.0187615193426609, 0.0153389442712069, 0.00408327905461192, 0.00099999993108213, 0.0149708092212677, 0.37361404299736, 1.0869482755661, 0.0441672168672085, 0.000049084617785411, 0.000122711557196453, "", 0.762743792766704, 1.09237672027666, 1.00150134903192, 0.142755761144217, -0.10032524734309],
		["", "Diesel", "Euro 3", "mittel", "IO", 7.12676226328527, 183.008122898033, 0.00139953398657572, 0.00260668628804367, 0.597672810216982, 0.108611936174308, 0.00607188371941447, 0.00100000004749745, 0.106005236441848, 0.265092758350383, 0.800090260959656, 0.0580593009091148, 0.000330986763818632, 0.000868895457356796, "", 0.561943182761054, 1.68203640171006, 0.764050793421313, 0.106001142545621, -0.0438221578892878],
		["", "Diesel", "Euro 3", "mittel", "AO", 4.81895542639903, 125.933397602722, 0.000266785122221336, 0.000383403792511672, 0.0266460869461298, 0.0159751567989588, 0.00400000018998981, 0.00100000004749745, 0.0155917536467314, 0.22166982293129, 0.64331716299057, 0.0304307956248522, 0.0000511205034854356, 0.000127801264170557, "", 0.452202014182694, 1.07073535653763, 0.597377000130713, 0.0850612312566955, -0.0579478236083628],
		["", "Diesel", "Euro 3", "mittel", "AB", 5.60486511812541, 163.032364189987, 0.000256160361459479, 0.00036813467158936, 0.0187615193426609, 0.0153389442712069, 0.00408327905461192, 0.00099999993108213, 0.0149708092212677, 0.37361404299736, 1.0869482755661, 0.0441672168672085, 0.000049084617785411, 0.000122711557196453, "", 0.762743792766704, 1.09237672027666, 1.00150134903192, 0.142755761144217, -0.10032524734309],
		["", "Diesel", "Euro 3", "groß", "IO", 9.05247372013013, 242.555557196162, 0.00139953398657572, 0.00260668628804367, 0.597672810216982, 0.108611936174308, 0.00607188371941447, 0.00100000004749745, 0.106005236441848, 0.265092758350383, 0.800090260959656, 0.0580593009091148, 0.000330986763818632, 0.000868895457356796, "", 0.561943182761054, 1.68203640171006, 0.764050793421313, 0.106001142545621, -0.0438221578892878],
		["", "Diesel", "Euro 3", "groß", "AO", 6.20399830223945, 166.62214209297, 0.000266785122221336, 0.000383403792511672, 0.0266460869461298, 0.0159751567989588, 0.00400000018998981, 0.00100000004749745, 0.0155917536467314, 0.22166982293129, 0.64331716299057, 0.0304307956248522, 0.0000511205034854356, 0.000127801264170557, "", 0.452202014182694, 1.07073535653763, 0.597377000130713, 0.0850612312566955, -0.0579478236083628],
		["", "Diesel", "Euro 3", "groß", "AB", 7.21977781240584, 221.652332221129, 0.000256160361459479, 0.00036813467158936, 0.0187615193426609, 0.0153389442712069, 0.00408327905461192, 0.00099999993108213, 0.0149708092212677, 0.37361404299736, 1.0869482755661, 0.0441672168672085, 0.000049084617785411, 0.000122711557196453, "", 0.762743792766704, 1.09237672027666, 1.00150134903192, 0.142755761144217, -0.10032524734309],
		["", "Diesel", "Euro 4", "klein", "IO", 5.98242741760572, 144.785336815201, 0.000740978867317417, 0.00138807269584165, 0.366772966480691, 0.0578363648938771, 0.00607188371941447, 0.00100000004749745, 0.0564482903888649, 0.218009994167046, 0.506470905006039, 0.0192992265931483, 0.000176080825130642, 0.00046269094007978, "", 0.356409633593523, 1.6479152211284, 0.466311002513527, 0.0678306262716511, -0.0296522130953246],
		["", "Diesel", "Euro 4", "klein", "AO", 4.05240428036559, 94.2650397611041, 0.000136101501993835, 0.000195594984688796, 0.017422940582037, 0.00814979057759047, 0.00400000018998981, 0.00100000004749745, 0.00795419607311487, 0.209927678108215, 0.443502724170685, 0.00940909422934055, 0.0000260793294728501, 0.0000651983282295987, "", 0.312331907008775, 1.06547670991859, 0.400426941882819, 0.0590853542101104, -0.0403944091699912],
		["", "Diesel", "Euro 4", "klein", "AB", 4.7124889563798, 121.80475354077, 0.000124967729789205, 0.000179594353539869, 0.012527409940958, 0.00748309772461653, 0.00408327952027321, 0.00100000004749745, 0.00730350380763412, 0.309796422719955, 0.653179228305817, 0.0109383473172784, 0.0000239459131989861, 0.0000598647893639281, "", 0.459105459903367, 1.08709771477152, 0.586463710302487, 0.086365785166854, -0.0605087471931911],
		["", "Diesel", "Euro 4", "mittel", "IO", 7.08768946221642, 183.008122898033, 0.000740978867317417, 0.00138807269584165, 0.366772966480691, 0.0578363648938771, 0.00607188371941447, 0.00100000004749745, 0.0564482903888649, 0.218009994167046, 0.506470905006039, 0.0192992265931483, 0.000176080825130642, 0.00046269094007978, "", 0.356409633593523, 1.6479152211284, 0.466311002513527, 0.0678306262716511, -0.0296522130953246],
		["", "Diesel", "Euro 4", "mittel", "AO", 4.81969320218557, 125.933397602722, 0.000136101501993835, 0.000195594984688796, 0.017422940582037, 0.00814979057759047, 0.00400000018998981, 0.00100000004749745, 0.00795419607311487, 0.209927678108215, 0.443502724170685, 0.00940909422934055, 0.0000260793294728501, 0.0000651983282295987, "", 0.312331907008775, 1.06547670991859, 0.400426941882819, 0.0590853542101104, -0.0403944091699912],
		["", "Diesel", "Euro 4", "mittel", "AB", 5.60172505610098, 163.032364189987, 0.000124967729789205, 0.000179594353539869, 0.012527409940958, 0.00748309772461653, 0.00408327952027321, 0.00100000004749745, 0.00730350380763412, 0.309796422719955, 0.653179228305817, 0.0109383473172784, 0.0000239459131989861, 0.0000598647893639281, "", 0.459105459903367, 1.08709771477152, 0.586463710302487, 0.086365785166854, -0.0605087471931911],
		["", "Diesel", "Euro 4", "groß", "IO", 9.04156421876831, 242.555557196162, 0.000740978867317417, 0.00138807269584165, 0.366772966480691, 0.0578363648938771, 0.00607188371941447, 0.00100000004749745, 0.0564482903888649, 0.218009994167046, 0.506470905006039, 0.0192992265931483, 0.000176080825130642, 0.00046269094007978, "", 0.356409633593523, 1.6479152211284, 0.466311002513527, 0.0678306262716511, -0.0296522130953246],
		["", "Diesel", "Euro 4", "groß", "AO", 6.23043518597565, 166.62214209297, 0.000136101501993835, 0.000195594984688796, 0.017422940582037, 0.00814979057759047, 0.00400000018998981, 0.00100000004749745, 0.00795419607311487, 0.209927678108215, 0.443502724170685, 0.00940909422934055, 0.0000260793294728501, 0.0000651983282295987, "", 0.312331907008775, 1.06547670991859, 0.400426941882819, 0.0590853542101104, -0.0403944091699912],
		["", "Diesel", "Euro 4", "groß", "AB", 7.24100889889955, 221.652332221129, 0.000124967729789205, 0.000179594353539869, 0.012527409940958, 0.00748309772461653, 0.00408327952027321, 0.00100000004749745, 0.00730350380763412, 0.309796422719955, 0.653179228305817, 0.0109383473172784, 0.0000239459131989861, 0.0000598647893639281, "", 0.459105459903367, 1.08709771477152, 0.586463710302487, 0.086365785166854, -0.0605087471931911],
		["", "Diesel", "Euro 5", "klein", "IO", 5.60958421745626, 144.785336815201, 0.000749549552485214, 0.00140038987344839, 0.297079000371545, 0.0583495769281024, 0.00607188325375319, 0.00100000004749745, 0.0569491927121706, 0.144318536657052, 0.498060345568778, 0.00283556437045833, 0.000177723114265549, 0.000466796633636613, "", 0.35052224198744, 1.64825997870115, 0.442452058813927, 0.0667372534190786, -0.0306611340449788],
		["", "Diesel", "Euro 5", "klein", "AO", 3.79188399254667, 94.2650397611041, 0.000139511757879518, 0.000200495938770473, 0.0121340528130531, 0.00835399702191353, 0.00400000018998981, 0.00100000004749745, 0.00815350096672773, 0.153923198580742, 0.439780592918396, 0.00111388007644564, 0.0000267327905021375, 0.0000668319771648385, "", 0.309726415132172, 1.06561393663287, 0.388858643886633, 0.0586014771473128, -0.040154299453463],
		["", "Diesel", "Euro 5", "klein", "AB", 4.41920431180946, 121.80475354077, 0.000128064071759582, 0.000184044169145636, 0.00866684596985579, 0.00766850728541613, 0.00408327952027321, 0.00100000004749745, 0.00748446304351091, 0.224370136857033, 0.641057550907135, 0.00164110981859267, 0.0000245392220676877, 0.000061348058807198, "", 0.45062028572429, 1.08722230960848, 0.566501568203792, 0.0847899671050254, -0.0594362648401624],
		["", "Diesel", "Euro 5", "mittel", "IO", 6.47731253308456, 183.008122898033, 0.000749549552485214, 0.00140038987344839, 0.297079000371545, 0.0583495769281024, 0.00607188325375319, 0.00100000004749745, 0.0569491927121706, 0.144318536657052, 0.498060345568778, 0.00283556437045833, 0.000177723114265549, 0.000466796633636613, "", 0.35052224198744, 1.64825997870115, 0.442452058813927, 0.0667372534190786, -0.0306611340449788],
		["", "Diesel", "Euro 5", "mittel", "AO", 4.40429836376376, 125.933397602722, 0.000139511757879518, 0.000200495938770473, 0.0121340528130531, 0.00835399702191353, 0.00400000018998981, 0.00100000004749745, 0.00815350096672773, 0.153923198580742, 0.439780592918396, 0.00111388007644564, 0.0000267327905021375, 0.0000668319771648385, "", 0.309726415132172, 1.06561393663287, 0.388858643886633, 0.0586014771473128, -0.040154299453463],
		["", "Diesel", "Euro 5", "mittel", "AB", 5.12482672591672, 163.032364189987, 0.000128064071759582, 0.000184044169145636, 0.00866684596985579, 0.00766850728541613, 0.00408327952027321, 0.00100000004749745, 0.00748446304351091, 0.224370136857033, 0.641057550907135, 0.00164110981859267, 0.0000245392220676877, 0.000061348058807198, "", 0.45062028572429, 1.08722230960848, 0.566501568203792, 0.0847899671050254, -0.0594362648401624],
		["", "Diesel", "Euro 5", "groß", "IO", 8.22597527999625, 242.555557196162, 0.000749549552485214, 0.00140038987344839, 0.297079000371545, 0.0583495769281024, 0.00607188325375319, 0.00100000004749745, 0.0569491927121706, 0.144318536657052, 0.498060345568778, 0.00283556437045833, 0.000177723114265549, 0.000466796633636613, "", 0.35052224198744, 1.64825997870115, 0.442452058813927, 0.0667372534190786, -0.0306611340449788],
		["", "Diesel", "Euro 5", "groß", "AO", 5.67766342732812, 166.62214209297, 0.000139511757879518, 0.000200495938770473, 0.0121340528130531, 0.00835399702191353, 0.00400000018998981, 0.00100000004749745, 0.00815350096672773, 0.153923198580742, 0.439780592918396, 0.00111388007644564, 0.0000267327905021375, 0.0000668319771648385, "", 0.309726415132172, 1.06561393663287, 0.388858643886633, 0.0586014771473128, -0.040154299453463],
		["", "Diesel", "Euro 5", "groß", "AB", 6.60731203771518, 221.652332221129, 0.000128064071759582, 0.000184044169145636, 0.00866684596985579, 0.00766850728541613, 0.00408327952027321, 0.00100000004749745, 0.00748446304351091, 0.224370136857033, 0.641057550907135, 0.00164110981859267, 0.0000245392220676877, 0.000061348058807198, "", 0.45062028572429, 1.08722230960848, 0.566501568203792, 0.0847899671050254, -0.0594362648401624],
		["", "Diesel", "Euro 6", "klein", "IO", 4.92655725909384, 144.785336815201, 0.000724879879773461, 0.00136493643119708, 0.315344132232516, 0.0568723503131503, 0.00607188371941447, 0.00100000004749745, 0.0555074152386016, 0.0397823224866419, 0.166899827754366, 0.00279062351603558, 0.000172995990087233, 0.000454978819552843, "", 0.118709879517351, 1.64726740571835, 0.150968560953139, 0.0236863862289336, 0.0010208173910949],
		["", "Diesel", "Euro 6", "klein", "AO", 3.62528628458196, 94.2650397611041, 0.000126192375319079, 0.000181354320375249, 0.0172855593264103, 0.00755643006414175, 0.00400000018998981, 0.00100000004749745, 0.00737507548183203, 0.0456728003919125, 0.152242660522461, 0.00109674513805658, 0.0000241805755649693, 0.0000604514425504021, "", 0.108449862455018, 1.06507797131781, 0.135798787334003, 0.0212215459358413, -0.0128637189278768],
		["", "Diesel", "Euro 6", "klein", "AB", 4.23123796857802, 121.80475354077, 0.000115383860247675, 0.000165821125847287, 0.0123450141400099, 0.00690921349450946, 0.00408327952027321, 0.00100000004749745, 0.00674339197576046, 0.0667556300759316, 0.222518756985664, 0.00151726603507996, 0.0000221094815060496, 0.0000552737074031029, "", 0.15764312997926, 1.08671206439612, 0.198054692916572, 0.0303799238952342, -0.0197565217863371],
		["", "Diesel", "Euro 6", "mittel", "IO", 6.7, 183.008122898033, 0.000724879879773461, 0.00840418413281441, 0.0215806066989899, 0.00933798239566386, 0.0144612528383732, 0.00680019659921527, 0.00093379826284945, 0.0397823224866419, 0.0378711819648743, 0.000524123955983669, 0.000172995990087233, 0.000454978819552843, "g/km", 0.0392941969819367, 4.0675491578877, 0.038214095487725, 0.0112078607315198, -0.00281830395031173],
		["", "Diesel", "Euro 6", "mittel", "AO", 5, 125.933397602722, 0.000126192375319079, 0.00911517255008221, 0.0158234629780054, 0.0101279695518315, 0.0120629789307714, 0.00790277775377035, 0.00101279700174928, 0.0456728003919125, 0.0375798158347607, 0.000489774043671787, 0.0000241805755649693, 0.0000604514425504021, "g/km", 0.0411630932614207, 3.45191424805672, 0.0386299433046952, 0.0109083525836468, -0.00292999967766524],
		["", "Diesel", "Euro 6", "mittel", "AB", 6.4, 163.032364189987, 0.000115383860247675, 0.00962241180241108, 0.0166460759937763, 0.0106915687210858, 0.00929078087210655, 0.00790515448898077, 0.00106915691867471, 0.0667556300759316, 0.0634358674287796, 0.000712114677298814, 0.0000221094815060496, 0.0000552737074031029, "g/km", 0.0592667976394296, 2.73148446157575, 0.0616078067705967, 0.0135219776723534, -0.00534832416206623],
		["", "Diesel", "Euro 6", "groß", "IO", 7.32486933024921, 242.555557196162, 0.000724879879773461, 0.00136493643119708, 0.315344132232516, 0.0568723503131503, 0.00607188371941447, 0.00100000004749745, 0.0555074152386016, 0.0397823224866419, 0.166899827754366, 0.00279062351603558, 0.000172995990087233, 0.000454978819552843, "", 0.118709879517351, 1.64726740571835, 0.150968560953139, 0.0236863862289336, 0.0010208173910949],
		["", "Diesel", "Euro 6", "groß", "AO", 5.38527741674607, 166.62214209297, 0.000126192375319079, 0.000181354320375249, 0.0172855593264103, 0.00755643006414175, 0.00400000018998981, 0.00100000004749745, 0.00737507548183203, 0.0456728003919125, 0.152242660522461, 0.00109674513805658, 0.0000241805755649693, 0.0000604514425504021, "", 0.108449862455018, 1.06507797131781, 0.135798787334003, 0.0212215459358413, -0.0128637189278768],
		["", "Diesel", "Euro 6", "groß", "AB", 6.28424561465712, 221.652332221129, 0.000115383860247675, 0.000165821125847287, 0.0123450141400099, 0.00690921349450946, 0.00408327952027321, 0.00100000004749745, 0.00674339197576046, 0.0667556300759316, 0.222518756985664, 0.00151726603507996, 0.0000221094815060496, 0.0000552737074031029, "", 0.15764312997926, 1.08671206439612, 0.198054692916572, 0.0303799238952342, -0.0197565217863371]
	]
);


DataGrid.setCell('DB Schadstoffe und KV', 'B2', (s, c, g) => `=${g.g('Nutzung', 'C5')}`);
DataGrid.setCell('DB Schadstoffe und KV', 'C2', (s, c, g) => `=${g.g('Nutzung', 'C6')}`);
DataGrid.setCell('DB Schadstoffe und KV', 'D2', (s, c, g) => `=${g.g('Nutzung', 'C3')}`);
DataGrid.setCell('DB Schadstoffe und KV', 'B4', (s, c, g) => `=${g.g('Nutzung', 'C5')}`);
DataGrid.setCell('DB Schadstoffe und KV', 'C4', (s, c, g) => `=${g.g('Nutzung', 'C6')}`);
DataGrid.setCell('DB Schadstoffe und KV', 'D4', (s, c, g) => `=${g.g('Nutzung', 'C3')}`);
DataGrid.setCell('DB Schadstoffe und KV', 'B6', (s, c, g) => `=${g.g('Nutzung', 'C5')}`);
DataGrid.setCell('DB Schadstoffe und KV', 'C6', (s, c, g) => `=${g.g('Nutzung', 'C6')}`);
DataGrid.setCell('DB Schadstoffe und KV', 'D6', (s, c, g) => `=${g.g('Nutzung', 'C3')}`);

DataGrid.setCell('DB Schadstoffe und KV', 'B10', (s, c, g) => `=${g.g('Nutzung', 'C5')}`);
//DataGrid.setCell('DB Schadstoffe und KV', 'C10', function(s,c,g){	return '=' + g.n('Nutzung', 'C6'); });
DataGrid.setCell('DB Schadstoffe und KV', 'D10', (s, c, g) => `=${g.g('Nutzung', 'C3')}`);
DataGrid.setCell('DB Schadstoffe und KV', 'B12', (s, c, g) => `=${g.g('Nutzung', 'C5')}`);
//DataGrid.setCell('DB Schadstoffe und KV', 'C12', function(s,c,g){	return '=' + g.n('Nutzung', 'C6'); });
DataGrid.setCell('DB Schadstoffe und KV', 'D12', (s, c, g) => `=${g.g('Nutzung', 'C3')}`);
DataGrid.setCell('DB Schadstoffe und KV', 'B14', (s, c, g) => `=${g.g('Nutzung', 'C5')}`);
//DataGrid.setCell('DB Schadstoffe und KV', 'C14', function(s,c,g){	return '=' + g.n('Nutzung', 'C6'); });
DataGrid.setCell('DB Schadstoffe und KV', 'D14', (s, c, g) => `=${g.g('Nutzung', 'C3')}`);

DataGrid.setCells('DB Schadstoffe und KV', 'F2', 'Y14', (s, c, g) => {
	const $x = g.COLUMN(c);
	const $y = g.ROW(c);
	const $valueCell = g.index2cell($x, 20);
	const $critFromCell = g.index2cell(2, $y - 1);
	const $critToCell = g.index2cell(5, $y);
	//noinspection UnnecessaryLocalVariableJS
	const $result = g.DGET(s, 'B20', 'Y146', g.g(s, $valueCell), $critFromCell, $critToCell);
	//g.log(s+':'+c+': '+$result);
	return $result;
});

// SUMPRODUCT($H21:$S21;$Nutzung.$D$20:$O$20)
DataGrid.setCells('DB Schadstoffe und KV', 'U21', 'U146', (s, c, g) => {
	const $y = g.ROW(c);
	return g.SUMPRODUCT(s, `H${$y}`, `S${$y}`, 'Nutzung', 'D20', 'O20');
});
DataGrid.setCells('DB Schadstoffe und KV', 'V21', 'V146', (s, c, g) => {
	const $y = g.ROW(c);
	return g.SUMPRODUCT(s, `H${$y}`, `S${$y}`, 'Nutzung', 'D21', 'O21');
});
DataGrid.setCells('DB Schadstoffe und KV', 'W21', 'W146', (s, c, g) => {
	const $y = g.ROW(c);
	return g.SUMPRODUCT(s, `H${$y}`, `S${$y}`, 'Nutzung', 'D22', 'O22');
});
DataGrid.setCells('DB Schadstoffe und KV', 'X21', 'X146', (s, c, g) => {
	const $y = g.ROW(c);
	return g.SUMPRODUCT(s, `H${$y}`, `S${$y}`, 'Nutzung', 'D26', 'O26');
});
DataGrid.setCells('DB Schadstoffe und KV', 'Y21', 'Y146', (s, c, g) => {
	const $y = g.ROW(c);
	return g.SUMPRODUCT(s, `H${$y}`, `S${$y}`, 'Nutzung', 'D25', 'O25');
});

/// ### DB Stromverbrauch
DataGrid.setCells('DB Stromverbrauch', 'B20', 'G38',
	[
		["FzTyp", "Größenklasse", "Fahrprofil", "Basisverbrauch", "batt.bedingter Zusatzverbrauch", "Basisgewicht"],
		["BEV", "klein", "IO", 16.41, 0.045, 910],
		["BEV", "mittel", "IO", 19.04, 0.045, 1137],
		["BEV", "groß", "IO", 22.66, 0.045, 1478],
		["BEV", "klein", "AO", 13.33, 0.034, 910],
		["BEV", "mittel", "AO", 16.02, 0.034, 1137],
		["BEV", "groß", "AO", 19.3, 0.034, 1478],
		["BEV", "klein", "AB", 19.75, 0.029, 910],
		["BEV", "mittel", "AB", 24, 0.029, 1137],
		["BEV", "groß", "AB", 28.84, 0.029, 1478],
		["PHEV", "klein", "IO", 14.83, 0.053, 1049],
		["PHEV", "mittel", "IO", 17.4, 0.053, 1311],
		["PHEV", "groß", "IO", 21, 0.053, 1704],
		["PHEV", "klein", "AO", 13.7, 0.038, 1049],
		["PHEV", "mittel", "AO", 16.53, 0.038, 1311],
		["PHEV", "groß", "AO", 20.04, 0.038, 1704],
		["PHEV", "klein", "AB", 21.08, 0.032, 1049],
		["PHEV", "mittel", "AB", 25.68, 0.032, 1311],
		["PHEV", "groß", "AB", 30.97, 0.032, 1704]
	]
);
DataGrid.setCells('DB Stromverbrauch', 'B1', 'D14',
	[
		["FzTyp", "Größenklasse", "Fahrprofil"], ["=BEV", (s, c, g) => `=${g.g('Nutzung', 'C3')}`, "=IO"],
		["FzTyp", "Größenklasse", "Fahrprofil"], ["=BEV", (s, c, g) => `=${g.g('Nutzung', 'C3')}`, "=AO"],
		["FzTyp", "Größenklasse", "Fahrprofil"], ["=BEV", (s, c, g) => `=${g.g('Nutzung', 'C3')}`, "=AB"], ["", ""], ["", ""],
		["FzTyp", "Größenklasse", "Fahrprofil"], ["=PHEV", (s, c, g) => `=${g.g('Nutzung', 'C3')}`, "=IO"],
		["FzTyp", "Größenklasse", "Fahrprofil"], ["=PHEV", (s, c, g) => `=${g.g('Nutzung', 'C3')}`, "=AO"],
		["FzTyp", "Größenklasse", "Fahrprofil"], ["=PHEV", (s, c, g) => `=${g.g('Nutzung', 'C3')}`, "=AB"]
	]
);
DataGrid.setCells('DB Stromverbrauch', 'E2', 'F14', (s, c, g) => {
	const $x = g.COLUMN(c);
	const $y = g.ROW(c);
	const $valueCell = g.index2cell($x, 20);
	const $critFromCell = g.index2cell(2, $y - 1);
	const $critToCell = g.index2cell(4, $y);
	return g.DGET(s, 'B20', 'F38', g.g(s, $valueCell), $critFromCell, $critToCell);
});


// ### DB Energiebereitstellung

DataGrid.setCells('DB Energiebereitstellung', 'B9', 'I15', [
	["Kraftstoff", "(Umweltwirkungen pro Liter)"],
	["Energieträger", "Versauerung", "Treibhauseffekt", "Feinstaub", "Eutroph. (terr.)", "Sommersmog", "KEA, nicht erneuerbar", "KEA, erneuerbar"],
	["Benzin", 0.00252, 0.665, 0.00213, 0.00017, 0.0013, 39, 0.137],
	["Diesel", 0.00253, 0.769, 0.0022, 0.000183, 0.00142, 45.9, 0.158],
	["Bioethanol", 0.0129, 1, 0.00624, 0.00227, 0.000379, 11.7, 12.4],
	["Biodiesel", 0.0103, 0.818, 0.0066, 0.00191, 0.00168, 9.93, 14.9],
	["n.a.", 0, 0, 0, 0, 0, 0, 0]
]);

function ref(cell: string): CellFunc {
	return (s, c, g) => g.g(s, cell);
}

DataGrid.setCells('DB Energiebereitstellung', 'K9', 'R14',
	[
		["Strom", "(Umweltwirkungen pro kWh)"],
		["Mix", "Versauerung", "Treibhauseffekt", "Feinstaub", "Eutroph. (terr.)", "Sommersmog", "KEA, nicht erneuerbar", "KEA, erneuerbar"],
		["Graustrom", ref('L36'), ref('L22'), ref('L37'), ref('L38'), ref('L39'), ref('L40'), ref('L41')],
		["Ökostrom", 0.000113296200829742, 0.021, 0.000097913565058904, 0.0000085960528395915, 0.00000163622187206106, 0.236312800833904, 0.0257442737517677],
		["n.a.", 0, 0, 0, 0, 0, 0, 0],
		["Energiewende",
			//=SUMMENPRODUKT($L$35:$AA$35;$L$36:$AA$36)/$AA$34
			(s, c, g) => g.SUMPRODUCT(s, 'L35', 'AA35', s, 'L36', 'AA36') / g.n(s, 'AA34'),
			//=SUMMENPRODUKT($L$21:$AA$21;$L$22:$AA$22)/$AA$20
			(s, c, g) => g.SUMPRODUCT(s, 'L21', 'AA21', s, 'L22', 'AA22') / g.n(s, 'AA20'),
			//=SUMMENPRODUKT($L$35:$AA$35;$L$37:$AA$37)/$AA$34
			(s, c, g) => g.SUMPRODUCT(s, 'L35', 'AA35', s, 'L37', 'AA37') / g.n(s, 'AA34'),
			//=SUMMENPRODUKT($L$35:$AA$35;$L$38:$AA$38)/$AA$34
			(s, c, g) => g.SUMPRODUCT(s, 'L35', 'AA35', s, 'L38', 'AA38') / g.n(s, 'AA34'),
			//=SUMMENPRODUKT($L$35:$AA$35;$L$39:$AA$39)/$AA$34
			(s, c, g) => g.SUMPRODUCT(s, 'L35', 'AA35', s, 'L39', 'AA39') / g.n(s, 'AA34'),
			//=SUMMENPRODUKT($L$35:$AA$35;$L$40:$AA$40)/$AA$34
			(s, c, g) => g.SUMPRODUCT(s, 'L35', 'AA35', s, 'L40', 'AA40') / g.n(s, 'AA34'),
			//=SUMMENPRODUKT($L$35:$AA$35;$L$41:$AA$41)/$AA$34
			(s, c, g) => g.SUMPRODUCT(s, 'L35', 'AA35', s, 'L41', 'AA41') / g.n(s, 'AA34'),
		]
	]
);


DataGrid.setCells('DB Energiebereitstellung', 'K16', 'AF18', [
	[
		"Berechnung eines gleitenden Emissionsfaktors, der den Fortschritt der Energiewende berücksichtigt:"
	],
	["Jahr", 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034, 2035, 2036, 2037, 2038, 2039, 2040
	],
	[
		// =$L$18 + ((M17-$L$17) / ($V$17-$L$17)) * ($V$18-$L$18)
		"Emissionsfaktor [kg CO2e/kWh]",
		0.429,
		(s, c, g) => g.n(s, 'L18') + ((g.n(s, 'M17') - g.n(s, 'L17')) / (g.n(s, 'V17') - g.n(s, 'L17'))) * (g.n(s, 'V18') - g.n(s, 'L18')),
		(s, c, g) => g.n(s, 'L18') + ((g.n(s, 'N17') - g.n(s, 'L17')) / (g.n(s, 'V17') - g.n(s, 'L17'))) * (g.n(s, 'V18') - g.n(s, 'L18')),
		(s, c, g) => g.n(s, 'L18') + ((g.n(s, 'O17') - g.n(s, 'L17')) / (g.n(s, 'V17') - g.n(s, 'L17'))) * (g.n(s, 'V18') - g.n(s, 'L18')),
		(s, c, g) => g.n(s, 'L18') + ((g.n(s, 'P17') - g.n(s, 'L17')) / (g.n(s, 'V17') - g.n(s, 'L17'))) * (g.n(s, 'V18') - g.n(s, 'L18')),
		(s, c, g) => g.n(s, 'L18') + ((g.n(s, 'Q17') - g.n(s, 'L17')) / (g.n(s, 'V17') - g.n(s, 'L17'))) * (g.n(s, 'V18') - g.n(s, 'L18')),
		(s, c, g) => g.n(s, 'L18') + ((g.n(s, 'R17') - g.n(s, 'L17')) / (g.n(s, 'V17') - g.n(s, 'L17'))) * (g.n(s, 'V18') - g.n(s, 'L18')),
		(s, c, g) => g.n(s, 'L18') + ((g.n(s, 'S17') - g.n(s, 'L17')) / (g.n(s, 'V17') - g.n(s, 'L17'))) * (g.n(s, 'V18') - g.n(s, 'L18')),
		(s, c, g) => g.n(s, 'L18') + ((g.n(s, 'T17') - g.n(s, 'L17')) / (g.n(s, 'V17') - g.n(s, 'L17'))) * (g.n(s, 'V18') - g.n(s, 'L18')),
		(s, c, g) => g.n(s, 'L18') + ((g.n(s, 'U17') - g.n(s, 'L17')) / (g.n(s, 'V17') - g.n(s, 'L17'))) * (g.n(s, 'V18') - g.n(s, 'L18')),
		0.254,
		//=$V$18 + ((W17-$V$17) / ($AF$17-$V$17)) * ($AF$18-$V$18)
		(s, c, g) => g.n(s, 'V18') + ((g.n(s, 'W17') - g.n(s, 'V17')) / (g.n(s, 'AF17') - g.n(s, 'V17'))) * (g.n(s, 'AF18') - g.n(s, 'V18')),
		(s, c, g) => g.n(s, 'V18') + ((g.n(s, 'X17') - g.n(s, 'V17')) / (g.n(s, 'AF17') - g.n(s, 'V17'))) * (g.n(s, 'AF18') - g.n(s, 'V18')),
		(s, c, g) => g.n(s, 'V18') + ((g.n(s, 'Y17') - g.n(s, 'V17')) / (g.n(s, 'AF17') - g.n(s, 'V17'))) * (g.n(s, 'AF18') - g.n(s, 'V18')),
		(s, c, g) => g.n(s, 'V18') + ((g.n(s, 'Z17') - g.n(s, 'V17')) / (g.n(s, 'AF17') - g.n(s, 'V17'))) * (g.n(s, 'AF18') - g.n(s, 'V18')),
		(s, c, g) => g.n(s, 'V18') + ((g.n(s, 'AA17') - g.n(s, 'V17')) / (g.n(s, 'AF17') - g.n(s, 'V17'))) * (g.n(s, 'AF18') - g.n(s, 'V18')),
		(s, c, g) => g.n(s, 'V18') + ((g.n(s, 'AB17') - g.n(s, 'V17')) / (g.n(s, 'AF17') - g.n(s, 'V17'))) * (g.n(s, 'AF18') - g.n(s, 'V18')),
		(s, c, g) => g.n(s, 'V18') + ((g.n(s, 'AC17') - g.n(s, 'V17')) / (g.n(s, 'AF17') - g.n(s, 'V17'))) * (g.n(s, 'AF18') - g.n(s, 'V18')),
		(s, c, g) => g.n(s, 'V18') + ((g.n(s, 'AD17') - g.n(s, 'V17')) / (g.n(s, 'AF17') - g.n(s, 'V17'))) * (g.n(s, 'AF18') - g.n(s, 'V18')),
		(s, c, g) => g.n(s, 'V18') + ((g.n(s, 'AE17') - g.n(s, 'V17')) / (g.n(s, 'AF17') - g.n(s, 'V17'))) * (g.n(s, 'AF18') - g.n(s, 'V18')),
		0.158,
	],
]);

DataGrid.setCells('DB Energiebereitstellung', 'K20', 'AA21', [
		["Betriebsjahr",
			1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16
		],
		["Fahrleistung (relativ zum Mittel)",
			1.277, 1.199, 1.159, 1.122, 1.088, 1.057, 1.027, 1.000, 0.973, 0.947, 0.921, 0.896, 0.871, 0.846, 0.821, 0.795
		],
	]
);
//=WVERWEIS($Eingabeparameter.$C$55 + L20 - 1;$'DB Energiebereitstellung'.$L$17:$AF$18;2;FALSCH())
DataGrid.setCells('DB Energiebereitstellung', 'L22', 'AA22',
	(s, c, g) => g.HLOOKUP(s, g.n('Berechnung_Eingabeparameter', 'C55') + g.n(s, g.index2cell(g.COLUMN(c), 20)) - 1, 'L17', 'AF18', 2, false)
);

DataGrid.setCells('DB Energiebereitstellung', 'K25', 'AF32',
	[
		["Berechnung eines gleitenden Emissionsfaktors, der den Fortschritt der Energiewende berücksichtigt:"],
		["Jahr", 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034, 2035, 2036, 2037, 2038, 2039, 2040],
		["AP", 0.0011681, 0.001, 0.001, 0.001, 0.001, 0.001, 0.001, 0.001, 0.001, 0.001, 0.001, 0.001, 0.001, 0, 0, 0, 0, 0, 0, 0, 0],
		["PM2.5", 0.0008077, 0.001, 0.001, 0.001, 0.001, 0.001, 0.001, 0.001, 0.001, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
		["EP_terr", 0.0001189, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
		["POCP", 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
		["KEA_non reg", 5.859, 5.578, 5.297, 5.015, 4.734, 4.453, 4.172, 3.891, 3.61, 3.329, 3.048, 2.991, 2.935, 2.878, 2.821, 2.764, 2.708, 2.651, 2.594, 2.538, 2.481],
		["KEA_reg", 1.44, 1.583, 1.725, 1.868, 2.011, 2.154, 2.297, 2.439, 2.582, 2.725, 2.868, 2.885, 2.903, 2.921, 2.938, 2.956, 2.973, 2.991, 3.009, 3.026, 3.044]
	]
);

DataGrid.setCells('DB Energiebereitstellung', 'K34', 'AA35',
	[
		["Betriebsjahr", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
		["Fahrleistung (relativ zum Mittel)", 1.277, 1.199, 1.159, 1.122, 1.088, 1.057, 1.027, 1, 0.973, 0.947, 0.921, 0.896, 0.871, 0.846, 0.821, 0.795]
	]
);

//=WVERWEIS($Eingabeparameter.$C$55 + L34 - 1;$'DB Energiebereitstellung'.$L$26:$AF$32;2;FALSCH())
DataGrid.setCells('DB Energiebereitstellung', 'L36', 'AA41',
	(s, c, g) => g.HLOOKUP(s, g.n('Berechnung_Eingabeparameter', 'C55') + g.n(s, g.index2cell(g.COLUMN(c), 34)) - 1, 'L26', 'AF32', g.ROW(c) - 34, false)
);


/// ### Dropdownlisten

DataGrid.setCell('Dropdownlisten', 'A1', 'Benzin');
DataGrid.setCell('Dropdownlisten', 'A2', 'Diesel');
DataGrid.setCell('Dropdownlisten', 'A3', 'Bioethanol');
DataGrid.setCell('Dropdownlisten', 'A4', 'Biodiesel');

















