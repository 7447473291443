/// ### Fahrgewohnheiten

import {CellResult, DataGrid, DataGridClass} from './calc'

DataGrid.setCell('Fahrgewohnheiten', 'C7', 13000);
DataGrid.setCell('Fahrgewohnheiten', 'C11', 200);

// entspricht fahrprofil "gemischt"
DataGrid.setCell('Eingabeparameter', 'B9', .3);  // io
DataGrid.setCell('Eingabeparameter', 'B10', .4); // ao
DataGrid.setCell('Eingabeparameter', 'B11', .3); // ab

export const PaFLT = 'Parameter Fahrleistungstool';
export const FLT = 'Fahrleistungstool';


/// ### Fahrprofil
const fahrprofil_werte = {
  city: {io: 60, ao: 20, ab: 20},
  average: {io: 30, ao: 40, ab: 30},
  autobahn: {io: 20, ao: 20, ab: 60},
};
type FahrprofilType = 'city' | 'average' | 'autobahn';

interface Fahrprofil {
  distance: number;
  num: number;
  num_type: number;
  fahrprofil: FahrprofilType;
  _hidden?: boolean;
}

interface Fahrprofil_benutzerdefiniert {
  Arbeit: Fahrprofil;
  Ausbildung: Fahrprofil;
  Freizeit: Fahrprofil;
  Urlaub: Fahrprofil;
  Sonstiges: Fahrprofil;
  Unbenutzt: Fahrprofil;
}

export const Fahrprofil_benutzerdefiniert: Fahrprofil_benutzerdefiniert = {
  Arbeit: {
    distance: 10,
    num: 5,
    num_type: 44,
    fahrprofil: 'city'
  },
  Ausbildung: {
    distance: 10,
    num: 5,
    num_type: 44,
    fahrprofil: 'city'
  },
  Freizeit: {
    distance: 100,
    num: 2,
    num_type: 12,
    fahrprofil: 'average'
  },
  Urlaub: {
    distance: 1500,
    num: 2,
    num_type: 1,
    fahrprofil: 'autobahn'
  },
  Sonstiges: {
    distance: 0,
    num: 1,
    num_type: 1,
    fahrprofil: 'autobahn'
  },
  Unbenutzt: {
    _hidden: true,
    distance: 0,
    num: 1,
    num_type: 1,
    fahrprofil: 'city'
  },
};

DataGrid.setCell('Fahrprofil', 'G4', g => fahrprofil_werte[Fahrprofil_benutzerdefiniert.Arbeit.fahrprofil].io / 100);
DataGrid.setCell('Fahrprofil', 'G5', g => fahrprofil_werte[Fahrprofil_benutzerdefiniert.Ausbildung.fahrprofil].io / 100);
DataGrid.setCell('Fahrprofil', 'G6', g => fahrprofil_werte[Fahrprofil_benutzerdefiniert.Freizeit.fahrprofil].io / 100);
DataGrid.setCell('Fahrprofil', 'G7', g => fahrprofil_werte[Fahrprofil_benutzerdefiniert.Urlaub.fahrprofil].io / 100);
DataGrid.setCell('Fahrprofil', 'G8', g => fahrprofil_werte[Fahrprofil_benutzerdefiniert.Sonstiges.fahrprofil].io / 100);
DataGrid.setCell('Fahrprofil', 'G9', g => fahrprofil_werte[Fahrprofil_benutzerdefiniert.Unbenutzt.fahrprofil].io / 100);

DataGrid.setCell('Fahrprofil', 'H4', g => fahrprofil_werte[Fahrprofil_benutzerdefiniert.Arbeit.fahrprofil].ao / 100);
DataGrid.setCell('Fahrprofil', 'H5', g => fahrprofil_werte[Fahrprofil_benutzerdefiniert.Ausbildung.fahrprofil].ao / 100);
DataGrid.setCell('Fahrprofil', 'H6', g => fahrprofil_werte[Fahrprofil_benutzerdefiniert.Freizeit.fahrprofil].ao / 100);
DataGrid.setCell('Fahrprofil', 'H7', g => fahrprofil_werte[Fahrprofil_benutzerdefiniert.Urlaub.fahrprofil].ao / 100);
DataGrid.setCell('Fahrprofil', 'H8', g => fahrprofil_werte[Fahrprofil_benutzerdefiniert.Sonstiges.fahrprofil].ao / 100);
DataGrid.setCell('Fahrprofil', 'H9', g => fahrprofil_werte[Fahrprofil_benutzerdefiniert.Unbenutzt.fahrprofil].ao / 100);

DataGrid.setCell('Fahrprofil', 'I4', g => fahrprofil_werte[Fahrprofil_benutzerdefiniert.Arbeit.fahrprofil].ab / 100);
DataGrid.setCell('Fahrprofil', 'I5', g => fahrprofil_werte[Fahrprofil_benutzerdefiniert.Ausbildung.fahrprofil].ab / 100);
DataGrid.setCell('Fahrprofil', 'I6', g => fahrprofil_werte[Fahrprofil_benutzerdefiniert.Freizeit.fahrprofil].ab / 100);
DataGrid.setCell('Fahrprofil', 'I7', g => fahrprofil_werte[Fahrprofil_benutzerdefiniert.Urlaub.fahrprofil].ab / 100);
DataGrid.setCell('Fahrprofil', 'I8', g => fahrprofil_werte[Fahrprofil_benutzerdefiniert.Sonstiges.fahrprofil].ab / 100);
DataGrid.setCell('Fahrprofil', 'I9', g => fahrprofil_werte[Fahrprofil_benutzerdefiniert.Unbenutzt.fahrprofil].ab / 100);

DataGrid.setCell('Fahrprofil', 'J4', g => Fahrprofil_benutzerdefiniert.Arbeit.num_type);
DataGrid.setCell('Fahrprofil', 'J5', g => Fahrprofil_benutzerdefiniert.Ausbildung.num_type);
DataGrid.setCell('Fahrprofil', 'J6', g => Fahrprofil_benutzerdefiniert.Freizeit.num_type);
DataGrid.setCell('Fahrprofil', 'J7', g => Fahrprofil_benutzerdefiniert.Urlaub.num_type);
DataGrid.setCell('Fahrprofil', 'J8', g => Fahrprofil_benutzerdefiniert.Sonstiges.num_type);
DataGrid.setCell('Fahrprofil', 'J9', g => Fahrprofil_benutzerdefiniert.Unbenutzt.num_type);


const _func_1 = (sheet: string, cell: string, g: DataGridClass) => {
  const row = g.ROW(cell);
  let profil = Fahrprofil_benutzerdefiniert.Arbeit;
  if (row == 4) {
    profil = Fahrprofil_benutzerdefiniert.Arbeit;
  }
  if (row == 5) {
    profil = Fahrprofil_benutzerdefiniert.Ausbildung;
  }
  if (row == 6) {
    profil = Fahrprofil_benutzerdefiniert.Freizeit;
  }
  if (row == 7) {
    profil = Fahrprofil_benutzerdefiniert.Urlaub;
  }
  if (row == 8) {
    profil = Fahrprofil_benutzerdefiniert.Sonstiges;
  }
  if (row == 9) {
    profil = Fahrprofil_benutzerdefiniert.Unbenutzt;
  }
  return (
    profil.distance *
    profil.num *
    (g.g(sheet, g.relCell(cell, -4, 0)) as number) *
    (g.g(sheet, `J${row}`) as number)
  )
};

DataGrid.setCell('Fahrprofil', 'K4', _func_1);
DataGrid.setCell('Fahrprofil', 'K5', _func_1);
DataGrid.setCell('Fahrprofil', 'K6', _func_1);
DataGrid.setCell('Fahrprofil', 'K7', _func_1);
DataGrid.setCell('Fahrprofil', 'K8', _func_1);
DataGrid.setCell('Fahrprofil', 'K9', _func_1);

DataGrid.setCell('Fahrprofil', 'L4', _func_1);
DataGrid.setCell('Fahrprofil', 'L5', _func_1);
DataGrid.setCell('Fahrprofil', 'L6', _func_1);
DataGrid.setCell('Fahrprofil', 'L7', _func_1);
DataGrid.setCell('Fahrprofil', 'L8', _func_1);
DataGrid.setCell('Fahrprofil', 'L9', _func_1);

DataGrid.setCell('Fahrprofil', 'M4', _func_1);
DataGrid.setCell('Fahrprofil', 'M5', _func_1);
DataGrid.setCell('Fahrprofil', 'M6', _func_1);
DataGrid.setCell('Fahrprofil', 'M7', _func_1);
DataGrid.setCell('Fahrprofil', 'M8', _func_1);
DataGrid.setCell('Fahrprofil', 'M9', _func_1);


DataGrid.setCell('Fahrprofil', 'K10', (sheet, cell, g) => g.SUM(sheet, ['K4', 'K5', 'K6', 'K7', 'K8', 'K9']));
DataGrid.setCell('Fahrprofil', 'M10', (sheet, cell, g) => g.SUM(sheet, ['M4', 'M5', 'M6', 'M7', 'M8', 'M9']));
DataGrid.setCell('Fahrprofil', 'L10', (sheet, cell, g) => g.SUM(sheet, ['L4', 'L5', 'L6', 'L7', 'L8', 'L9']));

DataGrid.setCell('Fahrprofil', 'K11', (sheet, cell, g) => (g.g(sheet, 'K10') as number) / g.SUM(sheet, ['K10', 'M10', 'L10']));
DataGrid.setCell('Fahrprofil', 'M11', (sheet, cell, g) => (g.g(sheet, 'M10') as number) / g.SUM(sheet, ['K10', 'M10', 'L10']));
DataGrid.setCell('Fahrprofil', 'L11', (sheet, cell, g) => (g.g(sheet, 'L10') as number) / g.SUM(sheet, ['K10', 'M10', 'L10']));

/// ### Eingabeparameter

// Verbrauch Vorschlag
DataGrid.setCell('Eingabeparameter', 'B17', (s, c, g) => g.g('Berechnung_Eingabeparameter', 'C17'));
// Verbrauch Nutzereingabe
DataGrid.setCell('Eingabeparameter', 'B18', (s, c, g) => g.g('Referenzfahrzeug', 'C21'));

// Fahrleistung
DataGrid.setCell('Eingabeparameter', 'B5', (s, c, g) => g.g('Fahrgewohnheiten', 'C7'));
// Lebensfahrleistung
DataGrid.setCell('Eingabeparameter', 'F5', (s, c, g) => g.g('Berechnung_Eingabeparameter', 'H5'));
// Reichweite
DataGrid.setCell('Eingabeparameter', 'B6', (s, c, g) => g.g('Fahrgewohnheiten', 'C11'));

// Fahrzeuggröße
DataGrid.setCell('Eingabeparameter', 'B13', (s, c, g) => {
  const $fahrzeug = g.g('Referenzfahrzeug', 'C4');
  if ($fahrzeug == 'Kleinwagen') {
    return 'klein';
  }
  if ($fahrzeug == 'Kompaktklasse') {
    return 'mittel';
  }
  if ($fahrzeug == 'Oberklasse') {
    return 'groß';
  }
  console.error(`'Referenzfahrzeug', 'C4' nicht gesetzt`);
  return Number.NaN;
});


// Kraftstoff
DataGrid.setCell('Eingabeparameter', 'B14', (s, c, g) => g.g('Referenzfahrzeug', 'C8'));
// Abgasnorm
DataGrid.setCell('Eingabeparameter', 'B15', (s, c, g) => g.g('Referenzfahrzeug', 'C15'));

// E-Anteil PHEV
DataGrid.setCell('Eingabeparameter', 'A21', 'io');
DataGrid.setCell('Eingabeparameter', 'A22', 'ao');
DataGrid.setCell('Eingabeparameter', 'A23', 'ab');
DataGrid.setCell('Eingabeparameter', 'B21', (s, c, g) => g.g(PaFLT, 'E9'));
DataGrid.setCell('Eingabeparameter', 'B22', (s, c, g) => g.g(PaFLT, 'E10'));
DataGrid.setCell('Eingabeparameter', 'B23', (s, c, g) => g.g(PaFLT, 'E11'));

/// ### Referenzfahrzeug

DataGrid.setCell('Referenzfahrzeug', 'C4', 'Kompaktklasse');
DataGrid.setCell('Referenzfahrzeug', 'C8', 'Benzin');
DataGrid.setCell('Referenzfahrzeug', 'C15', 'Euro 6');
DataGrid.setCell('Referenzfahrzeug', 'F21', (s, c, g) => g.g('Eingabeparameter', 'B17'));
//DataGrid.setCell('Referenzfahrzeug', 'C21', (s, c, g) => g.g('Referenzfahrzeug', 'F21'));


/// ### Auswertung Hauptkategorien
DataGrid.setCell('Auswertung Hauptkategorien', 'O5', (s, c, g) => `${g.g('Berechnung_Eingabeparameter', 'C25')} Jahre`);

export function formatNumber(value: CellResult, precision?: number): string {
  if (value === null) {
    return "";
  }
  if (typeof value !== 'number') {
    value = parseFloat(value);
  }
  if (precision !== undefined) {
    value = parseFloat(value.toFixed(precision));
  }
  return new Intl.NumberFormat('de-DE').format(value);
}


export function roundNumber(value: CellResult, precision: number = 0): number {
  if (value === null) return 0;
  const factor = Math.pow(10, precision);
  value = typeof value === 'string' ? parseFloat(value) : value;
  return Math.round(value * factor) / factor;
}


DataGrid.setCell('Auswertung Hauptkategorien', 'O6', (s, c, g) => `${formatNumber(g.g('Berechnung_Eingabeparameter', 'H5'))} km`);

DataGrid.setCell('Auswertung Hauptkategorien', 'O7', (s, c, g) => `${roundNumber((g.g('Eingabeparameter', 'B9') as number * 100))} % innerorts, ${roundNumber((g.g('Eingabeparameter', 'B10') as number * 100))} % außerorts, ${roundNumber((g.g('Eingabeparameter', 'B11') as number * 100))} % Autobahn`);


DataGrid.setCell('Auswertung Hauptkategorien', 'O9', (s, c, g) => `${roundNumber(g.g('Ergebnistabelle', 'C45'))} kg`);
DataGrid.setCell('Auswertung Hauptkategorien', 'P9', (s, c, g) => `${roundNumber(g.g('Ergebnistabelle', 'D45'))} kg`);
DataGrid.setCell('Auswertung Hauptkategorien', 'Q9', (s, c, g) => `${roundNumber(g.g('Ergebnistabelle', 'E45'))} kg`);
DataGrid.setCell('Auswertung Hauptkategorien', 'R9', (s, c, g) => `${roundNumber(g.g('Ergebnistabelle', 'F45'))} kg`);
DataGrid.setCell('Auswertung Hauptkategorien', 'S9', (s, c, g) => `${roundNumber(g.g('Ergebnistabelle', 'G45'))} kg`);
DataGrid.setCell('Auswertung Hauptkategorien', 'T9', (s, c, g) => `${roundNumber(g.g('Ergebnistabelle', 'H45'))} kg`);


DataGrid.setCell('Auswertung Hauptkategorien', 'O10', (s, c, g) => g.g('Eingabeparameter', 'B15'));
DataGrid.setCell('Auswertung Hauptkategorien', 'P10', 'Euro 6');


DataGrid.setCell('Auswertung Hauptkategorien', 'Q11', (s, c, g) => `${formatNumber(g.g('Berechnung_Eingabeparameter', 'H8'), 1)} kWh`);
DataGrid.setCell('Auswertung Hauptkategorien', 'S11', (s, c, g) => `${formatNumber(g.g('Berechnung_Eingabeparameter', 'H7'), 1)} kWh`);

DataGrid.setCell('Auswertung Hauptkategorien', 'Q12', (s, c, g) => `${g.g('Berechnung_Eingabeparameter', 'C28')} Jahre, ${g.g('Berechnung_Eingabeparameter', 'C29')} Zyklen`);


DataGrid.setCell('Auswertung Hauptkategorien', 'O15', (s, c, g) => {
  const $B14 = g.g('Eingabeparameter', 'B14');
  return ($B14 == 'Benzin') ? "Benzin (mit 5 % Bioethanol)" : (($B14 == 'Diesel') ? "Diesel (mit 5 % Biodiesel)" : $B14);
});
DataGrid.setCell('Auswertung Hauptkategorien', 'P15', (s, c, g) => {
  const $B14 = g.g('Eingabeparameter', 'B14');
  return ($B14 == 'Benzin') ? "Benzin (mit 5 % Bioethanol)" : (($B14 == 'Diesel') ? "Diesel (mit 5 % Biodiesel)" : $B14);
});


DataGrid.setCell('Auswertung Hauptkategorien', 'O18', (s, c, g) => formatNumber(g.g('Energiebereitstellung', 'D14'), 1));
DataGrid.setCell('Auswertung Hauptkategorien', 'P18', (s, c, g) => formatNumber(g.g('Energiebereitstellung', 'D15'), 1));
DataGrid.setCell('Auswertung Hauptkategorien', 'Q18', (s, c, g) => formatNumber(g.g('Energiebereitstellung', 'D16'), 1));
DataGrid.setCell('Auswertung Hauptkategorien', 'R18', (s, c, g) => formatNumber(g.g('Energiebereitstellung', 'D17'), 1));
DataGrid.setCell('Auswertung Hauptkategorien', 'S18', (s, c, g) => formatNumber(g.g('Energiebereitstellung', 'D18'), 1));
DataGrid.setCell('Auswertung Hauptkategorien', 'T18', (s, c, g) => formatNumber(g.g('Energiebereitstellung', 'D19'), 1));

DataGrid.setCell('Auswertung Hauptkategorien', 'O19', (s, c, g) => formatNumber(g.g('Energiebereitstellung', 'M14'), 1));
DataGrid.setCell('Auswertung Hauptkategorien', 'P19', (s, c, g) => formatNumber(g.g('Energiebereitstellung', 'M15'), 1));
DataGrid.setCell('Auswertung Hauptkategorien', 'Q19', (s, c, g) => formatNumber(g.g('Energiebereitstellung', 'M16'), 1));
DataGrid.setCell('Auswertung Hauptkategorien', 'R19', (s, c, g) => formatNumber(g.g('Energiebereitstellung', 'M17'), 1));
DataGrid.setCell('Auswertung Hauptkategorien', 'S19', (s, c, g) => formatNumber(g.g('Energiebereitstellung', 'M19'), 1));
DataGrid.setCell('Auswertung Hauptkategorien', 'T19', (s, c, g) => formatNumber(g.g('Energiebereitstellung', 'M19'), 1));


// Tabelle Fahrleistungstool
DataGrid.setCell(FLT, 'D37', 'Benzin');
DataGrid.setCell(FLT, 'F37', .5);
DataGrid.setCell(FLT, 'I37', (s, c, g) => g.g('Fahrgewohnheiten', 'C11'));
DataGrid.setCell(FLT, 'K37', (s, c, g) => g.g('Referenzfahrzeug', 'C21'));
DataGrid.setCell(FLT, 'L38', (s, c, g) => g.g(s, 'D37'));
DataGrid.setCell(FLT, 'O6', (s, c, g) => g.g('Fahrgewohnheiten', 'C7'));
DataGrid.setCell(FLT, 'O10', "Energiewende geht weiter");
DataGrid.setCell(FLT, 'K6', (s, c, g) => g.g(s, 'O6'));


// Fahrleistung
DataGrid.setCells(FLT, 'R38', 'AE38', (s, c, g) => g.g(PaFLT, 'E4') as number * (g.g(s, g.relCell(c, 0, 1)) as number));
// Betriebsjahr
DataGrid.setCells(FLT, 'R39', 'AE39', [[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]]);
// Kalenderjahr
const YEAR = new Date().getFullYear();

DataGrid.setCells(FLT, 'R40', 'AE40', [
  [
    YEAR, YEAR + 1, YEAR + 2, YEAR + 3, YEAR + 4, YEAR + 5, YEAR + 6, YEAR + 7, YEAR + 8, YEAR + 9, YEAR + 10, YEAR + 11, YEAR + 12, YEAR + 13,
  ]
]);
// Ihr Auto
// =$'Parameter Fahrleistungstool'.$B$6 + (SUM($'Parameter Fahrleistungstool'.$B$7:$B$9) * R38)
DataGrid.setCells(FLT, 'R41', 'AE41', (s, c, g) =>
  g.g(PaFLT, 'B6') as number + g.SUM(PaFLT, ['B7', 'B8', 'B9']) * (g.g(s, g.relCell(c, 0, -3)) as number)
);

// ICEV aktuell
// =$'Parameter Fahrleistungstool'.$B$16 + (SUM($'Parameter Fahrleistungstool'.$B$17:$B$19) * R38)
DataGrid.setCells(FLT, 'R42', 'AE42', (s, c, g) =>
  (g.g(PaFLT, 'B16') as number) + g.SUM(PaFLT, ['B17', 'B18', 'B19']) * g.n(s, g.relCell(c, 0, -4))
);


// PHEV
// =$'Parameter Fahrleistungstool'.$B$26 + (SUM($'Parameter Fahrleistungstool'.$B$28:$B$29) * R38)
DataGrid.setCells(FLT, 'R43', 'R43', (s, c, g) =>
  g.n(PaFLT, 'B26') + g.SUM(PaFLT, ['B28', 'B29']) * g.n(s, g.relCell(c, 0, -5))
);
// =R43 + ((SUM($'Parameter Fahrleistungstool'.$B$28:$B$29) + $'Parameter Fahrleistungstool'.E27) * (S38-R38))
DataGrid.setCells(FLT, 'S43', 'AE43', function (s, c, g) {
    //DataGridClass.log(c,g.relCell(c, -14, -16) , g.g(PaFLT, g.relCell(c, -14, -16)) );
    return g.n(s, g.relCell(c, -1, 0)) + ((g.SUM(PaFLT, ['B28', 'B29']) + g.n(PaFLT, g.relCell(c, -14, -16))) * (g.n(s, g.relCell(c, 0, -5)) - g.n(s, g.relCell(c, -1, -5))));
  }
);


// BEV
// =$'Parameter Fahrleistungstool'.$B$26 + (SUM($'Parameter Fahrleistungstool'.$B$28:$B$29) * R38)
DataGrid.setCells(FLT, 'R44', 'R44', (s, c, g) =>
  g.n(PaFLT, 'B36') + g.SUM(PaFLT, ['B38', 'B39']) * g.n(s, g.relCell(c, 0, -6))
);
// =R44 + ((SUM($'Parameter Fahrleistungstool'.$B$38:$B$39) + $'Parameter Fahrleistungstool'.E37) * (S38-R38))
DataGrid.setCells(FLT, 'S44', 'AE44', function (s, c, g) {
    //DataGridClass.log(c,g.relCell(c, -14, -7) , g.g(PaFLT, g.relCell(c, -14, -7)) );
    //DataGridClass.log(c,g.relCell(c, -0, -6) , g.g(FLT, g.relCell(c, -0, -6)) );

    return g.n(s, g.relCell(c, -1, 0)) + ((g.SUM(PaFLT, ['B38', 'B39']) + g.n(PaFLT, g.relCell(c, -14, -7))) * (g.n(s, g.relCell(c, 0, -6)) - g.n(s, g.relCell(c, -1, -6))));
  }
);

// Tooltips
DataGrid.setCell(PaFLT, 'V15', (s, c, g) => `Im Vergleich ihr Auto mit einem ICEV aktuell fahren Sie ab Kilometer ${Math.round(g.n(PaFLT, 'I13'))} insgesamt klimafreundlicher!`);
DataGrid.setCell(PaFLT, 'V16', (s, c, g) => `Im Vergleich ihr Auto mit einem PHEV fahren Sie ab Kilometer ${Math.round(g.n(PaFLT, 'I14'))} insgesamt klimafreundlicher!`);
DataGrid.setCell(PaFLT, 'V17', (s, c, g) => `Im Vergleich ihr Auto mit einem BEV fahren Sie ab Kilometer ${Math.round(g.n(PaFLT, 'I15'))} insgesamt klimafreundlicher!`);


// Parameter Fahrleistungstool
DataGrid.setCells(PaFLT, 'H1', 'O5', [
  ["Dropdown Strombereitstellung:", "", "", "Dropdown Fahrprofil:", "", "Fahrzeuggröße", "", "Kraftstoff"],
  ["Energiewende geht weiter", "", "", "gemischt", "", "klein", "", "Benzin"],
  ["Stromversorgung bleibt wie sie ist", "", "", "städtisch", "", "mittel", "", "Diesel"],
  ["100 % erneuerbare Versorgung", "", "", "Fernstrecken", "", "groß", "", ""],
  ["", "", "", "benutzerdefiniert…", "", "", "", ""]
]);

DataGrid.setCell(PaFLT, 'E3', (s, c, g) => g.g('Eingabeparameter', 'F5'));
DataGrid.setCell(PaFLT, 'E4', (s, c, g) => g.g('Eingabeparameter', 'B5'));
DataGrid.setCell(PaFLT, 'E5', (s, c, g) => g.n(s, 'E3') / g.n(s, 'E4'));


DataGrid.setCell(PaFLT, 'E7', (s, c, g) => g.g(FLT, 'O10'));
DataGrid.setCell(PaFLT, 'E8', (s, c, g) => g.g(FLT, 'F37'));
DataGrid.setCell(PaFLT, 'E9', (s, c, g) => (g.g(s, 'I7') == g.g(s, 'H8') ? g.g(s, 'H9') : g.g(s, 'I9')));
DataGrid.setCell(PaFLT, 'E10', (s, c, g) => (g.g(s, 'I7') == g.g(s, 'H8') ? g.g(s, 'H10') : g.g(s, 'I10')));
DataGrid.setCell(PaFLT, 'E11', (s, c, g) => (g.g(s, 'I7') == g.g(s, 'H8') ? g.g(s, 'H11') : g.g(s, 'I11')));

DataGrid.setCell(PaFLT, 'I7', 'E-Ant. opt.');  // Selection E-Ant. Mittel,E-Ant. opt.
DataGrid.setCell(PaFLT, 'G8', 'Fahrprofil');
DataGrid.setCell(PaFLT, 'H8', 'E-Ant. opt.');
DataGrid.setCell(PaFLT, 'I8', 'E-Ant. Mittel');

DataGrid.setCells(PaFLT, 'G9', 'I11', [
  [
    (s, c, g) => g.g('Eingabeparameter', 'B9'),
    (s, c, g) => g.n(s, 'E8') > g.n(s, 'G9') ? 1 : g.n(s, 'E8') / g.n(s, 'G9'),
    (s, c, g) => g.g(s, 'E8')
  ],
  [
    (s, c, g) => g.g('Eingabeparameter', 'B10'),
    (s, c, g) => (g.n(s, 'E8') > (g.n(s, 'G9') + g.n(s, 'G10'))) ? 1 : (g.n(s, 'E8') < g.n(s, 'G9')) ? 0 : (g.n(s, 'E8') - g.n(s, 'G9')) / g.n(s, 'G10'),
    (s, c, g) => g.g(s, 'E8')
  ],
  [
    (s, c, g) => g.g('Eingabeparameter', 'B11'),
    (s, c, g) => (g.n(s, 'E8') < (g.n(s, 'G9') + g.n(s, 'G10'))) ? 0 : ((g.n(s, 'E8') - g.n(s, 'G9') - g.n(s, 'G10')) / g.n(s, 'G11')),
    (s, c, g) => g.g(s, 'E8')
  ],
]);
// Ihr Auto
DataGrid.setCell(PaFLT, 'B6', 0);
DataGrid.setCell(PaFLT, 'B7', (s, c, g) => g.n('Ergebnistabelle', 'C23') / 1000000);
DataGrid.setCell(PaFLT, 'B8', (s, c, g) => g.n('Ergebnistabelle', 'C31') / 1000000);
DataGrid.setCell(PaFLT, 'B9', (s, c, g) => g.n('Ergebnistabelle', 'C39') / 1000000);

// ICEV aktuell
DataGrid.setCell(PaFLT, 'B16', (s, c, g) => (g.n('Ergebnistabelle', 'D15') / 1000000) * g.n(PaFLT, 'E3'));
DataGrid.setCell(PaFLT, 'B17', (s, c, g) => g.n('Ergebnistabelle', 'D23') / 1000000);
DataGrid.setCell(PaFLT, 'B18', (s, c, g) => g.n('Ergebnistabelle', 'D31') / 1000000);
DataGrid.setCell(PaFLT, 'B19', (s, c, g) => g.n('Ergebnistabelle', 'D39') / 1000000);

// PHEV
DataGrid.setCell(PaFLT, 'B26', (s, c, g) => (g.n('Ergebnistabelle', 'E15') / 1000000) * g.n(PaFLT, 'E3'));
//DataGrid.setCell(PaFLT, 'B27', (s, c, g) => g.g('Ergebnistabelle', 'D23') / 1000000);
DataGrid.setCell(PaFLT, 'B28', (s, c, g) => g.n('Ergebnistabelle', 'E31') / 1000000);
DataGrid.setCell(PaFLT, 'B29', (s, c, g) => g.n('Ergebnistabelle', 'E39') / 1000000);

// BEV
DataGrid.setCell(PaFLT, 'B36', (s, c, g) => (g.n('Ergebnistabelle', 'G15') / 1000000) * g.n(PaFLT, 'E3'));
//DataGrid.setCell(PaFLT, 'B37', (s, c, g) => g.g('Ergebnistabelle', 'D23') / 1000000);
DataGrid.setCell(PaFLT, 'B38', 0);
DataGrid.setCell(PaFLT, 'B39', (s, c, g) => g.n('Ergebnistabelle', 'E39') / 1000000);

DataGrid.setCell(PaFLT, 'F22', (s, c, g) => g.g('Ergebnisse tabellarisch', 'I23'));
DataGrid.setCell(PaFLT, 'F23', (s, c, g) => g.g('DB Energiebereitstellung', 'M11'));


DataGrid.setCell(PaFLT, 'D25', 'Betriebsjahre');
DataGrid.setCell(PaFLT, 'D26', 'Energiewende-Strommix [kg CO2e/kWh]');
DataGrid.setCell(PaFLT, 'D27', 'Energiebereitstellung jahresfein');

DataGrid.setCell(PaFLT, 'D29', 'Energiewende geht weiter');
DataGrid.setCell(PaFLT, 'D30', 'Stromversorgung bleibt wie sie ist');
DataGrid.setCell(PaFLT, 'D31', '100 % erneuerbare Versorgung');

DataGrid.setCell(PaFLT, 'D37', 'Energiebereitstellung jahresfein');
DataGrid.setCell(PaFLT, 'D39', 'Energiewende geht weiter');
DataGrid.setCell(PaFLT, 'D40', 'Stromversorgung bleibt wie sie ist');
DataGrid.setCell(PaFLT, 'D41', '100 % erneuerbare Versorgung');


DataGrid.setCells(PaFLT, 'E25', 'Q25', [[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]]);
DataGrid.setCells(PaFLT, 'E26', 'Q26', (s, c, g) => g.g('DB Energiebereitstellung', g.index2cell(g.COLUMN(c) + 7, 22)));

// =VLOOKUP($E$7;$D$29:E$31;1+E$25;0)
DataGrid.setCells(PaFLT, 'E27', 'Q27', (s, c, g) => g.VLOOKUP(s, g.g(s, 'E7'), 'D29', /*E31*/g.index2cell(g.COLUMN(c), 31), 1 + g.n(s, /*E25*/g.index2cell(g.COLUMN(c), 25)), false));
// =((($Ergebnistabelle.$E$23/1000)-$F$22)/1000) + ($F$22*(E26/$F$23)/1000)
DataGrid.setCells(PaFLT, 'E29', 'Q29', (s, c, g) => (((g.n('Ergebnistabelle', 'E23') / 1000) - g.n(s, 'F22')) / 1000) + (g.n(s, 'F22') * (g.n(s, /*E26*/g.index2cell(g.COLUMN(c), 26)) / g.n(s, 'F23')) / 1000));
// =$Ergebnistabelle.$E$23/1000000
DataGrid.setCells(PaFLT, 'E30', 'Q30', (s, c, g) => g.n('Ergebnistabelle', 'E23') / 1000000);
DataGrid.setCells(PaFLT, 'E31', 'Q31', (s, c, g) => g.n('Ergebnistabelle', 'F23') / 1000000);

// =VLOOKUP($E$7;$D$39:E$41;1+E$25;0)
DataGrid.setCells(PaFLT, 'E37', 'Q37', (s, c, g) => g.VLOOKUP(s, g.g(s, 'E7'), 'D39', /*E41*/g.index2cell(g.COLUMN(c), 41), 1 + g.n(s, /*E25*/g.index2cell(g.COLUMN(c), 25)), false));
// =($Ergebnistabelle.$G$23/1000000)*(E26/$F$23)
DataGrid.setCells(PaFLT, 'E39', 'Q39', (s, c, g) => (g.n('Ergebnistabelle', 'G23') / 1000000) * (g.n(s, /*E26*/g.index2cell(g.COLUMN(c), 26)) / g.n(s, 'F23')));
// =$Ergebnistabelle.$G$23/1000000
DataGrid.setCells(PaFLT, 'E40', 'Q40', (s, c, g) => g.n('Ergebnistabelle', 'G23') / 1000000);
DataGrid.setCells(PaFLT, 'E41', 'Q41', (s, c, g) => g.n('Ergebnistabelle', 'H23') / 1000000);



