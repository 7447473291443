import angular from "angular";
import 'angular-translate';
import 'angular-translate-loader-static-files';
import 'angular-translate-loader-url';

import "./data_entwurf_static";
import "./data_berechnung";
import "./data_entwurf";

import {CellResult, DataGrid} from "./calc";

import {debounce} from "lodash";
import {drawChart, drawFLTGraph} from "./controller";
import {Fahrprofil_benutzerdefiniert, roundNumber} from "./data_entwurf";

const app = angular.module('emissionsrechner', ['pascalprecht.translate']);

declare var $: JQueryStatic;
declare global {
  // noinspection JSUnusedGlobalSymbols
  interface Window {
    $translationUrls: {
      [langCode: string]: string;
    };
  }
}

export interface VehicleStatus {
  Verbrennungsfahrzeug_aktiv: boolean;
  Plug_in_Hybrid_aktiv: boolean;
  Batterie_Fahrzeug_aktiv: boolean;
  derzeitiges_Auto_aktiv: boolean;
}

app.config(['$translateProvider', function ($translateProvider: angular.translate.ITranslateProvider) {
  const currentLang = $('html').attr('lang') || 'de-de';
  $translateProvider.useUrlLoader(window.$translationUrls[currentLang]);
  $translateProvider.preferredLanguage(currentLang);
}]);


app.controller('EmissionsrechnerCtrl', ['$scope', function ($scope: angular.IScope & Record<string, any>) {

  const g = DataGrid;
  $scope.g = g;

  /// Init values
  // Ihr derzeitiges Fahrzeug
  $scope.setze_standardverbrauch = () => {
    g.clearResults();
    g.setCell('Referenzfahrzeug', 'C21', roundNumber(g.n('Referenzfahrzeug', 'F21'), 1));
  };

  $scope.setze_standardverbrauch();
  $scope.setVerbrauchmanual = () => {
    $scope.viewstate.Verbrauch_user_change = true;
  };
  $scope.zeigeInput2 = () => {
    $scope.viewstate.Verbrauch_user_change = true;
  };


  $scope.viewstate = {
    form: 'Fahrleistungstool',
    mehr_Einstellungen_visible: false,
    details_BEV_PHEV: null,
    section_input_visible: false,
    section_input_2_visible: false,
    section_results_visible: false,
    section_results_2_visible: false,
    section_data_details_visible: false,

    Verbrauch_spezifizieren: false,
    Verbrauch_user_change: false,

    Fahrgewohnheiten_fahrprofil_benutzerdefiniert_was_used: false
  };

  $scope.resetView = function () {
    $scope.viewstate.section_input_visible = false;
    $scope.viewstate.section_input_2_visible = false;
    $scope.viewstate.section_results_visible = false;
    $scope.viewstate.section_data_details_visible = false;
  };

  $scope.BEV_or_PHEV = () => ($scope.viewstate.details_BEV_PHEV || ($scope.g.getCell('Eingabeparameter', 'B6') < 150 ? 'BEV' : 'PHEV'));
  $scope.showBEV = () => ($scope.BEV_or_PHEV() == 'BEV');
  $scope.showPHEV = () => ($scope.BEV_or_PHEV() == 'PHEV');

  $scope.Fahrleistungstool = {
    Verbrennungsfahrzeug_aktiv: true,
    Plug_in_Hybrid_aktiv: true,
    Batterie_Fahrzeug_aktiv: true,
    derzeitiges_Auto_aktiv: false,
  } as VehicleStatus;

  // setze class auf body
  $scope.$watch('viewstate.section_results_visible || viewstate.section_results_2_visible', (n: boolean, o: boolean) => {
    $('body').toggleClass('rechner_auswertung', n)
  });

  // sichere einstellungen der startseite in sessionstorage
  {
    $scope.$watch('g.cells.Fahrgewohnheiten.C7', (n: CellResult, o: CellResult) => {
      sessionStorage.setItem('form:g.cells.Fahrgewohnheiten.C7', `${n}`);
    });
    $scope.$watch('g.cells.Fahrleistungstool.O10', (n: CellResult, o: CellResult) => {
      sessionStorage.setItem('form:g.cells.Fahrleistungstool.O10', `${n}`);
    });
    let Strombezug = sessionStorage.getItem('form:g.cells.Fahrleistungstool.O10');
    if (Strombezug) {
      g.cells.Fahrleistungstool.O10 = Strombezug;
    }
    let Jahresfahrleistung;
    if (Jahresfahrleistung = sessionStorage.getItem('form:g.cells.Fahrgewohnheiten.C7')) {
      g.cells.Fahrgewohnheiten.C7 = parseInt(Jahresfahrleistung)
    }
  }
  // erkenne tool-seite und klappe einstellungen auf
  if (/(online-tool|Itemid=124)$/i.test(location.href)) {
    $scope.viewstate.is_tool_seite = true;
    $scope.viewstate.mehr_Einstellungen_visible = true;
  }

  $scope.$watch('viewstate.section_input_visible', (n: boolean, o: boolean) => {
    if (n != o) {
      if (n) {
        $scope.viewstate.Verbrauch_user_change = false;
      }
    }
  });

  // Verbrauch aus Sheet FLT ist nicht mit Verbruach Rechner verlinkt:
  $scope.$watch('g.cells.Fahrleistungstool.D37', function (n: CellResult, o: CellResult) {
    $scope.g.setCell('Referenzfahrzeug', 'C8', n)
  });
  $scope.$watch('g.cells.Referenzfahrzeug.C8', function (n: CellResult, o: CellResult) {
    $scope.g.setCell('Fahrleistungstool', 'D37', n)
  });


  // Wenn sich Eingaben zum derzeitigem Fahrzeug oder Fahrprofil ändern:
  // Verbrauch auf Standardverbrauch setzen
  $scope.$watchGroup([
    'Fahrgewohnheiten.fahrprofil',
    'g.cells.Referenzfahrzeug.C4',
    'g.cells.Referenzfahrzeug.C8',
    'g.cells.Referenzfahrzeug.C15',
  ], (n: unknown, o: unknown) => {
    setze_standardverbrauch_debounced();
  });
  $scope.$watch('Fahrprofil_benutzerdefiniert', (n: unknown, o: unknown) => {
    setze_standardverbrauch_debounced();
  }, true);

  const setze_standardverbrauch_debounced = debounce(() => {
    $scope.$apply($scope.setze_standardverbrauch);
    $scope.$digest();
  }, 100);


  $scope.chartdata = {
    feinstaub: {},
    smog: {},
    ozon: {},
    klimawirkung: {},
    energie: {},
    umweltwirkung: {},
    acid: {},
    FLT: {},
  };

  function calcResults() {
    g.clearResults();
    if ($scope.viewstate.section_results_visible || $scope.viewstate.section_results_2_visible) {
      $scope.$apply(() => {
        drawChart($scope.chartdata, $scope.g, $scope.showPHEV())
      });
    }
  }

  function calcFLTGraph() {
    g.clearResults();
    $scope.$apply(() => {
      const fltoption = angular.copy($scope.Fahrleistungstool);
      if (!$scope.fahrleistungtool.$valid) {
        fltoption.derzeitiges_Auto_aktiv = false;
      }
      drawFLTGraph( $scope.g, fltoption)
    });
  }

  const calcResultsDebounced = debounce(calcResults, 100);
  const calcFLTGraphDebounced = debounce(calcFLTGraph, 100);

  // update charts on user-input
  $scope.$watch('viewstate.section_results_visible || viewstate.section_results_2_visible', (n: boolean, o: boolean) => {
    if (n) calcResultsDebounced();
  });
  $scope.$watch('g.cells.Fahrgewohnheiten', calcResultsDebounced, true);
  $scope.$watch('g.cells.Fahrleistungstool', calcResultsDebounced, true);
  $scope.$watch('g.cells.Referenzfahrzeug', calcResultsDebounced, true);
  $scope.$watch('Fahrprofil_benutzerdefiniert', calcResultsDebounced, true);

  // update FLT-graph on user input
  $scope.$watch('g.cells.Fahrgewohnheiten', calcFLTGraphDebounced, true);
  $scope.$watch('g.cells.Fahrleistungstool', calcFLTGraphDebounced, true);
  $scope.$watch('g.cells.Referenzfahrzeug', calcFLTGraphDebounced, true);
  $scope.$watch('Fahrleistungstool', calcFLTGraphDebounced, true);


  // Ihr Fahrprofil
  $scope.Fahrgewohnheiten = {
    fahrprofil: "average",
  };

  $scope.$watch('Fahrgewohnheiten.fahrprofil', (n: "average" | "io" | "ab" | "custom", o: "average" | "io" | "ab" | "custom") => {
    let io, ao, ab;
    /// VBA Script
    if (n == 'average') {
      io = .3;
      ao = .4;
      ab = .3
      DataGrid.setCell('Eingabeparameter', 'B9', io);
      DataGrid.setCell('Eingabeparameter', 'B10', ao);
      DataGrid.setCell('Eingabeparameter', 'B11', ab);
    } else if (n == 'io') {
      io = .6;
      ao = .2;
      ab = .2
      DataGrid.setCell('Eingabeparameter', 'B9', io);
      DataGrid.setCell('Eingabeparameter', 'B10', ao);
      DataGrid.setCell('Eingabeparameter', 'B11', ab);
    } else if (n == 'ab') {
      io = .2;
      ao = .2;
      ab = .6
      DataGrid.setCell('Eingabeparameter', 'B9', io);
      DataGrid.setCell('Eingabeparameter', 'B10', ao);
      DataGrid.setCell('Eingabeparameter', 'B11', ab);
    }
    if (n == 'custom') {
      $scope.viewstate.Fahrgewohnheiten_fahrprofil_benutzerdefiniert_was_used = true;

      DataGrid.setCell('Eingabeparameter', 'B9', (s, c, g) => g.getCell('Fahrprofil', 'K11'));
      DataGrid.setCell('Eingabeparameter', 'B10', (s, c, g) => g.getCell('Fahrprofil', 'L11'));
      DataGrid.setCell('Eingabeparameter', 'B11', (s, c, g) => g.getCell('Fahrprofil', 'M11'));
    }
  });


  $scope.Fahrprofil_benutzerdefiniert = Fahrprofil_benutzerdefiniert;
}]);