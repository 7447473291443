export type CellResult = string | number | null;

/**
 * Callback type for cell functions.
 */
export type CellFunc = (sheet: string, cell: string, grid: DataGridClass) => CellResult;

/**
 * Content that can be stored in a cell.
 */
export type CellContent = CellResult | CellFunc;

type SheetData = { [cell: string]: CellContent };
type Sheets = { [sheet: string]: SheetData };

export class DataGridClass {

  public readonly cells: Sheets = {};
  private results: Sheets = {};

  public readonly g = this.getCell
  public readonly n = this.getCell as (sheet: string, cell: string) => number


  relCell = (cell: string, x: number, y: number) => {
    const $x = cellNameToColNumber(cell) + x;
    const $y = cellNameToRowNumber(cell) + y;

    return getExcelColumnName($x) + $y;
  };

  COLUMN = cellNameToColNumber; // A = 65

  ROW = cellNameToRowNumber;

  INDEX = (sheet: string, cell1: string, cell2: string, row: number, col: number) => {
    const $x = DataGrid.COLUMN(cell1) + (col - 1);
    const $y = DataGrid.ROW(cell1) + (row - 1);

    DataGridClass.log(`INDEX: ${sheet}:${DataGrid.index2cell($x, $y)}`);

    return DataGrid.getCell(sheet, DataGrid.index2cell($x, $y));
  };


  SUMPRODUCT = (sheet: string, cell1: string, cell2: string, sheet2: string, cell3: string, cell4: string): number => {

    const $x1 = DataGrid.COLUMN(cell1);
    const $y1 = DataGrid.ROW(cell1);
    const $x2 = DataGrid.COLUMN(cell2);
    const $y2 = DataGrid.ROW(cell2);

    let $sum = 0.0;

    for (let x = $x1; x <= $x2; x++) {
      for (let y = $y1; y <= $y2; y++) {
        let a = DataGrid.INDEX(sheet, cell1, cell2, y - $y1 + 1, x - $x1 + 1);
        let b = DataGrid.INDEX(sheet2, cell3, cell4, y - $y1 + 1, x - $x1 + 1);
        if (typeof a === 'number' && typeof b === 'number')
          $sum += a * b;
      }
    }
    return $sum;
  };

  HLOOKUP = (sheet: string, value: any, cell1: string, cell2: string, row: number, is_range: boolean) => {
    const $x = DataGrid.COLUMN(cell1);
    const $y = DataGrid.ROW(cell1);
    const $x2 = DataGrid.COLUMN(cell2);
    const $y2 = DataGrid.ROW(cell2);

    let $return = null;

    for (let x = $x; x <= $x2; x++) {
      const cellAddress = DataGrid.index2cell(x, $y);
      const v = DataGrid.getCell(sheet, cellAddress);
      if (v === value) {
        $return = DataGrid.getCell(sheet, DataGrid.index2cell(x, $y + row - 1));
      }
    }

    return $return;
  };

  VLOOKUP = (sheet: string, value: CellResult, cell1: string, cell2: string, col: number, is_range: boolean): CellResult => {
    const $x = DataGrid.COLUMN(cell1);
    const $y = DataGrid.ROW(cell1);
    const $x2 = DataGrid.COLUMN(cell2);
    const $y2 = DataGrid.ROW(cell2);

    let $return: CellResult = null;
    let $found = false;

    for (let y = $y; y <= $y2; y++) {
      if ($found) break;
      const cellAddress = DataGrid.index2cell($x, y);
      const v = DataGrid.getCell(sheet, cellAddress);

      if (v === value) {
        $found = true;
        $return = DataGrid.getCell(sheet, DataGrid.index2cell($x + col - 1, y));
      }
    }

    return $return;
  };


  DGET = (
    sheet: string,
    datafrom: string,
    datato: string,
    value: CellResult,
    criteriafrom: string,
    criteriato: string
  ): CellResult => {
    const $x1 = DataGrid.COLUMN(datafrom);
    const $y1 = DataGrid.ROW(datafrom);
    const $x2 = DataGrid.COLUMN(datato);
    const $y2 = DataGrid.ROW(datato);

    const $cx1 = DataGrid.COLUMN(criteriafrom);
    const $cy1 = DataGrid.ROW(criteriafrom);
    const $cx2 = DataGrid.COLUMN(criteriato);
    const $cy2 = DataGrid.ROW(criteriato);

    const $criteria: { [key: number]: CellResult } = {};

    // Build criteria
    for (let x = $cx1; x <= $cx2; x++) {
      const c = DataGrid.getCell(sheet, DataGrid.index2cell(x, $cy1));
      const t = DataGrid.getCell(sheet, DataGrid.index2cell(x, $cy1 + 1));
      let $criteriaCol = 0;

      for (let x2 = $x1; x2 <= $x2; x2++) {
        const v = DataGrid.getCell(sheet, DataGrid.index2cell(x2, $y1));
        if (v === c) {
          $criteriaCol = x2;
        }
      }

      if ($criteriaCol > 0) {
        $criteria[$criteriaCol] = typeof t === 'string' ? t.slice(1) : t;
      }
    }

    // Find column with value
    let $valueCol = 0;
    for (let x = $x1; x <= $x2; x++) {
      const v = DataGrid.getCell(sheet, DataGrid.index2cell(x, $y1));
      if (v === value) {
        $valueCol = x;
      }
    }

    let $return: CellResult = null;

    for (let y = $y1; y <= $y2 && !$return; y++) {
      let isCriteriaMet = true;
      for (let i in $criteria) {
        const criteriaValue = $criteria[+i];
        if (DataGrid.getCell(sheet, DataGrid.index2cell(+i, y)) !== criteriaValue) {
          isCriteriaMet = false;
          break; // Criteria not met, no need to check further
        }
      }

      if (isCriteriaMet) {
        $return = DataGrid.getCell(sheet, DataGrid.index2cell($valueCol, y));
      }
    }

    return $return;
  };


  index2cell = (x: number, y: number) => getExcelColumnName(x) + y;


  static log(...l: any[]) { /*console.log(...l)*/
  }

  /**
   * Sets the content of a cell.
   *
   * @param sheet The name of the sheet.
   * @param cell The name of the cell.
   * @param content The content to set in the cell.
   */
  setCell(sheet: string, cell: string, content: CellContent): void {
    if (typeof this.cells[sheet] === 'undefined') {
      this.cells[sheet] = {};
    }

    if (typeof content === 'string' && /^[0-9\-]*[.,]?[0-9Ee\-]+$/.test(content)) {
      content = Number(content.replace(/[.,]/, '.'));
    }

    this.cells[sheet][cell] = content;
  }


  setCells(sheet: string, from: string, to: string, content: CellContent | CellContent[][]): void {
    const g = this;
    const $x1 = this.COLUMN(from);
    const $y1 = this.ROW(from);
    const $x2 = this.COLUMN(to);
    const $y2 = this.ROW(to);
    if (Array.isArray(content)) {
      for (let col = $x1; col <= $x2; col++) {
        for (let row = $y1; row <= $y2; row++) {
          const cell = g.index2cell(col, row);
          g.setCell(sheet, cell, content[row - $y1][col - $x1] || 0);
        }
      }
    } else {
      for (let col = $x1; col <= $x2; col++) {
        for (let row = $y1; row <= $y2; row++) {
          const cell = g.index2cell(col, row);
          g.setCell(sheet, cell, content);
        }
      }
    }
  }

  /**
   * Retrieves the content of a cell, executing any cell function if necessary.
   *
   * @param sheet The name of the sheet.
   * @param cell The name of the cell.
   * @returns The content of the cell, either a number or a string.
   */
  getCell(sheet: string, cell: string): CellResult {
    if (typeof this.cells[sheet] === 'undefined') {
      this.cells[sheet] = {};
    }
    if (typeof this.results[sheet] === 'undefined') {
      this.results[sheet] = {};
    }

    // Get cache or term
    let cellContent: CellContent = this.results[sheet][cell] || this.cells[sheet][cell];

    // Uncomment and adjust the following code if you need to interpret and execute formulas stored as strings.
    /*
    if (typeof cellContent === 'string' && /^=[A-Z0-9]+$/.test(cellContent)) {
        cellContent = (g) => g.getCell(sheet, 'K11'); // Example of converting a formula to a function.
    }
    */

    if (typeof cellContent === 'function') {
      const functionResult = cellContent(sheet, cell, this);
      this.results[sheet][cell] = functionResult;

      // Example logging, adjust or remove as necessary.
      // if (sheet === 'Ergebnisse tabellarisch' || sheet === 'Ergebnistabelle') {
      console.log(`${sheet}:${cell}  ${this.results[sheet][cell]}`);
      // }

      return functionResult;
    }

    return cellContent || 0;
  }

  /**
   * Retrieves the contents of a range of cells, excluding specified cells.
   *
   * @param s The sheet name.
   * @param from The starting cell reference.
   * @param to The ending cell reference.
   * @param exclude A list of cells to exclude or a RegExp to match cells to exclude.
   * @returns An array of the contents of the cells within the range, excluding the specified cells.
   */
  getCells(s: string, from: string, to: string, exclude: RegExp | string[] = []): CellResult[] {
    const $x1 = this.COLUMN(from);
    const $y1 = this.ROW(from);
    const $x2 = this.COLUMN(to);
    const $y2 = this.ROW(to);

    const $arr: CellResult[] = [];
    for (let col = $x1; col <= $x2; col++) {
      for (let row = $y1; row <= $y2; row++) {
        const cell = this.index2cell(col, row);

        // Check if `exclude` is an array and if it includes `cell`
        if (Array.isArray(exclude) && exclude.includes(cell)) {
          continue;
        }

        // Check if `exclude` is a RegExp and if it tests true for `cell`
        if (exclude instanceof RegExp && exclude.test(cell)) {
          continue;
        }

        $arr.push(this.getCell(s, cell));
      }
    }
    return $arr;
  }

  /**
   * Retrieves the contents of a range of cells by name, excluding specified cells.
   *
   * @param s The sheet name.
   * @param from The starting cell reference.
   * @param to The ending cell reference.
   * @param exclude A list of cell names to exclude or a RegExp pattern to match cell names against.
   * @returns An object with cell names as keys and their contents as values.
   */
  getCellsNamed(s: string, from: string, to: string, exclude: RegExp | string[] | null = null): SheetData {
    const $x1 = this.COLUMN(from);
    const $y1 = this.ROW(from);
    const $x2 = this.COLUMN(to);
    const $y2 = this.ROW(to);

    const $arr: SheetData = {};
    for (let col = $x1; col <= $x2; col++) {
      for (let row = $y1; row <= $y2; row++) {
        const cell = this.index2cell(col, row);

        // Check if `exclude` is an array and if it includes `cell`
        if (Array.isArray(exclude) && exclude.includes(cell)) {
          continue;
        }

        // Check if `exclude` is a RegExp and if it tests true for `cell`
        if (exclude instanceof RegExp && exclude.test(cell)) {
          continue;
        }

        $arr[cell] = this.getCell(s, cell);
      }
    }
    return $arr;
  }

  /**
   * Clears all results by resetting each sheet's results to an empty object.
   */
  clearResults(): void {
    for (const sheet in this.results) {
      if (this.results.hasOwnProperty(sheet)) { // Ensuring that the property belongs to the object itself, not inherited.
        this.results[sheet] = {};
      }
    }
  }

  /**
   * Sums the values of specified cells in a given sheet, returning NaN if any cell contains non-numeric data.
   *
   * @param sheet The name of the sheet containing the cells.
   * @param cells An array of cell identifiers whose values are to be summed.
   * @return The sum of the cell values, or NaN if any value is not a number.
   */
  SUM(sheet: string, cells: string[]): number {
    let sum: number = 0;

    for (const cell of cells) {
      const cellValue = this.getCell(sheet, cell);

      // Check if the cellValue is a number. If not, return NaN.
      if (typeof cellValue !== 'number') {
        return NaN; // Immediately return NaN if any cellValue is not a number
      }

      sum += cellValue;
    }

    return sum;
  }
}

export const DataGrid = new DataGridClass();


const charCodeOfA = "A".charCodeAt(0), alphabetLength = "Z".charCodeAt(0) - charCodeOfA + 1;

function cellNameToColNumber(cell: string): number {
  let result = 0;
  cell = cell.replace(/[^A-Z]/g, '');
  for (let i = 0; i < cell.length; i++) {
    result *= alphabetLength;
    result += cell.charCodeAt(i) - charCodeOfA + 1;
  }
  return result;
}

function cellNameToRowNumber(cell: string): number {
  return parseInt(cell.replace(/[^0-9]/g, ''), 10);
}

function getExcelColumnName(number: number): string {
  let sb = "";
  let num = number - 1;
  while (num >= 0) {
    sb += String.fromCharCode((num % alphabetLength) + charCodeOfA);
    num = Math.floor(num / alphabetLength) - 1;
  }
  return sb.split('').reverse().join('');
}





